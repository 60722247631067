import emptyBoard from '../assets/img/empty-board.jpg';
import emptyBoard2 from '../assets/img/empty-board2.jpg';
import emptyMemorial from '../assets/img/empty-memorial.jpg';
import emptyTable from '../assets/img/empty-table.png';
import testImage from '../assets/img/north2/bg2.jpg';
import testQR from '../assets/img/north2/logo.png';
import { Sprite, Stage, Container, Text } from "@pixi/react";
import {ColorMatrixFilter, TextStyle} from 'pixi.js';
import {ColorReplaceFilter} from "@pixi/filter-color-replace";
import {DropShadowFilter} from "@pixi/filter-drop-shadow";
import {BevelFilter} from '@pixi/filter-bevel';
import {setupImageHeightWidthByContainer} from "../utils/billing";

export type GraphElementPosition = {
    x: number,
    y: number,
}
export type GraphElementWidth = {
    width: number,
    height: number,
}
export type GraphParams = {
    position?: GraphElementPosition,
    size?: GraphElementWidth,
}
export type GraphImage = {
    src: string,
    params: GraphElementWidth,
}
export type GraphText = {
    text: string,
}
export type QRCodeConfiguratorProps = {
    bg?: GraphImage,
    image?: GraphImage,
    qr?: string | undefined,
    text?: GraphText[],
    type?: TypeConfigurator,
}
export enum TypeConfigurator {
    board,
    board2,
    memorial,
    table,
}

const ImageBlackAndWhite = new ColorMatrixFilter();
ImageBlackAndWhite.blackAndWhite(false);

const ColorReplace = new ColorReplaceFilter(0x000000, 0xe3e3e3, 0);
const WhiteColorReplace = new ColorReplaceFilter(0xffffff, 0xcccccc, 0);
const Bevel = new BevelFilter({ rotation: 45, thickness: 1, lightColor: 0xffffff, lightAlpha: 0.2, shadowColor: 0x000000, shadowAlpha: 0.2 });
const BevelLow = new BevelFilter({ rotation: 15, thickness: 1, lightColor: 0xffffff, lightAlpha: 0.1, shadowColor: 0x000000, shadowAlpha: 0.1 });

const DropShadow = new DropShadowFilter({ color: 0x949494, offset: { x: 0, y: 0 }, alpha: 1, blur: 1, quality: 1, pixelSize: 1.2 });

export default function QRCodeConfigurator ({bg, image, qr, text, type} : QRCodeConfiguratorProps) {
    let typeConf;
    switch (type) {
        case TypeConfigurator.board:
            typeConf = emptyBoard;
            break;
        case TypeConfigurator.board2:
            typeConf = emptyBoard2;
            break;
        case TypeConfigurator.memorial:
            typeConf = emptyMemorial;
            break;
        default:
            typeConf = emptyTable;
            break;
    }
    let textColor;
    let height;
    let width;
    const image1 = {
        x: 140,
        y: 175,
        w: 120,
        h: 80,
    }
    const image2 = {
        x: 180,
        y: 210,
        w: 40,
        h: 40,
    }
    const container = {
        x: 200,
        y: 145
    }
    const text1 = {
        size: 17,
        x: 0,
        y: 0,
    }
    const text2 = {
        size: 14,
        x: 0,
        y: 20,
    }
    const text3 = {
        size: 14,
        x: 0,
        y: 10,
    }
    const filtersForImages = [];
    const filtersForText = [];
    switch (type) {
        case TypeConfigurator.board:
        {
            textColor = '#403a3c';
            height = 400;
            width = 400;
            if (image) {
                const {width: imageWidth, height: imageHeight} = setupImageHeightWidthByContainer(160, 90, image?.params?.width, image?.params?.height);
                if (imageWidth - imageHeight < -15) {
                    image1.w = imageWidth;
                    image1.h = imageHeight;
                } else if (imageWidth - imageHeight > 15) {
                    // широкая
                    image1.w = imageWidth;
                    image1.h = imageHeight;
                } else {
                    // квадратная
                    image1.w = 80;
                    image1.h = 80;
                }
                image1.x = width / 2 - image1.w / 2;
                image1.y = image2.y + image2.h / 2 - image1.h / 2;
                if (text?.length && text[2]?.text?.length > 0) {
                    container.y = (image2.y - 72);
                } else {
                    container.y = (image2.y - 55);
                }

            }
            filtersForImages.push(ImageBlackAndWhite, Bevel);
            filtersForText.push(BevelLow);
            break;
        }
        case TypeConfigurator.memorial:
        {
            textColor = '#ffffff';
            height = 400;
            width = 530;
            image1.y = 55;
            image1.x = 195;
            image1.h = 100;
            image1.w = 80;
            image2.y = 100;
            image2.x = 245;
            container.x = 264;
            container.y = 180;
            text1.size = 13;
            text1.x = -2;
            text2.size = 13;
            text3.y = 10;
            text3.size = 13;
            if (image) {
                const {width: imageWidth, height: imageHeight} = setupImageHeightWidthByContainer(150, 140, image?.params?.width, image?.params?.height);
                console.log('imageWidth - imageHeight',imageWidth - imageHeight);
                // высокая
                if (imageWidth - imageHeight < -15) {
                    image1.w = imageWidth;
                    image1.h = imageHeight;
                } else if (imageWidth - imageHeight > 15) {
                    // широкая
                    image1.w = imageWidth;
                    image1.h = imageHeight;
                } else {
                    // квадратная
                    image1.w = 115;
                    image1.h = 115;
                }
                image1.x = width / 2 - image1.w / 2 - 1;
                image1.y = image2.y + image2.h / 2 - image1.h / 2;
                container.y = image1.y + image1.h + 20;
            }
            filtersForImages.push(ImageBlackAndWhite, Bevel);
            filtersForText.push(Bevel);
            break;
        }
        case TypeConfigurator.table:
        {
            textColor = '#ffffff';
            height = 400;
            width = 400;
            image1.x = 100;
            image1.y = 75;
            const imageWidth = 200;
            const imageHeight = 170;
            image1.w = imageWidth;
            image1.h = imageHeight;

            image2.x = 165;
            image2.y = 135;
            image2.w = 70;
            image2.h = 70;
            container.x = 200;
            container.y = 270;
            if (image) {
                const {width: imageWidth, height: imageHeight} = setupImageHeightWidthByContainer(280, 180, image?.params?.width, image?.params?.height);
                if (imageWidth - imageHeight < -15) {
                    image1.w = imageWidth;
                    image1.h = imageHeight;
                } else if (imageWidth - imageHeight > 15) {
                    // широкая
                    image1.w = imageWidth;
                    image1.h = imageHeight;
                } else {
                    // квадратная
                    image1.w = 160;
                    image1.h = 160;
                }
                image1.x = width / 2 - image1.w / 2;
                image1.y = image2.y + (image2.h + 5) / 2 - image1.h / 2;
                container.y = image1.y + image1.h + 20;
            }
            text1.size = 22;
            text2.size = 18;
            text2.y = 30;
            text3.size = 18;
            text3.y = 25;
            filtersForImages.push(Bevel);
            filtersForText.push(BevelLow);
            break;
        }
    }

    return (
        <Stage width={width} height={height} options={{ backgroundColor: 0xffffff }} style={{maxWidth: '100%'}}>
            <Sprite image={typeConf} x={0} y={0} width={width} height={height}/>
            {image?.src && (<Sprite filters={filtersForImages} image={image.src} x={image1.x} y={image1.y} height={image1.h}
                     width={image1.w}/>)}
            {qr && (<Sprite image={qr} x={image2.x} y={image2.y} width={image2.w} height={image2.h}/>)}

            <Container x={container.x} y={container.y}>
                {text?.length && text[0] && (
                    <Text
                        text={text[0]?.text}
                        // text={'Иванов Иван Иванович'}
                        anchor={0.5}
                        x={text1.x}
                        y={text1.y}
                        filters={filtersForText}
                        style={
                            new TextStyle({
                                align: 'left',
                                fontSize: text1.size,
                                fontFamily: 'Arial',
                                fill: textColor,
                                fontWeight: 'bolder',
                            })
                        }
                    />
                )}
                {text?.length && text[1] && (
                    <Text
                        text={text[1]?.text}
                        // text={'02.02.1960 - 10.08.2022'}
                        anchor={0.5}
                        filters={filtersForText}
                        x={text2.x}
                        y={text2.y}
                        style={
                            new TextStyle({
                                align: 'left',
                                fontSize: text2.size,
                                fontWeight: 'bold',
                                fontFamily: 'Arial',
                                fill: textColor,
                            })
                        }
                    />
                )}
                {text?.length && text[2] && (
                    <Text
                        text={text[2]?.text}
                        // text={'02.02.1960 - 10.08.2022'}
                        anchor={0.5}
                        filters={filtersForText}
                        x={text3.x}
                        y={text3.y + 28}
                        style={
                            new TextStyle({
                                align: 'left',
                                fontSize: text3.size,
                                fontWeight: 'bold',
                                fontFamily: 'Arial',
                                fill: textColor,
                            })
                        }
                    />
                )}
            </Container>
        </Stage>
    )
}