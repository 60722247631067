import React, {useEffect, useRef, useState} from 'react';
import {Box, Typography, Container, Divider, Button} from '@mui/material';
import { FullscreenControl, GeolocationControl, Map, Placemark, RouteButton, ZoomControl, SearchControl, TypeSelector } from '@pbe/react-yandex-maps';
import SpringModal from '../../components/Modal';
import ImageSlider from '../../components/ImageSlider';
import {
    BackgroundBlock,
    BackgroundContainer,
    BioBox,
    BioLink,
    BioPaper,
    BioTitle,
    CitateBox,
    DetailsBox,
    DetailsContainer,
    DetailsRow,
    FamilyTreeContainer,
    FThiddenBox,
    GraveAddress,
    GraveDivider,
    Graveyard,
    GraveyardName,
    HumanDate,
    HumanName,
    HumanYears,
    MainTitle,
    ModalContent,
    ModalDivider,
    ModalHeader,
    NameDivider,
    PhotoContainer,
    SubCitateBox, FT, QRCodeContainer, DownLoadButton
} from './styles';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {Link, useParams} from "react-router-dom";
import client, {API_HOST, UI_HOST} from "../../services/auth/ApiService/client";
import {IAnketa, initialState} from "../ChangePage/ChangePage";
import QRCodeStyling from 'qr-code-styling';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import {SocialLink, SocialLinksContainer} from "../SolderPage/styles";
import {addTextForYears} from "../../utils/billing";
import axios from "axios";
import {useMediaQuery} from "react-responsive";

interface IModalContent {
  title: string;
  content: string;
  imageSet: Array<{ buffer?: File | undefined, src: string }>;
}
export interface ILoadedData {
    anketa: IAnketa,
    relations: IAnketa[],
    order: any,
    trees: any,
}

const SinglePage = () => {
    const ref: any = useRef();
    const params = useParams()
    const refQR = useRef(null)
    const { id = '' } = params
    const [anketa, setAnketa] = useState<IAnketa>(initialState)
    const [relations, setRelations] = useState<IAnketa[]>([])
    const [trees, setTrees] = useState<any[]>([])
    const isMobile = useMediaQuery({ query: '(max-width: 720px)' })
    useEffect(() => {
        const loadData = () => {
            axios
                .get(`${API_HOST}/anketas/${id}`)
                .then((response) => {
                    const data = response.data as ILoadedData
                    setAnketa(data.anketa)
                    if (data.relations) {
                        setRelations(data.relations)
                        setTrees(data.trees)
                    }
                    qrCode.update({
                        data: `${UI_HOST}/solder/${data?.anketa?.id ?? ''}`
                    });
                })
        }
        loadData()
        if (refQR?.current) {
            qrCode.append(refQR.current)
        }
    }, []);
    const qrCode = new QRCodeStyling({
        width: 200,
        height: 200,
        image: 'https://storage.yandexcloud.net/northmemoryhotstore/logo-original.png',
        data: `${UI_HOST}/anketa/${anketa?.id ?? ''}`,
        dotsOptions: {
            color: '#000000',
            type: 'rounded',
        },
        imageOptions: {
            crossOrigin: 'anonymous',
            margin: 5,
        },
    })
    const onDownloadClick = () => {
        qrCode.download({
            extension: 'png'
        });
    };
  const FTPositions = [
      {
        left: 'calc(50% - 170px)',
        bottom: '200px',
    },
    {
        left: 'calc(50% - 90px)',
        bottom: '170px',
    },
    {
        left: 'calc(50% + 0px)',
        bottom: '170px',
    },
    {
        left: 'calc(50% - -80px)',
        bottom: '200px',
    },
    {
        left: 'calc(50% - 230px)',
        bottom: '300px',
    },
    {
        left: 'calc(50% - 140px)',
        bottom: '310px',
    },
    {
        left: 'calc(50% - 0px)',
        bottom: '290px',
    },
    {
        left: 'calc(50% - -100px)',
        bottom: '300px',
    },
    {
        left: 'calc(50% - -190px)',
        bottom: '280px',
    },
    {
        left: 'calc(50% - -228px)',
        bottom: '373px',
    },
    {
        left: 'calc(50% - 242px)',
        bottom: '403px',
    },
    {
        left: 'calc(50% - 152px)',
        bottom: '423px',
    },
    {
        left: 'calc(50% - 72px)',
        bottom: '393px',
    },
    {
        left: 'calc(50% - -18px)',
        bottom: '403px',
    },
    {
        left: 'calc(50% - -126px)',
        bottom: '393px',
    },
    {
        left: 'calc(50% - 209px)',
        bottom: '523px',
    },
    {
        left: 'calc(50% - 119px)',
        bottom: '553px',
    },
    {
        left: 'calc(50% - -3px)',
        bottom: '518px',
    },
    {
        left: 'calc(50% - -83px)',
        bottom: '485px',
    }
  ]
    const FTPositionsForMobile = [
        {
            left: 'calc(50% - 110px)',
            bottom: '90px',
        },
        {
            left: 'calc(50% - 60px)',
            bottom: '60px',
        },
        {
            left: 'calc(50% + 10px)',
            bottom: '60px',
        },
        {
            left: 'calc(50% + 60px)',
            bottom: '90px',
        },
        {
            left: 'calc(50% - 122px)',
            bottom: '150px',
        },
        {
            left: 'calc(50% - 70px)',
            bottom: '140px',
        },
        {
            left: 'calc(50% - 0px)',
            bottom: '130px',
        },
        {
            left: 'calc(50% + 50px)',
            bottom: '130px',
        },
        {
            left: 'calc(50% + 100px)',
            bottom: '120px',
        },
        {
            left: 'calc(50% + 100px)',
            bottom: '183px',
        },
        {
            left: 'calc(50% - 112px)',
            bottom: '203px',
        },
        {
            left: 'calc(50% - 62px)',
            bottom: '193px',
        },
        {
            left: 'calc(50% + 2px)',
            bottom: '183px',
        },
        {
            left: 'calc(50% + 48px)',
            bottom: '203px',
        },
        {
            left: 'calc(50% - 86px)',
            bottom: '263px',
        },
        {
            left: 'calc(50% - 39px)',
            bottom: '243px',
        },
        {
            left: 'calc(50% + 11px)',
            bottom: '263px',
        },
        {
            left: 'calc(50% - 50px)',
            bottom: '318px',
        },
        {
            left: 'calc(50% + 3px)',
            bottom: '315px',
        }
    ]

  const [open, setOpen] = React.useState(false);
  const [modalContent, setModalContent] = React.useState({} as IModalContent);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const setUpModal = (content: IModalContent) => {
      const newModalContent = {
        ...modalContent,
        ...content,
      };
      setModalContent(newModalContent);
  }

  const mapState = {
    center: [55.751574, 37.573856],
    zoom: 9,
    controls: []
  };

  const videoUrl = 'https://src.memorycode.ru/media/v1_hor.mp4';

  return (
    <>
        <SpringModal open={open} handleClose={handleClose}>
          <Box style={ { paddingRight: '2px' } }>
            <ModalHeader variant="h6" id="spring-modal-title">
              {modalContent.title}
            </ModalHeader>
            <ModalDivider />
            <ModalContent>
              <Typography component="div" id="spring-modal-description" sx={{ mt: 2 }} dangerouslySetInnerHTML={{ __html: modalContent.content }} />
              {modalContent?.imageSet?.length && <ImageSlider images={modalContent.imageSet.map((image) => image.src)} />}
            </ModalContent>
          </Box>
        </SpringModal>
        {anketa?.bg?.src ? (<BackgroundBlock sx={{backgroundImage: `url(${anketa.bg.src})`}}/>) : (<BackgroundBlock />)}
        <BackgroundContainer>
            {anketa?.photo?.src && (<PhotoContainer><img src={anketa?.photo?.src}/></PhotoContainer>)}
            <HumanName variant="h4">{anketa?.name}</HumanName>
            <NameDivider />
            {(anketa?.dateBorn || anketa?.dateEnd) && (<HumanDate variant="subtitle1">{`${anketa.dateBorn} - ${anketa.dateEnd}`}</HumanDate>)}
            {anketa?.years && (<HumanYears>{`(${addTextForYears(Number(anketa.years))})`}</HumanYears>)}
            {(anketa?.quote || anketa?.quoteName) && (
                <CitateBox>
                  <SubCitateBox>
                    <Typography variant="body1">{anketa.quote}</Typography>
                    <Typography variant="h6">{anketa.quoteName}</Typography>
                  </SubCitateBox>
                </CitateBox>
            )}
            {(anketa?.subData?.placeBorn || anketa?.subData?.placeLive || anketa?.subData?.placeEnd || anketa?.subData?.parents || anketa?.subData?.brothers || anketa?.subData?.partner || anketa?.subData?.education) && (
                <DetailsContainer>
                  <DetailsBox>
                      {anketa?.subData?.placeBorn && (<DetailsRow>
                          <Typography component="span"> Место рождения </Typography>
                          <Typography component="span">{anketa?.subData.placeBorn}</Typography>
                      </DetailsRow>)}

                      {anketa?.subData?.placeLive && (<DetailsRow>
                          <Typography component="span"> Место проживания </Typography>
                          <Typography component="span">{anketa?.subData.placeLive}</Typography>
                      </DetailsRow>)}

                      {anketa?.subData?.placeLive && (<DetailsRow>
                          <Typography component="span"> Место захоронения </Typography>
                          <Typography component="span">{anketa?.subData.placeEnd}</Typography>
                      </DetailsRow>)}

                      {anketa?.subData?.parents && (<DetailsRow>
                          <Typography component="span"> Родители </Typography>
                          <Typography component="span">{anketa?.subData.parents}</Typography>
                      </DetailsRow>)}

                      {anketa?.subData?.brothers && (<DetailsRow>
                          <Typography component="span"> Братья и сестры </Typography>
                          <Typography component="span">{anketa?.subData.brothers}</Typography>
                      </DetailsRow>)}

                      {anketa?.subData?.partner && (<DetailsRow>
                          <Typography component="span"> Супруга / Супруг </Typography>
                          <Typography component="span">{anketa?.subData.partner}</Typography>
                      </DetailsRow>)}

                      {anketa?.subData?.education && (<DetailsRow>
                          <Typography component="span"> Образование </Typography>
                          <Typography component="span">{anketa?.subData.education}</Typography>
                      </DetailsRow>)}

                  </DetailsBox>
                </DetailsContainer>
            )}
        </BackgroundContainer>

        {anketa?.bio?.length > 0 && (<>
            <Container style={{marginTop: '40px', paddingLeft: '0px', paddingRight: '0px',}}>
              <MainTitle variant="h4">Биография</MainTitle>
            </Container>
            <BackgroundContainer style={{marginTop: '20px'}}>
              {anketa?.bio?.length > 0 && anketa.bio.map((section, index) => (
                <BioPaper key={index} elevation={2} sx={{ my: 2 }}>
                  <BioTitle variant="h6">{section.name}</BioTitle>
                  <BioBox dangerouslySetInnerHTML={{ __html: section.text }} />
                  {section?.images?.length > 0 && <BioLink onClick={(e) => {
                    e.preventDefault();
                    setUpModal({
                      title: section.name,
                      content: section.text,
                      imageSet: section.images,
                    });
                    handleOpen();
                  }} href="#">Читать далее..</BioLink>}
                  <Divider />
                </BioPaper>
              ))}
            </BackgroundContainer>
        </>)}

        {trees && trees.length > 1 && (
            <>
                <Container style={{marginTop: '40px', paddingLeft: '0px', paddingRight: '0px',}}>
                    <MainTitle variant="h4">Семейное древо</MainTitle>
                </Container>
                <BackgroundContainer style={{marginTop: '20px'}}>
                    <FamilyTreeContainer>
                        {trees.map((tree, key) => {
                            const relation: IAnketa = relations.filter((r) => tree.anketaToId === r.id)[0];
                            const position = tree ? tree.position - 1 : 1;
                            const name = tree ? tree.name : '';
                            return (
                                <FT key={`tree-${key}`} sx={isMobile ? FTPositionsForMobile[position] : FTPositions[position]}>
                                    <img src={relation.photo.src}/>
                                    <FThiddenBox>
                                        <Typography variant="h6">{relation.name}</Typography>
                                        <Typography variant="subtitle1">{name}</Typography>
                                        <Typography variant="subtitle2">{relation.dateBorn} -
                                            {relation.dateEnd}</Typography>
                                        <Button component={Link} to={`/${relation.isSolder ? 'solder' : 'anketas'}/${relation.id}`}>Посмотреть</Button>
                                    </FThiddenBox>
                                </FT>
                            )
                        })}
                    </FamilyTreeContainer>
                </BackgroundContainer>
            </>
        )}

        {anketa.gallery?.length > 0 && (
            <>
                <Container style={{marginTop: '40px', paddingLeft: '0px', paddingRight: '0px',}}>
                    <MainTitle variant="h4">Галерея</MainTitle>
                </Container>
                <BackgroundContainer style={{marginTop: '20px'}}>
                    <ImageSlider images={anketa.gallery.map((img) => img.src)} />
                </BackgroundContainer>
            </>
        )}

        {anketa.video && anketa.video.src && (<>
            <Container style={{marginTop: '40px', paddingLeft: '0px', paddingRight: '0px',}}>
                <MainTitle variant="h4">Видео</MainTitle>
            </Container>
            <BackgroundContainer style={{marginTop: '20px', paddingTop: '20px', paddingBottom: '20px'}}>
                <Box>
                    <video width="100%" controls>
                        <source src={anketa.video.src} type={anketa.video.buffer?.type ?? "video/mp4"} />
                        Ваш браузер не поддерживает видео тег.
                    </video>
                </Box>
            </BackgroundContainer>
        </>)}

        {anketa.mediaLinks?.length > 0 && (<>
            <Container style={{marginTop: '40px', paddingLeft: '0px', paddingRight: '0px',}}>
                <MainTitle variant="h4">Видео из соц. сетей</MainTitle>
            </Container>
            <BackgroundContainer style={{marginTop: '20px', paddingTop: '20px', paddingBottom: '20px'}}>
                {anketa.mediaLinks.map((link) => (<>
                    <Box dangerouslySetInnerHTML={{ __html: link }} />
                </>))}
            </BackgroundContainer>
        </>)}

        {anketa.socialLinks?.length > 0 && (<>
            <Container style={{marginTop: '40px', paddingLeft: '0px', paddingRight: '0px',}}>
                <MainTitle variant="h4">Профили в соц. сетях</MainTitle>
            </Container>
            <BackgroundContainer style={{marginTop: '20px', paddingTop: '20px', paddingBottom: '20px'}}>
                <SocialLinksContainer>
                    {anketa.socialLinks.map((link) => (<>
                        <SocialLink href={link}>
                            <AttachFileIcon />
                            {link}
                        </SocialLink>
                    </>))}
                </SocialLinksContainer>
            </BackgroundContainer>
        </>)}

        {anketa.graveyard && anketa.coordinates?.coords[0] && (<>
            <Container style={{marginTop: '40px', paddingLeft: '0px', paddingRight: '0px',}}>
                <MainTitle variant="h4">Как найти захоронение</MainTitle>
            </Container>
            <BackgroundContainer style={{marginTop: '20px', paddingTop: '20px', paddingBottom: '20px'}}>
                <Graveyard>
                    <GraveyardName>{anketa.graveyard.name}</GraveyardName>
                    <GraveDivider />
                    <GraveAddress><span>Адрес:</span> {anketa.coordinates?.address}</GraveAddress>
                </Graveyard>
                <Map state={{
                    center: [Number(anketa.coordinates?.coords[0] ?? 0), Number(anketa.coordinates?.coords[1] ?? 0)],
                    zoom: 18,
                    controls: [],
                    type: 'yandex#hybrid'
                }} width="100%" height="400px" instanceRef={ref}>
                    {anketa.coordinates?.coords[0] && (<Placemark modules={["geoObject.addon.balloon"]}
                                geometry={[Number(anketa.coordinates?.coords[0]), Number(anketa.coordinates?.coords[1])]}
                                properties={{balloonContentBody: anketa.coordinates?.address}}/>)}
                    <FullscreenControl />
                    <GeolocationControl />
                    <RouteButton />
                    <ZoomControl />
                    <SearchControl />
                    <TypeSelector />
                </Map>
            </BackgroundContainer>
        </>)}

        <Container style={{marginTop: '40px', paddingLeft: '0px', paddingRight: '0px',}}>
          <MainTitle variant="h4">QR-Код для этой страницы</MainTitle>
        </Container>
        <BackgroundContainer style={{marginTop: '20px', paddingTop: '20px', paddingBottom: '20px'}}>
            <QRCodeContainer>
                <div style={{margin: '0 auto', marginTop: '20px', width: '240px'}} ref={refQR}/>
                <DownLoadButton aria-label="Скачать" onClick={onDownloadClick}>
                    <FileDownloadIcon />
                </DownLoadButton>
            </QRCodeContainer>
        </BackgroundContainer>
    </>
  );
}

export default SinglePage;