import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';
import appSlice from './appSlice';

const store = configureStore({
    reducer: {
      app : appSlice,
    },
    devTools: process.env.NODE_ENV !== 'production',
  })
  
  export type AppDispatch = typeof store.dispatch;
  export type RootState = ReturnType<typeof store.getState>;
  export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
  >;
  
  export default store;
  