    import React, {useEffect, useState} from 'react';
import { Box, Typography, Container, Divider, FormControlLabel, Checkbox, TextField } from '@mui/material';
import {Link, useNavigate, useParams, useSearchParams} from 'react-router-dom';
import { FullscreenControl, GeolocationControl, Map, Placemark, RouteButton, YMaps } from '@pbe/react-yandex-maps';
import SpringModal from '../../components/Modal';
import ImageSlider from '../../components/ImageSlider';
import qrImage from '../../assets/img/qr.png';
import { BackgroundBlock, BackgroundContainer, BioBox, BioLink, BioPaper, BioTitle, CitateBox, DetailsBox, DetailsContainer, DetailsRow, GraveAddress, GraveDivider, Graveyard, GraveyardName, HumanDate, HumanName, HumanYears, MainTitle, ModalContent, ModalDivider, ModalHeader, NameDivider, PhotoContainer, QRCode, SubCitateBox } from './styles';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import { styled } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AddIcon from '@mui/icons-material/Add';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {paymentSend, verifyPayment} from "../../utils/billing";
import client from "../../services/auth/ApiService/client";

interface IModalContent {
  title: string;
  content: string;
  imageSet: Array<{ title: string, img: string }>;
}

const userData = {
    email: 'admin@main.com',
    name: 'Авангард',
    phone: '+7(991)061-61-62'
}

const AnketaContainer = styled(Box)`
    display: flex;
`;

const Dot = styled('span')`
    display: inline-block;
    border-radius: 50%;
    background: rgba(53,61,81,.65);
    width: 12px;
    height: 12px;
`;

const Price = styled(`span`)`
    font-weight: 800;
    display: block;
    text-align: center;
    color: #292929;
    font-size: 21px;
    margin-bottom: 10px;
`;

interface ITariff {
    id: string;
    name: string;
    price: number;
    allowFields: {
        qr: true,
        bio: false,
        map: true,
        media: false,
        gallery: false,
        epitafia: true,
        tree: true,
        bg: true,
        video: true,
    }
}

const OrderPage = () => {
    const navigate = useNavigate();
    const [tariff, setTariff] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [modalContent, setModalContent] = React.useState({} as IModalContent);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [params] = useSearchParams()
    const r = params.get('r')
    useEffect(() => {
        const fetchData = async () => {
            // const payment = await verifyPayment();
            // console.log(payment)
            // if (payment) {
            //     navigate('/change');
            // }
        }
        fetchData();
    }, []);

    const handleOrder = async (type: string) => {
        // if (type === 'full') {
        //     await paymentSend();
        // }
        const referal = r ? `?r=${r}` : ''
        navigate('/create/' + type + referal)
    }

    useEffect(() => {
        const loadData = () => {

        client
            .get(`/tariff`)
            .then((response) => {
                console.log(response)
                const { data = [] } = response
                setTariff(data)
            })
            .catch((error) => {
                console.log('error')
                console.log(error)
            })
        }
        loadData()
    }, []);

  return (
    <>
        <Container style={{marginTop: '120px', paddingLeft: '0px', paddingRight: '0px',}}>
          <MainTitle variant="h4" sx={{marginBottom: '40px'}}>Создание момента памяти</MainTitle>
            <h4>Выберите тип встречи</h4>
            <TableContainer component={Paper} sx={{ maxWidth: 700 }}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{color: '#00000085'}}>Опции</TableCell>
                            {tariff && tariff.length > 0 && tariff.map((item: ITariff) => (
                                <TableCell key={`${item.id}-n`} align="center">{item.name}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                Эпитафия
                            </TableCell>
                            {tariff && tariff.length > 0 && tariff.map((item: ITariff) => (
                                <TableCell key={`${item.id}-e`} align="center">{item.allowFields.epitafia ? <Dot /> : ''}</TableCell>
                            ))}
                        </TableRow>

                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                Фон в шапке
                            </TableCell>
                            {tariff && tariff.length > 0 && tariff.map((item: ITariff) => (
                                <TableCell key={`${item.id}-e`} align="center">{item.allowFields.bg ? <Dot /> : ''}</TableCell>
                            ))}
                        </TableRow>

                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                Отметка на карте
                            </TableCell>
                            {tariff && tariff.length > 0 && tariff.map((item: ITariff) => (
                                <TableCell key={`${item.id}-m`} align="center">{item.allowFields.map ? <Dot /> : ''}</TableCell>
                            ))}
                        </TableRow>

                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                Родственные связи
                            </TableCell>
                            {tariff && tariff.length > 0 && tariff.map((item: ITariff) => (
                                <TableCell key={`${item.id}-r`} align="center">{item.allowFields.tree ? <Dot /> : ''}</TableCell>
                            ))}
                        </TableRow>

                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                QR-код для печати
                            </TableCell>
                            {tariff && tariff.length > 0 && tariff.map((item: ITariff) => (
                                <TableCell key={`${item.id}-q`} align="center">{item.allowFields.qr ? <Dot /> : ''}</TableCell>
                            ))}
                        </TableRow>

                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                Биография с фото
                            </TableCell>
                            {tariff && tariff.length > 0 && tariff.map((item: ITariff) => (
                                <TableCell key={`${item.id}-b`} align="center">{item.allowFields.bio ? <Dot /> : ''}</TableCell>
                            ))}
                        </TableRow>

                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                Фотогалерея
                            </TableCell>
                            {tariff && tariff.length > 0 && tariff.map((item: ITariff) => (
                                <TableCell key={`${item.id}-g`} align="center">{item.allowFields.gallery ? <Dot /> : ''}</TableCell>
                            ))}
                        </TableRow>

                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                Видео и аудиозаписи из соц. сетей
                            </TableCell>
                            {tariff && tariff.length > 0 && tariff.map((item: ITariff) => (
                                <TableCell key={`${item.id}-m`} align="center">{item.allowFields.media ? <Dot /> : ''}</TableCell>
                            ))}
                        </TableRow>
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                Видео файлы
                            </TableCell>
                            {tariff && tariff.length > 0 && tariff.map((item: ITariff) => (
                                <TableCell key={`${item.id}-m`} align="center">{item.allowFields.video ? <Dot /> : ''}</TableCell>
                            ))}
                        </TableRow>
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row"></TableCell>
                            {tariff && tariff.length > 0 && tariff.map((item: ITariff) => (
                                <TableCell align="center" key={`${item.id}-price`}>
                                    <Price>{item.price ? `${item.price} руб.` : `Бесплатно`}</Price>
                                    <Button sx={{ m: 2 }} size="large" variant="contained" onClick={(e) => handleOrder(item.id)}>
                                        Создать
                                    </Button>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    </>
  );
}

export default OrderPage;