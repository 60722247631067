import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import store from './store';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {DevSupport} from "@react-buddy/ide-toolbox";
import {ComponentPreviews, useInitial} from "./dev";
import {YMaps} from "@pbe/react-yandex-maps";
import ScrollToTop from './scrollToTop';

document.body.innerHTML = '<div id="root"></div>';
const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <React.StrictMode>

            <Provider store={store}>
                {/*<DevSupport ComponentPreviews={ComponentPreviews}*/}
                {/*            useInitialHook={useInitial}*/}
                {/*>*/}
                <App/>
                {/*</DevSupport>*/}
            </Provider>

    </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
