import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import {IAnketa} from "../pages/ChangePage/ChangePage";
import {API_HOST} from "../services/auth/ApiService/client";
import {Box} from "@mui/material";
import {useNavigate} from "react-router-dom";


export default function Search() {
  const [open, setOpen] = React.useState(false);
  const [ankets, setAnkets] = React.useState<IAnketa[]>([])
  const loading = open && ankets?.length === 0;
  const navigate = useNavigate()

  const onSearch = async (value: string) => {
    const search = async (text: string) => {
      try {
        let searchText = `${API_HOST}/anketas`
        if (text) {
          searchText += `?search=${text}`
        } else {
          searchText += `?page=1`
        }
        const res = await fetch(searchText)
        const data = await res.json() as IAnketa[]

        console.log(data)
        setAnkets([...data])
      } catch (e) {
        console.log(e);
      }

    };
    search(value)
  }

  React.useEffect(() => {
    console.log('open',open)
    if (!open) {
      setAnkets([]);
    }
  }, [open]);

  return (
      <Autocomplete
          id="tree-autocomplete"
          // fullWidth
          // freeSolo
          open={open}
          onOpen={() => {
            setOpen(true);
            onSearch('')
          }}
          onClose={() => {
            setOpen(false);
          }}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          options={ankets?.map((item: IAnketa) => item)}
          loading={loading}
          loadingText="Идет поиск..."
          clearText="Очистить"
          renderInput={(params) => (
              <TextField
                  {...params}
                  label="Введите ФИО"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <React.Fragment>
                          {loading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                    ),
                  }}
              />
          )}
          getOptionLabel={(option) => option.name}
          onInputChange={(event, newValue) => {
            if (typeof newValue === "string" || newValue === '') {
              onSearch(newValue)
            }
          }}
          onChange={(event, newValue) => {
            if (!newValue) {
              onSearch('')
            } else {
              navigate(`/${newValue?.isSolder ? 'solder' : 'anketa'}/${newValue.id}`)
            }
          }}
          renderOption={(props: any, option: IAnketa) => (
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                <img
                    loading="lazy"
                    width="20"
                    srcSet={`${option.photo.src} 2x`}
                    src={option.photo.src}
                    alt={option.name}
                />
                {option.name} ({option.dateBorn} - {option.dateEnd})
              </Box>
          )}
      />
  );
}
