import React, {useEffect, useState} from 'react';
import { Container, Button, TextField } from '@mui/material';
import {Link, useNavigate} from 'react-router-dom';
import logoImage from '../../assets/img/logo-original.png';
import hi1 from '../../assets/img/north5/mo1.png';
import hi2 from '../../assets/img/north5/mo2.png';
import hi3 from '../../assets/img/north5/mo5.png';
import hi4 from '../../assets/img/north5/mo6.png';
import hi5 from '../../assets/img/north5/mo7.png';
import f1 from '../../assets/img/north5/f1.png';
import f2 from '../../assets/img/north5/f2.png';
import f3 from '../../assets/img/north5/f3.png';
import s1 from '../../assets/img/north5/s1.png';
import s2 from '../../assets/img/north5/s2.png';
import s3 from '../../assets/img/north5/s3.png';
import {
  MainSection,
  MainSectionContent,
  MainTitle,
  MainDescription,
  MainDescriptionSmall,
  MainBtn,
  SearchSection,
  SearchTextContainer,
  SearchHeader,
  SearchSolderForm,
  SoldersContainer,
  SolderContainer,
  SolderFront,
  SolderImg,
  SolderTextContainer,
  SolderTextName,
  SolderTextDescription,
  SolderBack,
  SolderBackText,
  SolderButton,
  SoldersShowMore,
  HonorSection,
  HonorTitle,
  HonorImages,
  HonorText,
  HonorImage1,
  HonorImage2,
  HonorImage3,
  HonorImage4,
  HonorImage5,
  FamilySection,
  FamilyTitle,
  FamilyImages,
  FamilyBlock,
  FamilyImage,
  FamilyText,
  FamilyBlock1,
  FamilyBlock2,
  FamilyBlock3,
  StepsSection,
  StepsTitle,
  StepsContainer,
  StepsBlock, StepsImage, StepsText,
  ActionBtn, ActionSection, ActionText, ActionTitle
} from './styles';
import HeroesSlider from "../../components/HeroesSlider";
import SearchIcon from '@mui/icons-material/Search';
import {IAnketa} from "../ChangePage/ChangePage";
import axios from "axios";
import {API_HOST, userSession} from "../../services/auth/ApiService/client";
import {Hero} from "../../components/HeroesSlider/HeroesSlider";
import {setLoginOpen} from "../../store/appSlice";
import {useDispatch} from "react-redux";

const HeroesPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [chosenSolders, setChosenSolders] = useState<IAnketa[]>([]);
  const [solders, setSolders] = useState<IAnketa[]>([]);
  const [page, setPage] = useState<number>(1);
  const [searchText, setSearchText] = useState<string>('');
  useEffect(() => {
    const loadData = () => {
      axios.get(`${API_HOST}/solders`).then((response) => {
        const data = response.data as IAnketa[];
        setChosenSolders(data);
      })
      axios.get(`${API_HOST}/solders?page=2`).then((response) => {
        const data = response.data as IAnketa[];
        console.log(data)
        setSolders(data);
      })
    }
    loadData()
  }, []);
  const onButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    axios.get(`${API_HOST}/solders?search=${searchText}`).then((response) => {
      const data = response.data as IAnketa[];
      setSolders(data);
    })
  };
  return (
    <>
      <MainSection>
        <Container style={{ paddingLeft: '0px', paddingRight: '0px' }}>
          <MainSectionContent>
            <MainTitle>
              <img src={logoImage} />
              <span>Аллея <br/> <b>ГЕРОЕВ</b></span>
            </MainTitle>
            <MainDescription>
              <MainDescriptionSmall>
                Расскажите об их подвигах людям
              </MainDescriptionSmall>
              <MainBtn onClick={(e) => {
                if (!userSession.getSession()) {
                  dispatch(setLoginOpen(true))
                } else {
                  navigate('/create/75433dbf-6d4c-4613-8778-bf906f0eda77')
                }
              }}>Поделиться историей</MainBtn>
            </MainDescription>
            {chosenSolders && chosenSolders.length > 0 && (<HeroesSlider heroes={chosenSolders.map((solder) => {
              return {
                id: solder.id,
                name: solder?.name,
                description: solder?.quote,
                fullText: solder?.quote,
                img: solder?.photo?.src,
              } as Hero
            })} />)}
          </MainSectionContent>
        </Container>
      </MainSection>
      <SearchSection>
        <Container style={{ paddingLeft: '0px', paddingRight: '0px' }}>
          <SearchTextContainer>
            <SearchHeader>
              У нас хранится более <span>8000</span> историй о подвигах героев
            </SearchHeader>
            <SearchSolderForm>
              <TextField id="search-solder" label="Найти историю" fullWidth value={searchText} onChange={(e) => {
                setSearchText(e.target.value);
              }} />
              <Button variant="outlined" startIcon={<SearchIcon />} onClick={onButtonClick}></Button>
            </SearchSolderForm>
          </SearchTextContainer>
          <SoldersContainer>
            {solders && solders.length > 0 && solders.map((hero, index) => (
                <SolderContainer key={hero.name + index + 'chosen'}>
                  <SolderFront>
                    {hero?.photo?.src && (<SolderImg alt="text" src={hero?.photo?.src} />)}
                    <SolderTextContainer>
                      <SolderTextName>
                        {hero.name}
                      </SolderTextName>
                    </SolderTextContainer>
                  </SolderFront>
                  <SolderBack>
                    <SolderBackText>
                      {hero.quote}
                    </SolderBackText>
                    <SolderButton to={`/solder/${hero.id}`}>
                      Посмотреть полную историю
                    </SolderButton>
                  </SolderBack>
                </SolderContainer>
            ))}
            {solders && solders.length > 10 && (<SoldersShowMore>Показать еще</SoldersShowMore>)}
          </SoldersContainer>
        </Container>
      </SearchSection>
      <HonorSection>
        <Container>
          <HonorTitle>Сохраните память о подвигах героев</HonorTitle>
          <HonorImages>
            <HonorText>С помощью интерактивной страницы
              вы можете сохранить историю подвигов
              вашего близкого человека.</HonorText>
            <HonorImage1 src={hi1}/>
            <HonorImage2 src={hi2}/>
            <HonorImage3 src={hi3}/>
            <HonorImage4 src={hi4}/>
            <HonorImage5 src={hi5}/>
          </HonorImages>
        </Container>
      </HonorSection>
      <FamilySection>
        <FamilyTitle>С помощью нашей страницы вы сможете</FamilyTitle>
        <FamilyImages>
          <FamilyBlock1>
            <FamilyImage src={f1} />
            <FamilyText>Рассказать о жизни героя</FamilyText>
          </FamilyBlock1>
          <FamilyBlock2>
            <FamilyImage src={f2} />
            <FamilyText>Рассказать о Военном пути</FamilyText>
          </FamilyBlock2>
          <FamilyBlock3>
            <FamilyImage src={f3} />
            <FamilyText>Рассказать о Подвигах и Наградах героя</FamilyText>
          </FamilyBlock3>
        </FamilyImages>
      </FamilySection>
      <StepsSection>
        <Container>
          <StepsTitle>Делиться историями просто</StepsTitle>
          <StepsContainer>
            <StepsBlock>
              <StepsImage src={s1} />
              <StepsText>
                Зарегистрируйтесь на сайте
              </StepsText>
            </StepsBlock>
            <StepsBlock>
              <StepsImage src={s2} />
              <StepsText>
                Заполните форму Героя
              </StepsText>
            </StepsBlock>
            <StepsBlock>
              <StepsImage src={s3} />
              <StepsText>
                Получите табличку
              </StepsText>
            </StepsBlock>
          </StepsContainer>
        </Container>
      </StepsSection>
      <ActionSection>
        <ActionTitle>ПОДЕЛИТЕСЬ НАСЛЕДИЕМ ГЕРОЯ</ActionTitle>
        <ActionText>С нашими табличками памяти</ActionText>
        <ActionBtn variant="contained" onClick={(e) => {
          if (!userSession.getSession()) {
            dispatch(setLoginOpen(true))
          } else {
            navigate('/create/75433dbf-6d4c-4613-8778-bf906f0eda77')
          }
        }}>Заполнить анкету</ActionBtn>
      </ActionSection>
    </>
  );
}

export default HeroesPage;