import React, {useCallback, useEffect, useState} from 'react';
import {Box, Typography, Container, Divider, FormControlLabel, Checkbox, TextField, Grid} from '@mui/material';
import {Link, useNavigate} from 'react-router-dom';
import { FullscreenControl, GeolocationControl, Map, Placemark, RouteButton, YMaps } from '@pbe/react-yandex-maps';
import SpringModal from '../../components/Modal';
import ImageSlider from '../../components/ImageSlider';
import qrImage from '../../assets/img/qr.png';
import {
    BackgroundBlock,
    BackgroundContainer,
    BioBox,
    BioLink,
    BioPaper,
    BioTitle,
    CitateBox,
    ContentCopyIconBtn,
    DetailsBox,
    DetailsContainer,
    DetailsRow,
    GraveAddress,
    GraveDivider,
    Graveyard,
    GraveyardName,
    HumanDate,
    HumanName,
    HumanYears,
    MainTitle,
    ModalContent,
    ModalDivider,
    ModalHeader,
    NameDivider,
    PhotoContainer,
    QRCode,
    SubCitateBox
} from './styles';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import { styled } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AddIcon from '@mui/icons-material/Add';
import client, {API_HOST, UI_HOST, userSession} from "../../services/auth/ApiService/client";
import {IAnketa} from "../ChangePage/ChangePage";
import {paymentSend} from "../../utils/billing";

const AnketaContainer = styled(Box)`
    display: flex;
    flex-wrap: wrap;
`;

export interface IReferalState {
    count: number;
    percent: number;
}
export interface IProfile {
    id?: string;
    email?: string;
    phone?: string;
    name?: string;
    persentIsFixed?: number;
    referalPercent?: boolean;
    referalId?: string;
    nextCount?: number;
    referalState?: IReferalState[];
    summ?: number;
}

const getMonthByDate = (month: number) => {
    const months = [
        'Января',
        'Февраля',
        'Мерта',
        'Апреля',
        'Мая',
        'Июня',
        'Июля',
        'Августа',
        'Сентября',
        'Октября',
        'Ноября',
        'Декабря',
    ]
    return months[month];
}

const ProfilePage = () => {
  const [ankets, setAnkets] = useState<IAnketa[]>([]);
  const [anketsRef, setAnketsRef] = useState<IAnketa[]>([]);
  const [profile, setProfile] = useState<IProfile>({name: '', email: '', phone: ''});
  const navigate = useNavigate()

    useEffect(() => {
        const loadData = () => {
            client
                .get('/ankets')
                .then((response) => {
                    const { data } = response;
                    setAnkets(data);
                })
            client
                .get('/my-profile')
                .then((response) => {
                    const { data } = response;
                    setProfile(data);
                    console.log(data)
                    if (data.referalId) {
                        client
                            .get(`/ankets?referal=${data.referalId}`)
                            .then((response) => {
                                const { data: dataRef } = response;
                                console.log(dataRef)
                                setAnketsRef(dataRef);
                            })
                    }
                })
        }
        loadData()
    }, []);
    const onBoxSubmit = (event: React.FormEvent<HTMLDivElement>) => {
        event.preventDefault();
        client.post('/my-profile', profile)
    };

    const handlePay = (id: string | undefined, orderId: string | undefined) => {
        if (id && orderId) {
            paymentSend(id, orderId).then((response) => {
                console.log(response)
            });
        }
    }
    return (
    <>
        <Container style={{marginTop: '120px', paddingLeft: '0px', paddingRight: '0px',}}>
          <MainTitle variant="h4" sx={{marginBottom: '40px'}}>Основные данные пользователя</MainTitle>
            {profile?.referalPercent && (<Card sx={{ minWidth: 275 }}>
                <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        Вы являетесь партнером
                    </Typography>
                    <Typography variant="h5" component="div">
                        Ваше текущее вознаграждение: <b>{profile?.summ}</b> руб.
                    </Typography>
                    {/*<Typography sx={{ mb: 1.5 }} color="text.secondary">*/}
                    {/*    Ваш текущий процент вознаграждения: <b>{profile?.referalPercent}%</b>*/}
                    {/*</Typography>*/}
                    {/*{profile?.nextCount && (<Typography variant="body2">*/}
                    {/*    Заявок до следующего уровня вознаграждения: {profile?.nextCount}*/}
                    {/*</Typography>)}*/}
                    <Typography variant="body2">
                        Ваша ссылка для заказа: <Link to={`${UI_HOST}/order?r=${profile?.referalId}`}>{UI_HOST}/order?r={profile?.referalId}</Link> <ContentCopyIconBtn onClick={() => navigator.clipboard.writeText(`${UI_HOST}/order?r=${profile?.referalId}`)} />
                    </Typography>
                </CardContent>
            </Card>)}
            <Grid container spacing={5}  style={{marginTop: '10px', paddingLeft: '0px', paddingRight: '0px',}}>
                <Grid item xs={6}>
                    <Box
                        component="form"
                        onSubmit={onBoxSubmit}>
                        <FormControl fullWidth sx={{ m: 1 }}>
                            <TextField id="outlined-basic1" label="ФИО или название организации" variant="outlined" defaultValue={profile.name} value={profile.name} onChange={(e: any) => {
                                setProfile({
                                    ...profile,
                                    name: e.target.value,
                                })
                            }}/>
                        </FormControl>
                        <FormControl fullWidth sx={{ m: 1 }}>
                            <TextField fullWidth id="outlined-basic2" label="Номер телефона" variant="outlined" defaultValue={profile.phone} value={profile?.phone ?? ''} onChange={(e: any) => {
                                setProfile({
                                    ...profile,
                                    phone: e.target.value,
                                })
                            }}/>
                        </FormControl>
                        <FormControl fullWidth sx={{ m: 1 }}>
                            <TextField id="outlined-basic3" label="Email" variant="outlined" defaultValue={profile.email} value={profile.email} onChange={(e: any) => {
                                setProfile({
                                    ...profile,
                                    email: e.target.value,
                                })
                            }}/>
                        </FormControl>
                        <Button sx={{ m: 1 }} variant="contained" type='submit'>Сохранить</Button>
                        <Box>
                            <Button sx={{ m: 1 }} variant="contained" color="error" size="large" onClick={(e) => {
                                e.preventDefault()
                                userSession.clearSession()
                                navigate('/')
                            }}>Выйти из аккаунта</Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Container>
        <Container style={{marginTop: '40px', paddingLeft: '0px', paddingRight: '0px',}}>
            <MainTitle variant="h4" sx={{marginBottom: '40px', m: 2}}>Мои моменты памяти</MainTitle>
            <AnketaContainer>
                {ankets && ankets?.map((anketa, key) => {
                    const link = anketa.isSolder ? `/solder/${anketa.id}` : `/anketa/${anketa.id}`;
                    const status = anketa.status === 'active' ? 'Активна' : 'Отключена';
                    const statusCheck = anketa.status === 'active';
                    const date = new Date(anketa.createdAt);
                    const dateString = `${date.getDate() + 1} ${getMonthByDate(date.getMonth())} ${date.getFullYear()} года`
                    return <Card sx={{ width: 345, m: 2 }} key={key}>
                        <CardHeader
                            action={
                                <>
                                    <Link to={link}>
                                        <IconButton aria-label="view">
                                            <RemoveRedEyeIcon />
                                        </IconButton>
                                    </Link>
                                    <Link to={`/change/${anketa.id}`}>
                                        <IconButton aria-label="settings">
                                            <EditIcon />
                                        </IconButton>
                                    </Link>
                                </>
                            }
                            title={anketa.name}
                            subheader={anketa.dateBorn + " - " + anketa.dateEnd}
                            sx={{height: '115px', alignItems: 'flex-start'}}
                        />
                        <CardMedia
                            component="img"
                            height="194"
                            image={anketa?.photo?.src}
                            alt={anketa?.name}
                        />
                        <CardActions disableSpacing>
                            {(anketa.isTrial || anketa?.orders[0]?.status === 'NEW') && !anketa.isSolder ? (
                                <>
                                    <Button onClick={() => handlePay(anketa?.tariffId, anketa?.orderId)} variant="contained" color="primary" sx={{ m: 1 }}>Оплатить</Button>
                                    <span>Анкета будет удалена <br /> {dateString}</span>
                                </>
                            ) : (
                                <FormControlLabel control={<Checkbox defaultChecked={statusCheck} />} label={status} sx={{ m: 1 }}/>
                            )}
                        </CardActions>
                    </Card>
                })}
            </AnketaContainer>
            <Link to="/order">
                <Button sx={{ m: 2 }} size="large" variant="contained">
                    <AddIcon />
                    Создать Момент Памяти
                </Button>
            </Link>
            {anketsRef?.length > 0 && (<>
                <MainTitle variant="h4" sx={{marginBottom: '40px', m: 2}}>Моменты памяти клиентов</MainTitle>
                <AnketaContainer>
                    {anketsRef && anketsRef?.map((anketa, key) => {
                        const link = anketa.isSolder ? `/solder/${anketa.id}` : `/anketa/${anketa.id}`;
                        const status = anketa.status === 'active' ? 'Активна' : 'Отключена';
                        const statusCheck = anketa.status === 'active';
                        const date = new Date(anketa.createdAt);
                        const dateString = `${date.getDate() + 1} ${getMonthByDate(date.getMonth())} ${date.getFullYear()} года`
                        return <Card sx={{ width: 345, m: 2 }} key={key}>
                            <CardHeader
                                action={
                                    <>
                                        <Link to={link}>
                                            <IconButton aria-label="view">
                                                <RemoveRedEyeIcon />
                                            </IconButton>
                                        </Link>
                                        <Link to={`/change/${anketa.id}`}>
                                            <IconButton aria-label="settings">
                                                <EditIcon />
                                            </IconButton>
                                        </Link>
                                    </>
                                }
                                title={anketa.name}
                                subheader={anketa.dateBorn + " - " + anketa.dateEnd}
                                sx={{height: '115px', alignItems: 'flex-start'}}
                            />
                            <CardMedia
                                component="img"
                                height="194"
                                image={anketa?.photo?.src}
                                alt={anketa?.name}
                            />
                            <CardActions disableSpacing>
                                {(anketa.isTrial || anketa?.orders[0]?.status === 'NEW') && !anketa.isSolder ? (
                                    <>
                                        <Button onClick={() => handlePay(anketa?.tariffId, anketa?.orderId)} variant="contained" color="primary" sx={{ m: 1 }}>Оплатить</Button>
                                        <span>Анкета будет удалена <br /> {dateString}</span>
                                    </>
                                ) : (
                                    <FormControlLabel control={<Checkbox defaultChecked={statusCheck} />} label={status} sx={{ m: 1 }}/>
                                )}
                            </CardActions>
                        </Card>
                    })}
                </AnketaContainer>
            </>)}
        </Container>

    </>
  );
}

export default ProfilePage;