import axios from "axios";
import client, {API_HOST} from "../services/auth/ApiService/client";

const username = '336848';
const password = 'test_AGzmZ5VkGCLi_JZjkx4lLbQtXc4SioFIK3zLhy_aeuk';

type Billing = {
    "id": string,
    "status": string,
    "amount": {
        "value": string,
        "currency": string
    },
    "description": string,
    "recipient": {
        "account_id": string,
        "gateway_id": string
    },
    "created_at": string,
    "confirmation": {
        "type": string,
        "confirmation_url": string
    },
    "test": boolean,
    "paid": boolean,
    "refundable": boolean,
    "metadata": object
}

type BillinPayload = {
    "amount": {
        "value": string,
        "currency": string
    },
    "capture": boolean,
    "confirmation": {
        "type": string,
        "return_url": string
    },
    "description": string
}

export const paymentSend = async (tarifId: string, orderId: string) => {
    const billing = await client.get(`${API_HOST}/get-payment-link/${tarifId}/${orderId}`) as any;
    console.log(billing);
    localStorage.setItem('activePayment', billing.data.id as string);
    localStorage.setItem('activeOrder', orderId as string);
    console.log(orderId)
    window.location.href = billing.data.confirmation.confirmation_url;
}

export const verifyPayment = async () => {
    const billingId = getCurrentBilling();
    const orderId = localStorage.getItem('activeOrder') as string;
    if (billingId) {
        const result = await client.get(`${API_HOST}/verify-payment-link/${billingId}/${orderId}`);
        return result?.data?.paid;
    }
    return false;
}

export const getCurrentBilling = () => {
    return localStorage.getItem('activePayment') as string;
}

export const addTextForYears = (years: number) => {
    if (years === 1) {
        return `${years} год`
    } else if (years > 1 && years < 5) {
        return `${years} года`
    } else {
        return `${years} лет`
    }
}

export const setupImageHeightWidthByContainer = (containerWidth: number, containerHeight: number, imageWidth: number, imageHeight: number) => {
    if (containerWidth / containerHeight > imageWidth / imageHeight) {
        return {
            width: containerHeight * imageWidth / imageHeight,
            height: containerHeight
        }
    } else {
        return {
            width: containerWidth,
            height: containerWidth * imageHeight / imageWidth
        }
    }
}