import React, { useEffect, useRef, useState } from "react";
import { createRoot } from "react-dom/client";
import {
    YMaps,
    Map,
    Placemark,
    SearchControl,
    TypeSelector, ZoomControl, FullscreenControl, GeolocationControl, RouteButton
} from "@pbe/react-yandex-maps";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {Box, styled} from "@mui/material";

const API_KEY = "85a1be39-eeb8-4907-a966-dae0a8b25f61";

const center = [47.2313, 39.7233];

const images = [...Array(5)].map((n, i) => {
    const letter = String.fromCharCode(i + 97);
    return `https://img.icons8.com/ios-filled/2x/marker-${letter}.png`;
});

const YMapBox = styled(Box)`
    margin-top: 20px;
    width: 100%;
    height: 500px;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid #e4e4e4;
`;
const YMapContainer = styled(Box)`
    margin-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
`;

interface IProps {
    newCoords: number[];
    setNewCoords: any;
    address: (string | null);
    setAddress: any;
}

const MapSelect = ({ newCoords, setNewCoords, address = '', setAddress }: IProps) => {
    const ref: any = useRef();
    const ref2: any = useRef();
    const ymaps: any = React.useRef(null);

    const [value, setValue] = useState("");
    const [options, setOptions] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                if (value) {
                    const res = await fetch(
                        `https://geocode-maps.yandex.ru/1.x/?apikey=${API_KEY}&format=json&geocode=${value}`
                    );
                    const data = await res.json();
                    const collection = data.response.GeoObjectCollection.featureMember.map(
                        (item: any) => item.GeoObject
                    );
                    setOptions(() => collection);
                    console.log(`set option`, collection, value)
                }
            } catch (e) {
                console.log(e);
            }
        })();
    }, [value]);

    return (
        <YMapContainer>
            <Autocomplete
                freeSolo
                filterOptions={(x) => x}
                value={value}
                onChange={(event, newValue) => {
                    console.log('check onChange')
                    if (typeof newValue === "string") {
                        setValue(() => newValue);
                        const obg: any = options.find(
                            (item: any) =>
                                newValue.includes(item.name) &&
                                newValue.includes(item.description)
                        );
                        const coords = obg.Point.pos
                            .split(" ")
                            .map((item: any) => Number(item))
                            .reverse();
                        console.log(coords, newValue)
                        setNewCoords(() => coords);
                        setAddress(() => newValue);
                    } else {
                        console.log(newValue);
                    }
                }}
                onInputChange={(e: any) => {
                    console.log('check onInput')
                    if (e) {
                        setValue(e?.target?.value);
                    }
                }}
                options={options.map((item: any) => `${item.name} ${item.description}`)}
                renderInput={(params) => (
                    <TextField {...params} label="Введите адрес" />
                )}
            />
            <YMapBox>
                {newCoords[0] && (
                    <Map
                        instanceRef={ref2}
                        state={{
                            center: newCoords ?? [69.050867, 33.256601],
                            zoom: 17,
                            controls: [],
                            type: 'yandex#hybrid'
                        }}
                        onLoad={(e: any) => {
                            ymaps.current = e;
                            // const points: number[][] = [];
                            // const bounds = e.util.bounds.fromPoints(points);
                            // const pos = e.util.bounds.getCenterAndZoom(bounds, [700, 700]);
                            // ref2.current.setZoom(pos.zoom);
                            // ref2.current.setCenter(pos.center);
                            // if (!ref2?.current) return;
                            // ref2?.current?.setBounds(bounds, { checkZoomRange: true });

                            e.geocode(newCoords).then((res: any) => {
                                const firstGeoObject = res.geoObjects.get(0);
                                const newAddress = [
                                    firstGeoObject.getPremiseNumber(),
                                    firstGeoObject.getThoroughfare() || firstGeoObject.getPremise(),
                                    firstGeoObject.getLocalities().length
                                        ? firstGeoObject.getLocalities()
                                        : firstGeoObject.getAdministrativeAreas(),
                                ]
                                    .filter(Boolean)
                                    .join(" ");
                                console.log(newAddress);

                                setAddress(() => newAddress);
                                setValue(() => newAddress);
                            });
                        }}
                        width="100%"
                        height="100%"
                        modules={["control.ZoomControl"]}
                        onClick={(event: any) => {
                            const coords = event.get("coords");
                            setNewCoords(() => coords);

                            ymaps.current.geocode(coords).then((res: any) => {
                                const firstGeoObject = res.geoObjects.get(0);
                                const newAddress = [
                                    firstGeoObject.getLocalities().length
                                        ? firstGeoObject.getLocalities()
                                        : firstGeoObject.getAdministrativeAreas(),
                                    firstGeoObject.getThoroughfare() || firstGeoObject.getPremise(),
                                    firstGeoObject.getPremiseNumber()
                                ]
                                    .filter(Boolean)
                                    .join(" ");
                                // ref.current.getMap().hint.open(coords, newAddress);
                                setAddress(() => newAddress);
                                setValue(() => newAddress);
                            });
                        }}
                    >
                        <ZoomControl />
                        <SearchControl options={{ float: "right" }} />
                        <FullscreenControl />
                        <GeolocationControl />
                        <RouteButton />
                        <SearchControl />
                        <TypeSelector />
                        {/*<TypeSelector options={{ float: "right" }} />*/}
                        {newCoords && (
                            <Placemark
                                instanceRef={ref}
                                onDragEnd={(event: any) => {
                                    const coords = ref.current.geometry._coordinates;
                                    setNewCoords(() => coords);
                                    ymaps.current.geocode(coords).then((res: any) => {
                                        const firstGeoObject = res.geoObjects.get(0);
                                        const newAddress = [
                                            firstGeoObject.getLocalities().length
                                                ? firstGeoObject.getLocalities()
                                                : firstGeoObject.getAdministrativeAreas(),
                                            firstGeoObject.getThoroughfare() || firstGeoObject.getPremise(),
                                            firstGeoObject.getPremiseNumber()
                                        ]
                                            .filter(Boolean)
                                            .join(" ");
                                        // ref.current.getMap().hint.open(coords, newAddress);
                                        setAddress(() => newAddress);
                                        setValue(() => newAddress);
                                    });
                                }}
                                geometry={newCoords}
                                options={{
                                    iconImageSize: [30, 30],
                                    draggable: true,
                                    preset: "islands#greenIcon",
                                    hideIconOnBalloonOpen: false,
                                    openEmptyHint: true
                                }}
                                properties={{
                                    iconContent: "+",
                                    hintContent: address
                                }}
                            />
                        )}
                    </Map>
                )}
            </YMapBox>
        </YMapContainer>
    );
}

export default MapSelect;