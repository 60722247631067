import {createSlice} from "@reduxjs/toolkit";

const initialState: any = {
  page: 1,
  groupsChapter: 'counter',
  enabledTabIds: [1],
  modalName: null,
  loginOpen: false,
  loading: false,
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLoginOpen: (state, { payload }) => {
        state.loginOpen = payload;
    },
    setLoading: (state, { payload }) => {
      state.loginOpen = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    enableTabId: (state, { payload }) => {
      if (!state.enabledTabIds.includes(payload)) {
        state.enabledTabIds.push(payload);
      }
    },
    setModalName: (state, { payload }) => {
      state.modalName = payload;
    },
    resetApp: () => {
      return initialState;
    },
  },
});

export const {
  setPage,
  enableTabId,
  setModalName,
  resetApp,
    setLoginOpen,
    setLoading,
} = appSlice.actions;

export default appSlice.reducer;
