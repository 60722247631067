import React from 'react';
import { Box, Typography, List, ListItem, ListItemText, Container } from '@mui/material';
import {ActionBtn, ActionSection, ActionText, ActionTitle} from "../HomePage/styles";
import {userSession} from "../../services/auth/ApiService/client";
import {setLoginOpen} from "../../store/appSlice";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

const OffertaPage = () => {
      const dispatch = useDispatch()
      const navigate = useNavigate()
      return (
          <>
                <Container maxWidth="md" sx={{marginTop: '82px'}}>
                      <Box sx={{my: 4, textAlign: 'justify'}}>
                            <Typography variant="h3" component="h3" gutterBottom>
                                  ПУБЛИЧНЫЙ ДОГОВОР - ОФЕРТА
                            </Typography>
                            <h4>1. ОБЩИЕ ПОЛОЖЕНИЯ</h4>
                            1.1. Настоящий документ является официальным предложением (публичной
                            офертой) ООО &quot;СЕВЕРСНАБ ГРУПП&quot; (ИНН/КПП: 5108005388/510801001, ОГРН: 1245100004214),
                            (далее по тексту &quot;Исполнитель&quot;) в адрес любого физического лица
                            (далее по тексту &quot;Заказчик&quot;), обладающего дееспособностью и
                            необходимым полномочием заключить с ООО &quot;СЕВЕРСНАБ ГРУПП&quot; настоящий
                            договор на условиях, определенных в настоящей оферте и содержит все
                            существенные условия договора. Отношения в области защиты прав
                            потребителей регулируются Гражданским кодексом Российской
                            Федерации, Законом &quot;О защите прав потребителей&quot; и принимаемыми в
                            соответствии с ним иными федеральными законами и правовыми актами
                            Российской Федерации.<br/>
                            1.2. Полным и безоговорочным акцептом является любое из указанных
                            действий Заказчика, совершенное ранее по времени: оплата Заказчиком
                            или лицом, действующим в интересах Заказчика, (в интересах
                            недееспособных за несовершеннолетием граждан, сделку заключают их
                            представители), любой из услуг Исполнителя, либо акцепт Оферты
                            посредством совершения в используемом Заказчиком средстве
                            просмотра сайта Исполнителя действий по ее принятию (создание
                            страницы, создание личного кабинета), либо путем проставления
                            отметки в графе &quot;Я подтверждаю своё согласие с условиями
                            предоставления услуг и политикой конфиденциальности&quot; в контексте
                            совершения любого действия Заказчиком на Сайте Исполнителя по
                            адресу <a href='https://на-севере-память.рф/'>на-севере-память.рф</a>, а также любые иные пользовательские
                            действия Заказчика на Сайте Исполнителя.<br/>
                            1.3. Заказчик, осуществляя акцепт настоящей Оферты, в порядке,
                            определенном п. 1.2, подтверждает, что ознакомлен, согласен,
                            полностью и безоговорочно принимает все условия настоящего
                            договора, политики конфиденциальности, соглашения об использовании
                            персональных данных, в том виде, в каком они изложены в тексте
                            Оферты и ее приложений.<br/>
                            1.4. Акцептуя настоящую Оферту, Заказчик гарантирует, что он имеет
                            законные права вступать в договорные отношения с Исполнителем, что
                            не преследует целей, признаваемых в РФ незаконными.<br/>
                            1.5. Для реализации прав и обязанностей, вытекающих из заключения
                            настоящего договора вследствие принятия (одобрения) Заказчиком
                            Оферты, исполнение ее условий не требует подписания Заказчиком
                            и/или Исполнителем каких-либо документов, сохраняя при этом свою
                            юридическую силу.<br/>
                            1.6. В настоящей Оферте и ее приложениях, понятия, приведённые в
                            настоящем пункте и подпунктах, имеют следующее значение:<br/>
                            1.6.1. Оферта – настоящий документ &quot;Публичный договор-оферта&quot;,
                            опубликованный в сети Интернет по адресу: <a href='https://на-севере-память.рф/'>на-севере-память.рф</a> <br/>
                            1.6.2. Акцепт оферты – полное и безоговорочное принятие оферты
                            путем осуществления Заказчиком действий, указанных в
                            настоящем договоре. Акцепт оферты создает договор оферты. <br/>
                            1.6.3. Заказчик – лицо, осуществившее акцепт оферты, и являющееся
                            таким образом Заказчиком услуг Исполнителя по заключенному
                            договору оферты.<br/>
                            1.6.4. Договор оферты – договор между Исполнителем и Заказчиком на
                            оказание услуг, который заключается посредством акцепта
                            оферты.<br/>
                            1.6.5. Прейскурант – действующий систематизированный перечень
                            услуг, которые могут быть оказаны Исполнителем с ценами,
                            публикуемыми на Интернет-ресурсе по адресу: <a href='https://на-севере-память.рф/prices'>на-севере-память.рф/prices</a> и/или в личном кабинете Заказчика.<br/>
                            1.6.6. Тариф (тарифный план) – совокупность услуг/работ,
                            предложенных Заказчиком Исполнителю по договору оферты, по
                            стоимости, условий и объему предоставления услуг.<br/>
                            1.6.7. Дополнительные услуги – услуги, не входящие в соответствующий
                            Тарифный план, которые заказываются Заказчиком отдельно в
                            установленном для соответствующей Дополнительной услуги
                            порядке.
                            1.6.8. Сайт - совокупность интернет-сервисов, размещенных по
                            зарегистрированному доменному имени <a href='https://на-севере-память.рф/'>на-севере-память.рф</a>, а
                            также всех его поддоменах.<br/>
                            1.6.9. Личный Кабинет - электронный интерфейс управления Услугами,
                            доступ к которому Абонент осуществляет с помощью
                            идентификаторов доступа на странице авторизации, находится по
                            адресу: <a href='https://на-севере-память.рф/profile'>на-севере-память.рф/profile</a> <br/>
                            1.6.10. Страница Заказчика – страница, сформированная/создаваемая
                            на сайте <a href='https://на-севере-память.рф/'>на-севере-память.рф</a> на основании распоряжения
                            Исполнителя или вследствие действий (распоряжения)
                            Исполнителя.<br/>
                            1.6.11. Подписка – разновидность услуг, предоставляемых на
                            определенный период Исполнителем Заказчику с возможностью
                            пролонгации. Подписка содержит доступ к контенту,
                            возможностям или функциям за определенную плату по
                            указанному тарифному плану.<br/>
                            <h4>2. ПРЕДМЕТ ДОГОВОРА</h4>
                            2.1. Предметом настоящей оферты является оказание Исполнителем
                            Заказчику услуг/выполнение работ в соответствии с условиями
                            настоящей оферты, текущим прейскурантом и выбранному
                            Исполнителем тарифу.<br/>
                            2.2. Исполнитель имеет право в любой момент изменять Прейскурант и
                            условия настоящей публичной оферты в одностороннем порядке без
                            предварительного согласования с Заказчиком, обеспечивая при этом
                            публикацию изменений на Интернет-ресурсе по адресу: <a href='https://на-севере-память.рф/'>на-севере-память.рф</a>. Измененные условия вступают в силу с момента
                            их опубликования. Стоимость уже оплаченных услуг/работ – изменению
                            не подлежит.<br/>
                            2.3. Предусмотренные предметом настоящего договора услуги могут
                            оказываются Исполнителем как дистанционно посредством сети
                            Интернет, телефонии или путем личных встреч, по выбору Исполнителя.
                            В случае если услуги/работы предполагают передачу материального
                            носителя, он по выбору Исполнителя и/или по согласованию с
                            Заказчиком может быть передан как путем нарочной передачи, так и
                            путем отправления почтовым отправлением по адресу доставки,
                            указанному Заказчиком при регистрации или оформлении заказа.<br/>
                            2.4. Заказчик не вправе приобретать услуги Исполнителя в целях, которые
                            могут быть признаны в РФ незаконными.<br/>
                            2.5. Настоящий договор является публичными и бессрочным и действует до
                            прекращения обязательств любой из сторон в порядке, установленном
                            Договором. Условия Договора изложены в тексте договора,
                            Прейскуранте и в условиях тарифного плана, выбранного Заказчиком
                            самостоятельно, которые также должны быть опубликованы и доступны
                            любой из сторон на сайте.<br/>
                            2.6. Неотъемлемой частью этой оферты является политика
                            конфиденциальности и соглашение об использовании персональных
                            данных, которые являются приложением и неотъемлемой частью
                            настоящего договора оферты и размещены Исполнителем по адресу <a href='https://на-севере-память.рф/'>на-севере-память.рф</a> <br/>
                            <h4>3. ОФОРМЛЕНИЕ ЗАКАЗА, ОПЛАТА УСЛУГ</h4>
                            3.1. Оформление заказа на оказание услуг и/или заказ дополнительных
                            услуг/работ по настоящему договору осуществляется посредством
                            регистрации на сайте и создания страницы или в момент оплаты, в
                            зависимости от того какое из действий произойдет ранее. Исполнитель,
                            перед созданием страницы обязан ознакомится с условиями согласно
                            настоящему договору и принять их, путем проставления отметки в графе
                            &quot;Я подтверждаю своё согласие с условиями предоставления услуг и
                            политикой конфиденциальности&quot;.<br/>
                            3.2. Оплата осуществляется Заказчиком как с помощью банковской карты,
                            так и по усмотрению Заказчика посредством использования
                            дополнительных способов оплаты, предоставленных на
                            интернет-странице Исполнителя на Сайте.<br/>
                            3.3. При заказе услуг Исполнителя Заказчик обязуется предоставить
                            следующую достоверную регистрационную информацию о себе:<br/>
                            3.3.1. фамилия, имя, отчество (на русском языке);<br/>
                            3.3.2. адрес электронной почты;<br/>
                            3.3.3. контактный телефон.<br/>
                            3.4. При регистрации Заказчика на Сайте ему открывается Личный Кабинет, в
                            котором отображаются все сведения о Заказчике, сообщенные или
                            внесенным им самостоятельно при заказе услуг (п. 3.3.). Из личного
                            кабинета Заказчик производит управление созданными им или с его
                            распоряжения страницами, в том числе вносит дополнения, изменяет их,
                            размещает дополнительные сведения, фотографии и/или видео,
                            производит заказ дополнительных услуг и т.д. По договоренности сторон
                            первоначальные сведения о Заказчике, внесение изменений в сведения
                            на странице памяти могут быть внесены Исполнителем или его
                            представителем по распоряжению Заказчика, в том числе
                            допускается/принимается в работу Исполнителем устное распоряжение
                            Заказчика, при условии его идентификации. Под идентификацией
                            стороны понимают возможность Заказчика сообщить логин и ФИО лица,
                            на которого оформлена страница.<br/>
                            3.5. Подтверждение факта размещения Заказа и\или внесения изменений в
                            личные данные на адрес электронной почты является дополнительным,
                            но не обязательным.<br/>
                            3.6. В случае технического сбоя, в том числе но не ограничиваясь, - не
                            отражения (отсутствия) внесенных изменений, какой либо информации в
                            Личном Кабинете, неполучения Заказчиком письма, отправленного на
                            адрес электронной почты, Заказчик связывается с Исполнителем через
                            форму обратной связи на сайте и описывает
                            выявленные несоответствия.<br/>
                            <h4>4. СРОК АКЦЕПТА</h4>
                            4.1. Настоящая публичная оферта вступает в силу с момента ее размещения
                            в сети Интернет на Сайте и действует до момента ее отзыва
                            Исполнителем.<br/>
                            4.2. Срок акцепта настоящей оферты к приобретению услуг Исполнителя в
                            рамках оказываемой конкретной услуги Исполнителя из всего
                            ассортимента оказываемых услуг, конкретного Тарифного плана может
                            быть ограничен Исполнителем сроками, о чем прямо указывается на
                            сайте.<br/>
                            4.3. Акцепт может быть отозван Заказчиком до момента его получения
                            Исполнителем.<br/>
                            <h4>5. СРОКИ И ПОРЯДОК ОКАЗАНИЯ УСЛУГ/ВЫПОЛНЕНИЯ РАБОТ</h4>
                            5.1. Исполнитель приступает к оказанию услуг/выполнению работ только при
                            выполнении Заказчиком следующих условий:<br/>
                            5.1.1. 5.1.1. Заказчик предоставил свои установочные, достоверные
                            регистрационные данные в том объеме, который определен
                            Исполнителем и необходим для исполнения последним
                            обязательств согласно выбранного Заказчиком тарифного плана и
                            (или) требуется действующим Законодательством для
                            совершения тех юридически значимых действий, которые
                            необходимо совершить для надлежащего исполнения Сторонами
                            своих обязательств.<br/>
                            5.1.2. 5.1.2. Заказчик осуществил полный и безусловный акцепт
                            оферты.<br/>
                            5.2. Сроки оказания услуг зависят от выбранного Заказчиком тарифа и
                            указываются непосредственно в тарифном плане.<br/>
                            5.3. Услуги по настоящему договору считаются оказанными Исполнителем в
                            полном объеме и с надлежащим качеством:<br/>
                            5.3.1. для тарифа &quot;Краткая страница&quot;: в момент формирования и
                            выгрузки QR-кода в личном кабинете Заказчика и предоставления
                            Заказчику доступа к странице;<br/>
                            5.3.2. для остальных тарифов и дополнительных услуг/работ в момент
                            выполнения Исполнителем последнего из включенных в список
                            услуг/работ согласно выбранного Заказчиком тарифного плана.<br/>
                            <br/>
                            По соглашению сторон в момент исполнения Исполнителем услуг/работ,
                            по настоящему договору, Акт об оказании услуг сторонами не
                            составляется.<br/>
                            5.5. С момента формирования страницы, созданной по заказу Заказчика,
                            ответственность за материалы, сведения, информацию, файлы и иное,
                            размещенное Заказчиком или с распоряжения Заказчика на созданной
                            Заказчиком странице - несет Заказчик.<br/>
                            5.6. Стороны соглашаются что в случае получения Исполнителем
                            требования от правообладателя информации и/или данных (фото-видео
                            материалы) размещенных на странице Заказчика или от
                            контролирующего органа о закрытии страницы Заказчика в связи с
                            размещением на странице Заказчика информации/материалов с
                            нарушением чьих либо прав или интересов или законодательных актов
                            РФ, Исполнитель, как администратор сайта, закрывает страницу без
                            получения от Заказчика разрешения. О данном факте Исполнитель
                            обязан уведомить Заказчика посредством электронной почты, адрес
                            которой указан Заказчиком при регистрации или указанной в контактных
                            сведениях о Заказчике в личном кабинете в разумный срок.
                            <h4>6. ПРАВА И ОБЯЗАННОСТИ СТОРОН</h4>
                            6.1. Заказчик обязуется:<br/>
                            6.1.1. оплачивать услуги Исполнителя в порядке, размере и сроки,
                            предусмотренные настоящим Договором, включающим в этой
                            части условия оплаты, указанные в предложении Исполнителя на
                            Сайте.<br/>
                            6.1.2. посредством заполнения установленной Исполнителем формы на
                            Сайте предоставить свои регистрационные данные. Надлежащим
                            образом и достоверно заполнить регистрационную форму, указать
                            информацию, которая будет отображаться на странице памяти,
                            предоставить Исполнителю достоверные сведения/информацию,
                            необходимую последнему для надлежащего оказания
                            услуг/выполнения работ согласно выбранного Заказчиком
                            тарифного плана.<br/>
                            6.1.3. в процессе взаимодействия с Исполнителем, консультантами и
                            другими специалистами Заказчика, не затрагивать тему религии,
                            политики, не совершать действий, направленных на разжигание
                            межнациональной, межрелигиозной, любой иной розни.<br/>
                            6.1.4. не использовать обсценную лексику (в т.ч., но не исключительно,
                            нецензурные выражения), а также идиоматические обороты,
                            аллегории и т.п. лингвистические приемы пусть даже и
                            иносказательно нарушающие требования настоящего пункта.<br/>
                            6.1.5. предоставлять консультантам и иным привлеченным Заказчикам
                            специалистам достоверную и корректную
                            информацию/документацию/носители в том числе фотографии,
                            видеосъемка, без искажения. Заказчик должен обладать
                            законным правом публикации
                            информации/документации/сведений. В случае предоставления
                            искаженной (неверной) информации и/или информации которая
                            не принадлежит Заказчику и он не имеет права распространения
                            данной информации и т.д., и/или если данное обстоятельство
                            нарушает интеллектуальную собственность и/или затрагивает
                            права третьих лиц, ответственность за данное возлагается на
                            Заказчика.<br/>
                            6.1.6. в случае самостоятельного заполнения/формирования страницы
                            размещать на странице достоверную и корректную
                            информацию/документацию/сведения, в том числе, но не
                            ограничиваясь фотографии, видеосъемка.<br/>
                            6.1.7. заказчик должен обладать законным правом публикации
                            информации/документации/сведений, размещенных им или с его
                            указания Исполнителем на странице Заказчика. В случае
                            предоставления искаженной (неверной) информации и/или
                            информации которая не принадлежит Заказчику и он не имеет
                            права распространения данной информации/документации и т.д.,
                            в том числе если данное обстоятельство нарушает
                            интеллектуальную собственность и/или затрагивает права
                            третьих лиц, ответственность за данное возлагается на Заказчика.<br/>
                            6.1.8. не использовать информацию, полученную от Исполнителя,
                            способами, которые могут привести к нанесению ущерба
                            интересам Исполнителя.<br/>
                            6.1.9. в срок не позднее 3 (три) рабочих дня с момента опубликования
                            некролога, составленного Исполнителем по исходным данным
                            (сведениям, информации) предоставленным Заказчиком, или
                            корректировки исполнителем биографии, - проверить текст,
                            сведения, размещенные на странице. В случае если сведения
                            некорректны, немедленно сообщить о данном Исполнителю.<br/>
                            6.1.10. нести ответственность за размещенные на странице/-ах
                            Заказчика сведения, информацию, фото-видео материалы, и т.д.<br/>
                            6.1.11. Заключением настоящего договора Заказчик дает свое
                            разрешение на получение от Исполнителя любого
                            информирования, в том числе рекламного характера,
                            посредством СМС сообщений и/или посредством передачи
                            данных по электронной почте, указанной Заказчиком при
                            регистрации и/или измененной Заказчиком, при внесении
                            изменений в личные данные, в личном кабинете Заказчика.<br/>
                            6.1.12. Заказчик не имеет права размещать на странице Заказчика
                            информацию, противоречащую требованиям действующего
                            Законодательства Российской Федерации (фото, видео,
                            текстовую, а также в виде ссылок на другие, противоречащие
                            действующему законодательству РФ, источники), в том числе
                            информацию, нарушающую требования Федерального закона от
                            27 июля 2006 г. N 149-ФЗ &quot;Об информации, информационных
                            технологиях и о защите информации&quot; и иных
                            нормативно-правовых актов Российской Федерации.<br/>
                            6.2. Заказчик вправе:<br/>
                            6.2.1. требовать от Исполнителя предоставления информации по
                            вопросам организации и обеспечения надлежащего оказания
                            услуг.<br/>
                            6.2.2. требовать надлежащего и своевременного оказания услуг
                            Исполнителем.<br/>
                            6.2.3. обращаться к Исполнителю по всем вопросам, связанным с
                            оказанием услуг, а также задавать вопросы, связанные с
                            оказанием услуг.<br/>
                            6.3. Исполнитель обязуется:<br/>
                            6.3.1. организовать и обеспечить надлежащее оказание услуг в
                            соответствии с условиями, предусмотренными настоящим
                            договором и выбранному Заказчиком тарифному плану.<br/>
                            6.3.2. использовать все личные, персональные данные и иную
                            конфиденциальную информацию о Заказчике и/или лицах в
                            отношении которых Заказчик заполняет страницу для исполнения
                            обязательств по настоящему договору, при этом стороны
                            соглашаются что Исполнитель вправе передавать персональные
                            данные Заказчика третьим лицам, при условии что они
                            задействованы в исполнении Исполнителем обязательств по
                            настоящему договору. Заключением настоящего договора
                            Заказчик дает Исполнителю право использования своих
                            персональных данных в том числе их передачу третьим лицам,
                            при условии, что третье лицо задействовано в исполнении
                            Исполнителем настоящего договора.<br/>
                            6.3.3. проявлять уважение к личности Заказчика.<br/>
                            6.3.4. своевременно информировать Заказчика о расширении услуг,
                            скидках, акциях, иных предложениях Исполнителя путем СМС
                            информирования и/или посредством направления сведений по
                            электронной почте Заказчика.<br/>
                            6.4. Исполнитель вправе:<br/>
                            6.4.1. не приступать к оказанию услуг и/или приостановить их оказание
                            в случае нарушения Заказчиком условий по настоящему договору,
                            в т.ч., но не исключительно, при нарушении срока оплаты, до
                            момента осуществления оплаты, предоставления сведений,
                            данных.<br/>
                            6.4.2. самостоятельно определять формы и методы оказания
                            услуг/выполнения работ, предусмотренных настоящим договором.
                            Не приступать к исполнению своих обязательств по договору в
                            случае, если Заказчик не полностью заполнил данные в Личном
                            кабинете, и данное не позволяет выполнить работы/оказать
                            услуги в полном объеме и/или надлежащего качества.<br/>
                            6.4.3. самостоятельно определять состав специалистов (консультантов),
                            привлекаемых для оказания услуг/выполнения работ по Договору,
                            и по своему усмотрению распределять между ними работу.<br/>
                            6.4.4. при необходимости привлекать субподрядчиков для оказания
                            услуг/выполнения работ в рамках настоящего договора, при этом
                            за действия/бездействия субподрядчиков несет Исполнитель как
                            за свои собственные.<br/>
                            6.4.5. требовать оплаты своих услуг в соответствии с условиями
                            настоящего договора.<br/>
                            6.4.6. получать от Заказчика любую информацию, необходимую для
                            выполнения своих обязательств по настоящему договору.<br/>
                            6.4.7. внести изменения в условия публичной оферты и/или отозвать её
                            в любой момент до ее акцепта Заказчиком. В случае внесения
                            Исполнителем изменений в публичную оферту такие изменения
                            вступают в силу с момента размещения измененного текста
                            публичной оферты, если иной срок вступления изменений в силу
                            не определен дополнительно в тексте договора-оферты в новой
                            редакции. Заказчик обязан самостоятельно производить
                            ознакомление с условиями Оферты, при посещении сайта
                            Исполнителя. Любые изменения в Оферте вступают в силу с
                            момента их публикации. Заказчик, в случае непринятия условий
                            Оферты, обязан не позднее 24 часов от момента изменения
                            условий Оферты в письменной форме уведомить Исполнителя о
                            непринятии изменений условий Оферты. Неподача такого
                            заявления означает полный и безусловный акцепт (принятие)
                            Заказчиком измененных условий Оферты.<br/>
                            6.4.8. немедленно прекратить оказание услуг Заказчику в случаях
                            нарушения Заказчиком требований по п.п. 6.1.3-6.1.8, а также в
                            случае выявления в действиях Заказчика признаков действий, не
                            признаваемых в РФ законными, как то, но не исключительно в
                            связи с выявлением в действиях Заказчика признаков
                            злоупотребления правом, совершения Заказчиком
                            провокационных, или агрессивных, или оскорбительных действий
                            лексического или техногенного характера в отношении третьих
                            лиц, или самого Исполнителя. Под оскорбительными действиями
                            Стороны договорились считать также и действия, хоть и не
                            покрываемые терминами и определениями соответствующих
                            статей действующего законодательства, но соответствующие
                            таковым согласно поведенческим обычаям воспитанных людей.<br/>
                            6.4.9. По требованию правообладателя или иного уполномоченного
                            лица прекратить (снять с публикации и/или открытого доступа)
                            страницу Заказчика или отдельные
                            сведения/информацию/материалы размещенные на странице
                            Заказчика.<br/>
                            6.4.10. Исполнитель гарантирует доступность страницы в течении 10
                            (десяти) лет даже в случае ее фактического неиспользования
                            (отсутствие посещений) Заказчиком и/или третьими лицами. В
                            случае если страница по какой то причине оказалась недоступна
                            или у Заказчика или третьих лиц возникли какие либо проблемы
                            при посещении страницы (отсутствие или несоответствие
                            сведений, данных и т.д.) Заказчик сообщает о данном
                            Исполнителю
                            посредством оформления обращения на сайте <a href='https://на-севере-память.рф/'>на-севере-память.рф</a> &quot;Обратная связь&quot;, а Исполнитель, в свою
                            очередь обязан исправить (скорректировать) несоответствия в
                            разумный срок и сообщить о данном Заказчику.
                            Под неиспользованием страницы стороны понимают отсутствие
                            со стороны Заказчика или третьих лиц действий со страницей, в
                            том числе ее посещение.<br/>
                            6.4.11. Осуществляет мониторинг страницы Заказчика по вопросу о
                            размещении на странице информации, противоречащей
                            требованиям действующего Законодательства Российской
                            Федерации (фото, видео, текстовой, а также в виде ссылок на
                            другие, противоречащие действующему законодательству РФ,
                            источники), в том числе информации, нарушающей требованиям
                            Федерального закона от 27 июля 2006 г. N 149-ФЗ &quot;Об
                            информации, информационных технологиях и о защите
                            информации&quot; и иных нормативно-правовых актов Российской
                            Федерации. В случае обнаружения указанной информации
                            производит её удаление без извещения Заказчика об этом.
                            <h4>7. СТОИМОСТЬ УСЛУГ И ПОРЯДОК ОПЛАТЫ</h4>
                            7.1. Оплата приобретаемых Заказчиком услуг Исполнителя производится в
                            российских рублях.<br/>
                            7.2. Оплата приобретаемых Заказчиком услуг Исполнителя производится
                            любым из вариантов оплаты, представленным на Сайте.<br/>
                            7.3. Операция с использованием банковской карты считается совершенной с
                            момента зачисления перечисленных денежных средств на счета
                            Исполнителя.<br/>
                            7.4. Стоимость услуг Исполнителя указана к каждому Тарифу, и размещена
                            на Сайте в пределах действия сроков каждого конкретного ценового
                            предложения.<br/>
                            7.5. Все поступившие платежи являются задатком согласно ст. 380 ГК РФ,
                            оплата которого гарантирует возмещение ранее понесенных
                            Исполнителем расходов, в том числе но не исключительно,
                            подготовительных работ Исполнителя, включающих в себя создание и
                            размещение интеллектуальной собственности Исполнителя,
                            обеспечение работы информационных ресурсов сайта Исполнителя,
                            привлечение Исполнителем специалистов, оплата их простоя и т.д..<br/>
                            7.6. Для каждого Тарифа может быть предусмотрен индивидуальный порядок
                            оплаты, однако в случае если иное не предусмотрено в тарифе или
                            сторонами не оговорено дополнительно оплата осуществляется в
                            порядке единовременной оплаты в размере 100%.<br/>
                            7.7. Услуга считается оплаченной с момента поступления денежных средств
                            на расчетный счет Исполнителя.
                            <h4>8. ОТВЕТСТВЕННОСТЬ СТОРОН И РАЗРЕШЕНИЕ СПОРОВ</h4>
                            8.1. Стороны несут ответственность за неисполнение или ненадлежащее
                            исполнение своих обязательств по настоящему договору в соответствии
                            с действующим законодательством РФ.<br/>
                            8.2. Все споры и разногласия, связанные с реализацией положений
                            настоящего Договора, будут решаться Сторонами путем проведения
                            переговоров.<br/>
                            8.3. В случае невозможности урегулирования спора во внесудебном порядке,
                            он будет передан на рассмотрение в суд по месту нахождения
                            Исполнителя.<br/>
                            8.4. Исполнитель ни при каких обстоятельствах не несет никакой
                            ответственности за последствия каких-либо неисправностей, как
                            программных (т.е. программного обеспечения, используемого
                            Заказчиком), так и аппаратных, того технического оборудования,
                            персональных вычислительных средств, которые использует Заказчик в
                            процессе потребления услуг Исполнителя
                            <h4>9. ОТВЕТСТВЕННОСТЬ СТОРОН И РАЗРЕШЕНИЕ СПОРОВ</h4>
                            9.1. Факт заключения настоящего Договора не рассматривается Сторонами
                            как конфиденциальная информация.<br/>
                            9.2. Стороны обязуются не разглашать информацию, полученную в ходе
                            выполнения своих обязательств по настоящему Договору, за
                            исключением случаев, когда Сторона обязана предоставить такую
                            информацию в соответствии с действующим законодательством РФ,
                            применимым к договору, или было получено согласие на разглашение
                            такой информации.<br/>
                            9.3. Любая информация, касающаяся взаимоотношений Сторон, прямо ими
                            по взаимному согласию не определенная как не охраняемая режимом
                            коммерческой тайны и (или) не подлежащая раскрытию согласно
                            требованиям законодательства РФ, признается Сторонами
                            коммерческой тайной и раскрытию не подлежит. Любая информация, не
                            являющаяся персональными данными Заказчика, является
                            исключительной собственностью Заказчика, если она не является
                            исключительной собственностью иных лиц. Сгенерированные
                            вычислительными средствами Исполнителя числовые, буквенные и (или)
                            буквенно-числовые последовательности являются собственностью
                            Исполнителя и не являются персональными данными Заказчика.<br/>
                            9.4. Обработка персональных данных Заказчика осуществляется
                            Исполнителем только в смысле п. 5 ч. 1 ст. 6 Федерального закона от
                            27.07.2006 г. No 152-ФЗ &quot;О персональных данных&quot; (далее – &quot;Закон &quot;О
                            персональных данных&quot;), исключительно этим ограничивается и чье-либо
                            согласие в этой связи согласно этому закону не требуется. Никакие
                            персональные данные ни в какой момент времени не передаются в
                            иностранные государства, не являющихся сторонами Конвенции Совета
                            Европы о защите физических лиц при автоматизированной обработке
                            персональных данных и обеспечивающих адекватную защиту прав
                            субъектов персональных данных, но на всякий случай, например но не
                            исключительно на случай маршрутизации сетевого трафика или иные
                            ситуации прохождения трафика, содержащего персональные данные в
                            иных государствах, своим акцептом Заказчик предоставляет свое
                            согласие по п. 1 ч. 4 ст. 12 Закона &quot;О персональных данных&quot;. Сами по
                            себе персональные данные Исполнителем не хранятся, не
                            обрабатываются, а уничтожаются сразу после их использования
                            Заказчиком по п. 5 ч. 1 ст. 6 Закона &quot;О персональных данных&quot; при
                            акцепте Оферты. Параметры и данные, позволяющие Заказчику
                            использовать ресурсы Исполнителя при оказании услуг, хранятся и
                            обрабатываются в персональных вычислительных средствах Заказчика,
                            среди прочего непосредственно в браузере Заказчика и ином
                            программном обеспечении, посредством которых Заказчик потребляет
                            услуги Исполнителя.<br/>
                            9.5. Заказчик настоящим гарантирует что любой контент, размещенный
                            (закачанный) Заказчиком на странице Заказчика или интернет-ресурсе
                            Исполнителя, соответствует требованиям патентной (правовой) чистоты,
                            что включает в себя гарантии того, что все используемое им
                            программное и (или) обеспечение используется им абсолютно легально,
                            что весь контент, будь то графический, текстовой или какой-либо иной,
                            приобретен Заказчиком законно или создан самостоятельно. Весь этот
                            контент, соответствующий указанным требованиям и принципам, в
                            момент окончания размещения в интернет-ресурсе Исполнителя
                            поступает в распоряжение Исполнителя, на него распространяется
                            режим коммерческой тайны Исполнителя, и Заказчик не вправе в этой
                            связи предъявлять какие-либо претензии Исполнителю.
                            <h4>10. ФОРС-МАЖОР</h4>
                            10.1. Стороны освобождаются от ответственности за полное или частичное
                            неисполнение обязательств по настоящему договору в случае, если
                            неисполнение обязательств явилось следствием действий
                            непреодолимой силы, а именно: действия органов государственной
                            власти (в т.ч. принятие правовых актов), пожара, наводнения,
                            землетрясения, отсутствие электроэнергии и/или сбои работы
                            компьютерной сети, забастовки, войны или любых других независящих
                            от сторон обязательств.<br/>
                            10.2. Сторона, которая не может выполнить обязательства по настоящему
                            договору, должна своевременно, но не позднее 10 (десяти) календарных
                            дней после наступления обстоятельств непреодолимой силы,
                            посредством электронной почты известить другую сторону о наличии
                            таких обстоятельств.<br/>
                            10.3. Стороны признают, что неплатежеспособность сторон не является форс
                            мажорным обстоятельством.
                            <h4>11. ПРОЧИЕ УСЛОВИЯ ДОГОВОРА</h4>
                            11.1. Исполнитель не гарантирует, что работа Сайта будет непрерывной и
                            безошибочной на любых клиентских устройствах, в любых клиентских
                            интернет браузерах, в любой точке планеты Земля.
                            <h4>12. СРОК ДЕЙСТВИЯ, ИЗМЕНЕНИЕ, РАСТОРЖЕНИЕ ДОГОВОРА</h4>
                            12.1. Настоящий договор признается заключенным в г. Мурманск.<br/>
                            12.2. Настоящий договор вступает в силу с момента его заключения и
                            действует до исполнения его сторонами своих обязательств друг перед
                            другом.<br/>
                            12.3. Датой заключения договора, равно как и датой акцепта является дата
                            события согласно п.1.2.<br/>
                            12.4. Настоящая оферта может быть отозвана Исполнителем в любой момент
                            до ее акцепта Заказчиком.<br/>
                            12.5. Настоящий договор может быть расторгнут по соглашению Сторон.<br/>
                            12.6. Условия договора могут быть изменены Исполнителем. В случае
                            внесения изменений в публичную оферту и (или) ценовые предложения
                            такие изменения вступают в силу с момента размещения изменений,
                            если иной срок вступления изменений в силу не определен
                            дополнительно.<br/>
                            12.7. По вопросам оплаты, качества, своевременности получения услуг,
                            другим вопросам в отношении исполнения настоящего договора или в
                            связи с ним Заказчик может обратиться к Исполнителю по электронной
                            почте <a href={'mailto:north.memory.rf@gmail.com'}>north.memory.rf@gmail.com</a> или отправить сообщение на
                            разделе сайта &quot;Обартная связь&quot; (<a href='https://на-севере-память.рф/feedback'>на-севере-память.рф</a>).
                            <h4>Исполнитель</h4>
                            ООО &quot;СЕВЕРСНАБ ГРУПП&quot;
                            <ul>
                                  <li>Адрес: 184511, РОССИЯ, МУРМАНСКАЯ ОБЛАСТЬ, Г МОНЧЕГОРСК, УЛ КУМУЖИНСКАЯ, Д. 7, КВ. 28</li>
                                  <li>ОГРН: 1245100004214</li>
                                  <li>ИНН: 5108005388</li>
                            </ul>
                            Реквизиты:
                            <ul>
                                  <li>Р/с 40702810910001665831 в АО &quot;ТБанк&quot; г. Москва</li>
                                  <li>К/с 30101810145250000974</li>
                                  <li>БИК 044525974</li>
                            </ul>
                            Генеральный директор: Громов А.А.
                      </Box>
                </Container>
                <ActionSection style={{marginTop:'40px'}}>
                      <ActionTitle>ПОДЕЛИТЕСЬ МОМЕНТОМ ПАМЯТИ БЛИЗКОГО ЧЕЛОВЕКА</ActionTitle>
                      <ActionText>С нашими табличками памяти</ActionText>
                      <ActionBtn onClick={(e) => {
                            if (!userSession.getSession()) {
                                  dispatch(setLoginOpen(true))
                            } else {
                                  navigate('/order')
                            }
                      }} variant="contained">Заполнить анкету</ActionBtn>
                </ActionSection>
          </>
      );
}

export default OffertaPage;