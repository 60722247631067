import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/free-mode';

import './styles.css';

// import required modules
import { Scrollbar, FreeMode, Autoplay } from 'swiper/modules';
import {Link} from "react-router-dom";

export type Hero = {
    id: string;
    name: string;
    description: string;
    img: string;
    fullText: string;
}

type Props = {
    heroes: Hero[];
}

export default function HeroesSlider ({heroes} : Props) {
    return (
        <>
            <Swiper
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                        freeMode: false,
                    },
                    729: {
                        slidesPerView: 2,
                        freeMode: true,
                    },
                    1024: {
                        slidesPerView: 3,
                        freeMode: true,
                    },
                    1280: {
                        slidesPerView: 5,
                        freeMode: true,
                    }
                }}
                spaceBetween={0}
                freeMode={true}
                autoplay={{
                    delay: 4500,
                }}
                scrollbar={{ draggable: true }}
                modules={[Scrollbar, FreeMode, Autoplay]}
                className="heroesSwiper"
            >
                {
                    heroes.map((hero, key) => {
                        return (
                            <SwiperSlide key={hero.name+'heroesSlider'+key}>
                                <img src={hero.img} alt={hero.name} />
                                <div className="hero-description">
                                    <p className="hero-name">{hero.name}</p>
                                </div>
                                <div className="hero-full-container">
                                    <div className="hero-full-name">
                                        {hero.name}
                                    </div>
                                    <div className="hero-full-short">
                                        {hero.description}
                                    </div>
                                    <Link to={`/solder/${hero.id}`} className="hero-full-btn">Посмотреть его историю</Link>
                                </div>
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
        </>
    );
}