    import React, {useEffect, useState} from 'react';
import { Box, Container } from '@mui/material';
import { useNavigate, useSearchParams} from 'react-router-dom';
import Button from '@mui/material/Button';
import { styled } from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
    import axios from "axios";
    import {MainTitle} from "./styles";
    import {API_HOST, userSession} from "../../services/auth/ApiService/client";
    import {ActionBtn, ActionSection, ActionText, ActionTitle} from "../HomePage/styles";
    import {setLoginOpen} from "../../store/appSlice";
    import {useDispatch} from "react-redux";

interface IModalContent {
  title: string;
  content: string;
  imageSet: Array<{ title: string, img: string }>;
}

const Dot = styled('span')`
    display: inline-block;
    border-radius: 50%;
    background: rgba(53,61,81,.65);
    width: 12px;
    height: 12px;
`;

const Price = styled(`span`)`
    font-weight: 800;
    display: block;
    text-align: center;
    color: #292929;
    font-size: 21px;
    margin-bottom: 10px;
`;

interface ITariff {
    id: string;
    name: string;
    price: number;
    allowFields: {
        qr: true,
        bio: false,
        map: true,
        media: false,
        gallery: false,
        epitafia: true,
        tree: true,
        bg: true,
        video: true,
    }
}

const PricesPage = () => {
    const navigate = useNavigate();
    const [tariff, setTariff] = useState([]);
    const [params] = useSearchParams()
    const dispatch = useDispatch()
    const r = params.get('r')

    useEffect(() => {
        const loadData = () => {

        axios
            .get(`${API_HOST}/tariff`)
            .then((response) => {
                console.log(response)
                const { data = [] } = response
                setTariff(data)
            })
            .catch((error) => {
                console.log('error')
                console.log(error)
            })
        }
        loadData()
    }, []);

  return (
    <>
        <Container style={{marginTop: '120px', paddingLeft: '0px', paddingRight: '0px',}}>
          <MainTitle variant="h4" sx={{marginBottom: '40px'}}>Тарифы для создания Момента Памяти</MainTitle>
            <TableContainer component={Paper} sx={{ maxWidth: 700 }}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{color: '#00000085'}}>Опции</TableCell>
                            {tariff && tariff.length > 0 && tariff.map((item: ITariff) => (
                                <TableCell key={`${item.id}-n`} align="center">{item.name}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                Эпитафия
                            </TableCell>
                            {tariff && tariff.length > 0 && tariff.map((item: ITariff) => (
                                <TableCell key={`${item.id}-e`} align="center">{item.allowFields.epitafia ? <Dot /> : ''}</TableCell>
                            ))}
                        </TableRow>

                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                Фон в шапке
                            </TableCell>
                            {tariff && tariff.length > 0 && tariff.map((item: ITariff) => (
                                <TableCell key={`${item.id}-e`} align="center">{item.allowFields.bg ? <Dot /> : ''}</TableCell>
                            ))}
                        </TableRow>

                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                Отметка на карте
                            </TableCell>
                            {tariff && tariff.length > 0 && tariff.map((item: ITariff) => (
                                <TableCell key={`${item.id}-m`} align="center">{item.allowFields.map ? <Dot /> : ''}</TableCell>
                            ))}
                        </TableRow>

                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                Родственные связи
                            </TableCell>
                            {tariff && tariff.length > 0 && tariff.map((item: ITariff) => (
                                <TableCell key={`${item.id}-r`} align="center">{item.allowFields.tree ? <Dot /> : ''}</TableCell>
                            ))}
                        </TableRow>

                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                QR-код для печати
                            </TableCell>
                            {tariff && tariff.length > 0 && tariff.map((item: ITariff) => (
                                <TableCell key={`${item.id}-q`} align="center">{item.allowFields.qr ? <Dot /> : ''}</TableCell>
                            ))}
                        </TableRow>

                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                Биография с фото
                            </TableCell>
                            {tariff && tariff.length > 0 && tariff.map((item: ITariff) => (
                                <TableCell key={`${item.id}-b`} align="center">{item.allowFields.bio ? <Dot /> : ''}</TableCell>
                            ))}
                        </TableRow>

                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                Фотогалерея
                            </TableCell>
                            {tariff && tariff.length > 0 && tariff.map((item: ITariff) => (
                                <TableCell key={`${item.id}-g`} align="center">{item.allowFields.gallery ? <Dot /> : ''}</TableCell>
                            ))}
                        </TableRow>

                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                Видео и аудиозаписи из соц. сетей
                            </TableCell>
                            {tariff && tariff.length > 0 && tariff.map((item: ITariff) => (
                                <TableCell key={`${item.id}-m`} align="center">{item.allowFields.media ? <Dot /> : ''}</TableCell>
                            ))}
                        </TableRow>
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                Видео файлы
                            </TableCell>
                            {tariff && tariff.length > 0 && tariff.map((item: ITariff) => (
                                <TableCell key={`${item.id}-m`} align="center">{item.allowFields.video ? <Dot /> : ''}</TableCell>
                            ))}
                        </TableRow>
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row"></TableCell>
                            {tariff && tariff.length > 0 && tariff.map((item: ITariff) => (
                                <TableCell align="center" key={`${item.id}-price`}>
                                    <Price>{item.price ? `${item.price} руб.` : `Бесплатно`}</Price>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
        <ActionSection style={{marginTop:'40px'}}>
            <ActionTitle>ПОДЕЛИТЕСЬ МОМЕНТОМ ПАМЯТИ БЛИЗКОГО ЧЕЛОВЕКА</ActionTitle>
            <ActionText>С нашими табличками памяти</ActionText>
            <ActionBtn onClick={(e) => {
                if (!userSession.getSession()) {
                    dispatch(setLoginOpen(true))
                } else {
                    navigate('/order')
                }
            }} variant="contained">Заполнить анкету</ActionBtn>
        </ActionSection>
    </>
  );
}

export default PricesPage;