import styled from "@emotion/styled/macro";
import { Box, Button, Typography } from "@mui/material";
import mainImage from '../../assets/img/bg0.png';
import bgSnow from '../../assets/img/bg-snow.png';
import ship from '../../assets/img/ship1.png';
import solder1 from '../../assets/img/solder1.png';
import solder2 from '../../assets/img/solder2.png';
import solder3 from '../../assets/img/solder3.png';
import bga from '../../assets/img/north5/bgf.png';
import solder4 from '../../assets/img/solder4.png';
import searchBgImage from '../../assets/img/search-bg.png';
import treeImage from '../../assets/img/tree.png';
import iconBg from '../../assets/img/iconbg.png';
import { Link } from "react-router-dom";
import FormControl from "@mui/material/FormControl";

export const MainSection = styled(Box)`
  background-color: rgba(12,12,12,0.75);
  width: 100%;
  color: #fff;
  position: relative;
  :after{
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-image: url(${mainImage});
    background-size: cover;
    background-position: center 90%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;  
  }
`;
export const MainSectionContent = styled(Box)`
  max-width: 345px;
  max-height: 600px;
    padding-top: 40px;
  height: 100vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-flow: column;
  align-items: baseline;
  justify-content: center;
    @media (min-width: 1930px) {
        min-height: 800px;
    }
  @media (max-width: 1024px) {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    max-width: 100%;  
    max-height: 100%;
    justify-content: center;
    align-items: center;
  }
`;
export const MainTitle = styled(Box)`
  display: flex;
    justify-content: center;
    align-items: center;
  img {
    width: 130px;
    height: auto;
  }
  @media (max-width: 1024px) {
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

  span {
    font-size: 36px;
    display: block;
    line-height: 1.2;
    margin-left: 15px;
      border-bottom: #fff 1px solid;
    @media (max-width: 1024px) {
      max-width: 170px;
    }
  }
`;
export const MainDescription = styled(Box)`
  font-size: 18px;
  margin-top: 10px;
  @media (max-width: 1024px) {
    margin-top: 15px;
    text-align: center;
  }
  span {
    font-size: 20px;
    @media (max-width: 1024px) {
      max-width: 170px;
    }
  }
`;
export const MainDescriptionSmall = styled(Box)`
  font-size: 16px;
  margin-top: 15px;
`;
export const MainBtn = styled(Button)`
  margin-top: 20px;
  color: #FFF;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 3px;
  padding: 0px;
  padding-bottom: 5px;
  border-bottom: 1px solid #fff;
  border-radius: 0px;
`;

export const SearchSection = styled(Box)`
    margin-top: 0px;
    padding-bottom: 60px;
    position: relative;
    background: url(${solder1}), url(${solder2}), url(${ship}), url(${bgSnow});
    background-position: calc(100% - 100px) 20px,10% 220px,calc(100% - 20px) 200px,0px 0px;
    background-repeat: no-repeat, no-repeat, no-repeat, repeat;
`;
export const SearchTextContainer = styled(Box)`
    max-width: 550px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
export const SearchHeader = styled('h4')`
    margin: 0px;
    padding-top: 40px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    color: #244D76;
    font-size: 21px;
    line-height: 1.5;
    position: relative;
    &:after {
        content: ' ';
        display: block;
        position: absolute;
        left: calc(50% - 50px);
        bottom: -10px;
        width: 100px;
        height: 2px;
        background: #2196F3;
    }
    & span {
        font-weight: 900;
    }
`;
export const SearchSolderForm = styled(FormControl)`
    flex-direction: row;
    margin-top: 30px;
    width: 100%;
    @media (max-width: 720px) {
        padding-left: 15px;
        padding-right: 15px;
    }
    & button {
        color: rgba(0, 0, 0, 0.3);
        border-color: rgba(0, 0, 0, 0.3);
        margin-left: 1px;
        & span {
            margin: 0px;
            & svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
                font-size: 26px;
            }
        }
    }
`;
export const SoldersContainer = styled(Box)`
    margin-top: 40px;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    margin-left: -7px;
    margin-right: -7px;
    @media (max-width: 720px) {
        flex-flow: column;
        align-items: center;
        margin-left: 0;
        margin-right: 0;
    }
`;
export const SolderContainer = styled(Box)`
    height: 480px;
    width: 280px;
    position: relative;
    margin: 7px;
`;
export const SolderFront = styled(Box)`
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2px;
    padding-bottom: 5px;
`;
export const SolderImg = styled('img')`
    height: 400px;
    width: 100%;
    object-fit: cover;
`;
export const SolderTextContainer = styled(Box)`
    padding: 10px;
`;
export const SolderTextName = styled(Typography)`
    font-size: 18px;
    color: #244D76;
    font-weight: 700;
    height: 55px;
    text-transform: uppercase;
`;
export const SolderTextDescription = styled(Typography)`
    font-size: 12px;
    color: #244D76;
`;
export const SolderBack = styled(Box)`
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url(${solder3}) bottom right / 75% no-repeat, #fff;
    padding: 10px;
    transition: 200ms linear;
    ${SolderContainer}:hover & {
        opacity: 1;
    }
    &:after {
        border: 1px solid rgba(0, 0, 0, 0.1);
        position: absolute;
        top: 7px;
        right: 7px;
        bottom: 7px;
        left: 7px;
        content: ' ';
        z-index: 0;
    }
`;
export const SolderBackText = styled(Box)`
    z-index: 10;
    position: relative;
    padding: 10px;
    text-align: justify;
    text-align-last: center;
    font-size: 16px;
    line-height: 1.4;
    height: calc(100% - 50px);
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const SolderButton = styled(Link)`
    display: block;
    position: relative;
    text-decoration: none;
    margin: 0 auto;
    padding: 5px;
    text-align: center;
    color: #244D76;
    text-transform: uppercase;
    font-weight: 500;
    z-index: 100;
    font-size: 14px;
`;

export const SoldersShowMore = styled(Button)`
    margin-top: 20px;
    padding: 5px;
    &:after {
        content: ' ';
        height: 2px;
        width: 20px;
        position: absolute;
        left: calc(50% - 10px);
        background: #fff;
        bottom: 0px;
        transition: all 200ms ease-in;
    }
    &:hover:after {
        width: 100px;
        left: calc(50% - 50px);
    }
`;

export const HonorSection = styled(Box)`
    background: #FAFAFA;
    padding-bottom: 40px;
`;
export const HonorImages = styled(Box)`
    position: relative;
    margin: 0 auto;
    margin-top: 30px;
    max-width: 1060px;
    height: 709px;
    @media (max-width: 720px) {
        height: auto;
    }
`;
export const HonorTitle = styled('h4')`
    color: #244D76;
    font-weight: 500;
    font-size: 21px;
    position: relative;
    text-align: center;
    padding-bottom: 10px;
    &:after {
        content: ' ';
        position: absolute;
        bottom: 0;
        right: calc(50% - 50px);
        width: 100px;
        height: 2px;
        background: #2196F3;
    }
`;
export const HonorText = styled(Box)`
    padding-top: 120px;
    color: #244D76;
    font-size: 20px;
    line-height: 1.5;
    letter-spacing: 0.15em;
    max-width: 520px;
    width: 100%;
    position: relative;
    padding-bottom: 10px;
    &:after {
        content: ' ';
        position: absolute;
        bottom: 0;
        left: 0px;
        width: 100px;
        height: 2px;
        background: #2196F3;
    }
`;
export const HonorImage = styled('img')`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
    @media (max-width: 720px) {
        height: auto;
        position: relative;
        width: auto;
        margin: 15px auto;
        display: block;
    }
`;
export const HonorImage1 = styled(HonorImage)`
    right: 350px;
    top: 30px;
    left: auto;
    @media (max-width: 720px) {
        right: 0;
        top: 0;
        display: none;
    }
`;
export const HonorImage2 = styled(HonorImage)`
    right: 162px;
    top: 30px;
    left: auto;
    @media (max-width: 720px) {
        right: 0;
        top: 0;
        display: none;
    }
`;
export const HonorImage3 = styled(HonorImage)`
    right: 629px;
    top: 323px;
    left: auto;
    @media (max-width: 720px) {
        right: 0;
        top: 0;
        display: none;
    }
`;
export const HonorImage4 = styled(HonorImage)`
    right: 347px;
    top: 323px;
    left: auto;
    @media (max-width: 720px) {
        right: 0;
        top: 0;
    }
`;
export const HonorImage5 = styled(HonorImage)`
    right: 49px;
    top: 323px;
    left: auto;
    @media (max-width: 720px) {
        right: 0;
        top: 0;
        display: none;
    }
`;

export const FamilySection = styled(Box)`
    background-image: url(${bgSnow});
    padding-top: 40px;
    padding-bottom: 40px;
`;
export const FamilyTitle = styled('h4')`
    color: #244D76;
    font-weight: 500;
    font-size: 21px;
    position: relative;
    text-align: center;
    margin: 0;
    padding-bottom: 10px;
    &:after {
        content: ' ';
        position: absolute;
        bottom: 0;
        right: calc(50% - 50px);
        width: 100px;
        height: 2px;
        background: #2196F3;
    }
`;
export const FamilyImages = styled(Box)`
    position: relative;
    margin: 0 auto;
    margin-top: 30px;
    max-width: 808px;
    height: 650px;
    @media (max-width: 720px) {
        height: auto;
    }
`;
export const FamilyBlock = styled(Box)`
position: absolute;
    left: 0;
    top: 0;
    @media (max-width: 720px) {
        width: auto;
        position: relative;
        padding: 10px;
        & img {
            width: 100%;
        }
    }
`;
export const FamilyBlock1 = styled(FamilyBlock)`
`;
export const FamilyBlock2 = styled(FamilyBlock)`
    top: 115px;
    right: 0;
    left: auto;
    @media (max-width: 720px) {
        top: 0;
    }
`;
export const FamilyBlock3 = styled(FamilyBlock)`
    top: 351px;
    left: 84px;
    @media (max-width: 720px) {
        left: 0;
        top: 0;
    }
`;
export const FamilyText = styled(Box)`
    margin-top: 18px;
    font-weight: 500;
    letter-spacing: 0.11em;
    font-size: 20px;
    color: #244D76;
    position: relative;
    padding-bottom: 10px;
    &:after {
        content: ' ';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100px;
        height: 2px;
        background: #2196F3;
    }
`;
export const FamilyImage = styled('img')`
    
`;
export const FamilyImage1 = styled(FamilyImage)``;
export const FamilyImage2 = styled(FamilyImage)``;
export const FamilyImage3 = styled(FamilyImage)``;

export const StepsSection = styled(Box)`
    background: #FAFAFA;
    padding-top: 80px;
    padding-bottom: 80px;
`;
export const StepsTitle = styled('h4')`
    color: #244D76;
    font-weight: 500;
    font-size: 21px;
    position: relative;
    text-align: center;
    margin: 0;
    padding-bottom: 10px;
    &:after {
        content: ' ';
        position: absolute;
        bottom: 0;
        right: calc(50% - 50px);
        width: 100px;
        height: 2px;
        background: #2196F3;
    }
`;
export const StepsContainer = styled(Box)`
    margin: 0 auto;
    margin-top: 40px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @media (max-width: 720px) {
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    &>div:nth-of-type(1):after {
        content: ' ';
        position: absolute;
        z-index: 2;
        left:100%;
        top: 85px;
        width: 150px;
        border-top: 1px dashed #244D76;
        @media (max-width: 720px) {
            display: none;
        }
    }
    &>div:nth-of-type(2):after {
        content: ' ';
        position: absolute;
        z-index: 2;
        left:100%;
        top: 107px;
        width: 150px;
        border-top: 1px dashed #244D76;
        @media (max-width: 720px) {
            display: none;
        }
    }
`;
export const StepsBlock = styled(Box)`
    position: relative;
    z-index: 10;
    @media (max-width: 720px) {
        margin-top: 10px;
        margin-bottom: 10px;
        width: 100%;
    }
`;
export const StepsImage = styled('img')`
    @media (max-width: 720px) {
        width: 100%;
    }
`;
export const StepsText = styled(Box)`
    color: #244D76;
    font-weight: 400;
    font-size: 20px;
    position: relative;
    text-align: left;
    margin: 0;
    padding-bottom: 10px;
    &:after {
        content: ' ';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100px;
        height: 2px;
        background: #2196F3;
    }
`;

export const ActionSection = styled(Box)`
  max-width: 100%;
  background-color: hsla(210, 53%, 30%, 0.95);
  margin: 0 auto;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  @media (max-width: 720px) {
    text-align: center;
  }
  :after{
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-image: url(${bga});
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;  
  }
`;
export const ActionTitle = styled(Typography)`
  color: #FFF;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
export const ActionText = styled(Box)`
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 15px;
  text-transform: uppercase;
`;
export const ActionBtn = styled(Button)`
  margin-top: 25px;
`;