import styled from "@emotion/styled/macro";

import {Box, Typography, Container, Paper, Divider, Link, IconButton} from '@mui/material';

import secondImage from '../../assets/img/trees/t6.png';
import bgCity from '../../assets/img/bg-city.png';
import quotes1 from '../../assets/img/quotes1.png';

export const BackgroundBlock = styled(Box)`
  background-image: url(${bgCity});
  background-size: cover;
  background-position-y: 0px;
  margin-bottom: -150px;
  height: 350px;
  width: 100%;
    position: relative;
    z-index: -1;
    :after{
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background: rgba(12,12,12,0.75);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }
`;
export const BackgroundContainer = styled(Container)`
  background-color: #fff;
  border: 1px solid rgba(192,201,218,.31);
  box-shadow: 0 20px 20px hsla(0,0%,74.5%,.16);
  padding-top: 10px;
  border-radius: 8px;
  padding-bottom: 20px;
`;

export const FTBox = styled(Box)`
    background: #fff;
    color: #244D76;
    text-align: center;
    //width: 172px;
    //padding: 5px;
    //border-radius: 5px;
    border-radius: 0px;
    box-shadow: 0 20px 20px hsla(0,0%,74.5%,.16);
    position: absolute;
    border: 1px solid #e4e4e4;
       img {
           width: 70px;
           height: 70px;
           object-fit: cover;
           border-radius: inherit;
           transition: all 0.2s ease;
           @media (max-width: 720px) {
               width: 40px;
               height: 40px;
           }
       }
        span {
              font-size: 10px;
         }
        h6:first-of-type {
            font-size: 14px;
        }
        h6:nth-of-type(2) {
            font-size: 12px;
        }
`;
export const FThiddenBox = styled(Box)`
    position: absolute;
    display: block;
    background: #fff;
    border-left: 1px solid #e4e4e4;
    border-right: 1px solid #e4e4e4;
    border-bottom: 1px solid #e4e4e4;
    padding: 5px;
    opacity: 0;
    height: 0px;
    overflow: hidden;
    transition: all 0.2s ease-out;
    z-index: -1;
    width: 189px;
`;
export const FT = styled(FTBox)`
    transition: all 0.5s ease;
    z-index: 1;
    &:hover {
        z-index: 20;
        @media (max-width: 720px) {
            left: calc(50% - 100px);
            bottom: 150px;
        }
    }
    &:hover ${FThiddenBox} {
        opacity: 1;
        height: 120px;
        z-index: 1;
        transition: all 0.5s ease-in;
        width: 200px;
        @media (max-width: 720px) {
            height: 140px;
        }
    }
    &:hover img {
        height: 200px;
        width: 200px;
    }
`;
export const FamilyTreeContainer = styled(Container)`
  background-color: #fff;
  background-image: url(${secondImage});
    width: 100%;
    height: 900px;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: contain;
    box-shadow: none;
    position: relative;
    @media (max-width: 720px) {
        height: 400px;
        overflow: hidden;
    }
`;

export const SocialLinksContainer = styled(Box)`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
` as typeof Box;
export const SocialLink = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: baseline;
    width: fit-content;
    margin: 5px;
    & svg {
        margin-right: 5px;
    }
` as typeof Link;

export const FT1 = styled(FTBox)`
    bottom: 200px;
    left: calc(50% - 170px);
`;
export const FT2 = styled(FTBox)`
    left: calc(50% - 90px);
    bottom: 70px;
`;
export const FT3 = styled(FTBox)`
    left: calc(50% + 0px);
    bottom: 170px;
`;
export const FT4 = styled(FTBox)`
    bottom: 200px;
    left: calc(50% - -80px);
`;
export const FT5 = styled(FTBox)`
    bottom: 300px;
    left: calc(50% - 230px);
`;
export const FT6 = styled(FTBox)`
    bottom: 310px;
    left: calc(50% - 140px);
`;
export const FT7 = styled(FTBox)`
    bottom: 290px;
    left: calc(50% - 0px);
`;
export const FT8 = styled(FTBox)`
    bottom: 300px;
    left: calc(50% - -100px);
`;
export const FT9 = styled(FTBox)`
    bottom: 280px;
    left: calc(50% - -190px);
`;
export const FT10 = styled(FTBox)`
    bottom: 373px;
    left: calc(50% - -228px);
`;
export const FT11 = styled(FTBox)`
    bottom: 403px;
    left: calc(50% - 242px);
`;
export const FT12 = styled(FTBox)`
    bottom: 423px;
    left: calc(50% - 152px);
`;
export const FT13 = styled(FTBox)`
    bottom: 393px;
    left: calc(50% - 72px);
`;
export const FT14 = styled(FTBox)`
    bottom: 403px;
    left: calc(50% - -18px);
`;
export const FT15 = styled(FTBox)`
    bottom: 393px;
    left: calc(50% - -126px);
`;
export const FT16 = styled(FTBox)`
    bottom: 523px;
    left: calc(50% - 209px);
`;
export const FT17 = styled(FTBox)`
    bottom: 553px;
    left: calc(50% - 119px);
`;
export const FT18 = styled(FTBox)`
    bottom: 518px;
    left: calc(50% - -3px);
`;
export const FT19 = styled(FTBox)`
    bottom: 485px;
    left: calc(50% - -83px);
`;
export const FT20 = styled(FTBox)`
    bottom: 410px;
    left: calc(50% - -80px);
`;
export const FT21 = styled(FTBox)`
    bottom: 410px;
    left: calc(50% - -80px);
`;
export const FT22 = styled(FTBox)`
    bottom: 410px;
    left: calc(50% - -80px);
`;
export const FT23 = styled(FTBox)`
    bottom: 410px;
    left: calc(50% - -80px);
`;
export const FT24 = styled(FTBox)`
    bottom: 410px;
    left: calc(50% - -80px);
`;

export const FTNumberBox = styled(Box)`
    width: 70px;
    height: 70px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #244D76;
    font-weight: 500;
    font-size: 28px;
    letter-spacing: 0.00735em;
`;

export const PhotoContainer = styled(FTBox)`
  display: block;
  border-radius: 50%;
  position: relative;
  width: 260px;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  border: 5px solid #fff;
  box-shadow: 0 0 24px 0 rgba(75,93,127,.14);
  margin-top: -130px;
      img {
        width: 100%;
        height: auto;
        display: block;
      }
`;
export const HumanName = styled(Typography)`
  text-align: center;
  margin-top: 15px;
  color: #244D76;
  font-weight: 500;
  font-size: 28px;
`;
export const NameDivider = styled(Divider)`
  width: 350px;
  margin: 0 auto;
  margin-top: 7px;
  border-color: rgba(0, 0, 0, 0.17);
`;
export const HumanDate = styled(Typography)`
  text-align: center;
  margin-top: 15px;
  font-size: 18px;
  font-weight: 600;
  color: #18334e;
  letter-spacing: 0.015em;
`;
export const HumanYears = styled(Typography)`
  text-align: center;
`;
export const CitateBox = styled(Box)`
  background-color: #f8f9fc;
  border-radius: 8px;
  padding: 20px;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 600;
  color: #18334e;
  margin-left: -40px;
  margin-right: -40px;
  position: relative;
  z-index: 1;
  @media (max-width: 1024px) {
    margin-left: 0px;
    margin-right: 0px;
  }
  @media (max-width: 720px) {
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 30px;
    padding-bottom: 30px;
      p {
        font-size: 1rem;
      }
  }
  :after{
    content: '';
    display: block;
    width: 100%;
    height: 100%;
      background-image: url(${quotes1});
    background-size: cover;
    background-position-y: -50px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.08;
    z-index: -1;  
  }
`;
export const SubCitateBox = styled(Box)`
  max-width: 650px;
  margin: 0 auto;
  text-align: justify;
  text-align-last: center;
      p {
        font-size: 18px;
      }
      h6 {
        margin-top: 20px;
      }
`;

export const DetailsContainer = styled(Box)`
  position: relative;
  margin-top: 60px;
  @media (max-width: 720px) {
    flex-direction: column;
  }
`;
export const DetailsBox = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media (max-width: 720px) {
    flex-direction: column;
  }
`;
export const DetailsRow = styled(Box)`
  border-bottom: 1px solid #d2d5d9;
  margin-bottom: 20px;
  padding-bottom: 20px;
  display: flex;
  width: 50%;
  @media (max-width: 720px) {
    width: 100%;
  }
    span:first-of-type{
      font-weight: 500;
      width: 185px;
      display: inline-block;
      font-size: 14px;
      @media (max-width: 720px) {
        width: 50%;
      }
    }
    span:last-of-type{
      font-weight: 400;
      font-size: 14px;
      @media (max-width: 720px) {
        text-align: right;
        width: 50%;
        display: inline-block;
      }
    }
`;
export const ModalContent = styled(Box)`
  height: 400px;
  overflow-y: auto;
  padding-right: 32px;
`;
export const ModalHeader = styled(Typography)`
  margin-bottom: 10px;
  margin-right: 32px;
`;
export const ModalDivider = styled(Divider)`
  margin-bottom: 10px;
  margin-right: 32px;
`;

export const BioPaper = styled(Paper)`
  padding: 0px;
  box-shadow: none;
  transition: none;
  padding-bottom: 10px;
`;
export const BioTitle = styled(Typography)`
  margin-bottom: 15px;
`;
export const BioBox = styled(Box)`

`;
export const BioLink = styled(Link)`
  margin-bottom: 10px;
  display: block;
  text-decoration: none;
`;

export const Graveyard = styled(Box)`
  margin-bottom: 20px;
`;
export const GraveyardName = styled(Typography)`
  margin-bottom: 10px;
  font-size: 21px;
  letter-spacing: 0.02938em;
`;
export const GraveDivider = styled(Divider)`
  
`;
export const GraveAddress = styled(Typography)`
  margin-top: 10px;
  span {
    font-weight: 500;
    margin-right: 5px;
    margin-top: 15px;
  }
`;

export const QRCode = styled('img')`
  max-width: 250px;
  margin: 0 auto;
  display: block;
`;

export const MainTitle = styled(Typography)`
  color: #244D76;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 26px;
  letter-spacing: 0.06em;
  @media (max-width: 1024px) {
    padding-left: 24px;
    padding-right: 24px;
  }
  @media (max-width: 720px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const QRCodeContainer = styled(Box)`
    position: relative;
    width: fit-content;
    margin: 0 auto;
`;
export const DownLoadButton = styled(IconButton)`
    position: absolute;
    right: 5px;
    top: 5px;
` as typeof IconButton;