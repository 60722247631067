import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import HomePage from './pages/HomePage';
import Header from './components/Header';
import Footer from './components/Footer';
import SinglePage from './pages/SinglePage';
import HeroesPage from './pages/HeroesPage';
import ProfilePage from './pages/ProfilePage';
import OrderPage from './pages/OrderPage';
import ChangePage from './pages/ChangePage';
import CompaniesPage from "./pages/CompaniesPage";
import SolderPage from "./pages/SolderPage";
import {YMaps} from "@pbe/react-yandex-maps";
import CheckoutPage from "./pages/CheckoutPage";
import Feedback from "./pages/FeedbackPage";
import PoliticPage from "./pages/PoliticPage";
import OffertaPage from "./pages/OffertaPage";
import PayReferPage from "./pages/PayReferPage";
import PricesPage from "./pages/PricesPage";
import ScrollToTop from "./scrollToTop";

const App = () => {
  return (
      <YMaps
          query={{
              load: "package.full",
              apikey: '85a1be39-eeb8-4907-a966-dae0a8b25f61',
              suggest_apikey: 'bd3a2736-411d-46c5-9640-7cd52ea50dec'
          }}
          preload={true}
      >
    <Router>
        <Header />
      <main>
          <ScrollToTop />
        <Routes>
          <Route path="/" element={<HomePage />} />
          {/* <Route path="/catalog" element={<PayReferPage />} /> */}
          <Route path="/anketa/:id" element={<SinglePage />} />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/feedback" element={<Feedback />} />
          <Route path="/solder/:id" element={<SolderPage />} />
          <Route path="/aleya-heroev" element={<HeroesPage />} />
          <Route path="/companies" element={<CompaniesPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/order" element={<OrderPage />} />
          <Route path="/create/:tariffid" element={<ChangePage />} />
          <Route path="/change/:id" element={<ChangePage />} />
          <Route path="/processing-of-personal-data" element={<PoliticPage />} />
          <Route path="/oferta" element={<OffertaPage />} />
          <Route path="/prices" element={<PricesPage />} />
          <Route path="/payments-and-repayment" element={<PayReferPage />} />
        </Routes>
      </main>
       <Footer />
    </Router>
      </YMaps>
  );
}

export default App;