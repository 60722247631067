import React from 'react';
import {Box, Container, styled, Typography} from '@mui/material';
import {Link} from "react-router-dom";

export const FooterLink = styled(Link)`
  color: rgba(0, 0, 0, 0.6);
  transition: all 0.2s linear;
  text-decoration: none;
  &:hover{
    color:rgba(0, 0, 0, 0.9);
  }
`;

const Footer = () => {
  return (
    <Box component="footer" sx={{ bgcolor: 'background.paper', py: 6 }}>
      <Container maxWidth="lg">
        <Typography variant="body2" color="text.secondary" align="center">
          {'© '}
          {new Date().getFullYear()}
          {' #НА СЕВЕРЕ - ПАМЯТЬ, Все права защищены.'}
          <br />
          <FooterLink to="/processing-of-personal-data" color="inherit">
            Соглашение об обработке персональных данных
          </FooterLink>
          {' | '}
          <FooterLink to="/payments-and-repayment" color="inherit">
            Условия оплаты и возврата
          </FooterLink>
          {' | '}
          <FooterLink to="/oferta" color="inherit">
            Договор-оферта
          </FooterLink>
          {' | '}
          <FooterLink to="/prices" color="inherit">
            Цены
          </FooterLink>
        </Typography>
      </Container>
    </Box>
  );
}

export default Footer;