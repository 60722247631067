import React, {useState} from 'react';
import {Button, Box, Grid, TextField} from '@mui/material';
import styled from "@emotion/styled/macro";
import {useNavigate} from 'react-router-dom';
import FormControl from "@mui/material/FormControl";
import axios from "axios";
import {API_HOST, userSession} from "../services/auth/ApiService/client";
import {useDispatch} from "react-redux";
import {setLoginOpen} from "../store/appSlice";

const LoginComponent = styled(Box)``;
interface IUserData {
    email: string,
    password: string,
}

const Login = () => {
    const navigate = useNavigate();
    const [userData, setUserData] = useState<IUserData>({
        email: '',
        password: '',
    });
    const dispatch = useDispatch()
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${API_HOST}/login`, {
                email: userData.email,
                password: userData.password,
            })

            const { accessToken, refreshToken, expTime, id } = response.data
            userSession.initSession(accessToken, refreshToken, expTime, id)

            console.log('Login successful', accessToken)
            dispatch(setLoginOpen(false))
            navigate('/profile')
        } catch (error) {
            console.error('Login error', error)
            // Handle errors (e.g., show error message)
        }
    };
    return (
        <LoginComponent>
                <Box>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={5}>
                                <FormControl fullWidth sx={{ m: 1 }}>
                                    <TextField id="outlined-basic3" label="Email" type="email" variant="outlined" onChange={(e) => {setUserData({
                                        ...userData,
                                        email: e.target.value,
                                    })}} value={userData.email}/>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl fullWidth sx={{ m: 1 }}>
                                    <TextField id="outlined-basic3" label="Пароль" type="password" variant="outlined" onChange={(e) => {setUserData({
                                        ...userData,
                                        password: e.target.value,
                                    })}} value={userData.password}/>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Button fullWidth sx={{ m: 1, height: '56px' }} variant="contained" type="submit">Авторизоваться</Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
        </LoginComponent>
    );
}

export default Login;