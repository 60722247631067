import React, {useState} from 'react';
import {AppBar, Toolbar, Typography, Button, Box, Grid, TextField} from '@mui/material';
import styled from "@emotion/styled/macro";
import {Link, useNavigate} from 'react-router-dom';
import imgLogo from '../assets/img/logo-original.png';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from "@mui/material/FormControl";
import axios from "axios";
import {API_HOST, userSession} from "../services/auth/ApiService/client";

const SignUpComponenr = styled(Box)``;
interface IUserData {
    email: string,
    password: string,
    password2: string,
    phone: string,
}

interface Props {
    modalOpen: () => void;
}
const SignUp: React.FC<Props> = ({ modalOpen }) => {
    const navigate = useNavigate();
    const [menuOpened, setMenuOpened] = useState(false);
    const [errors, setErrors] = useState([])
    const [userData, setUserData] = useState<IUserData>({
        email: '',
        password: '',
        password2: '',
        phone: '',
    });
    const handleOpenMenu = () => {
        setMenuOpened(!menuOpened);
    }
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            if (userData.password === userData.password2) {
                const response = await axios.post(`${API_HOST}/register`, {
                    email: userData.email,
                    password: userData.password,
                    phone: userData.phone,
                })

                const { accessToken, refreshToken, expTime, id } = response.data
                userSession.initSession(accessToken, refreshToken, expTime, id)

                modalOpen()
                navigate('/profile')
            } else {
                const newErrors: string[] = [...errors];
                const errText = 'Пароли не совпадают';
                if (newErrors.indexOf(errText) !== -1) {
                    newErrors.push(errText)
                    setErrors(errors)
                }
            }
        } catch (error) {
            console.error('Login error', error)
            // Handle errors (e.g., show error message)
        }
    };
    return (
        <SignUpComponenr>
            <form onSubmit={(e) => handleSubmit(e)}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <FormControl fullWidth sx={{m: 1}}>
                            <TextField id="outlined-basic3" label="Email" type="email" variant="outlined"
                                       onChange={(e) => {
                                           setUserData({
                                               ...userData,
                                               email: e.target.value,
                                           })
                                       }} value={userData.email}/>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <FormControl fullWidth sx={{m: 1}}>
                            <TextField id="outlined-basic3" label="Номер телефона" variant="outlined" onChange={(e) => {
                                setUserData({
                                    ...userData,
                                    phone: e.target.value,
                                })
                            }} value={userData.phone}/>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <FormControl fullWidth sx={{m: 1}}>
                            <TextField id="outlined-basic3" label="Пароль" type="password" variant="outlined"
                                       onChange={(e) => {
                                           setUserData({
                                               ...userData,
                                               password: e.target.value,
                                           })
                                       }} value={userData.password}/>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <FormControl fullWidth sx={{m: 1}}>
                            <TextField id="outlined-basic3" label="Повторите пароль" type="password" variant="outlined"
                                       onChange={(e) => {
                                           setUserData({
                                               ...userData,
                                               password2: e.target.value,
                                           })
                                       }} value={userData.password2}/>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12} sx={{m: '0 auto'}}>
                        <Button variant="contained" type="submit" sx={{ m: 1, height: '56px' }}>Зарегистрироваться</Button>
                    </Grid>
            </Grid>
        </form>
</SignUpComponenr>
    );
}

export default SignUp;