import React from 'react';
import { Box, Typography, List, ListItem, ListItemText, Container } from '@mui/material';
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {ActionBtn, ActionSection, ActionText, ActionTitle} from "../HomePage/styles";
import {userSession} from "../../services/auth/ApiService/client";
import {setLoginOpen} from "../../store/appSlice";

const PoliticPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  return (
      <>
        <Container maxWidth="md" sx={{marginTop: '82px'}}>
          <Box sx={{my: 4, textAlign: 'justify'}}>
            <Typography variant="h3" component="h3" gutterBottom>
              ОПЛАТА И ВОЗВРАТ
            </Typography>
            <h4>1. ОПЛАТА БАНКОВСКИМИ КАРТАМИ</h4>
            1.1. Мы принимаем платежи по банковским картам следующих платежных
            систем: VISA, MASTERCARD<br/>
            1.2. После нажатия на кнопку &quot;Оплатить&quot;, вы попадете на страницу оплаты,
            где будет необходимо ввести данные вашей пластиковой карты и
            подтвердить оплату. Вся передаваемая вами информация
            конфиденциальна и не подлежит разглашению. Данные вашей карты
            передаются в зашифрованном виде. Передача информации
            осуществляется по закрытым банковским сетям высшей степени
            защиты. Таким образом, персональные и банковские данные клиента
            недоступны для работников интернет-магазина. Если на вашей карте
            есть в наличии необходимая сумма, она будет заблокирована и списана.<br/>
            1.3. Оплатить картой возможно только заказы, размещенные через сайт и в
            личном кабинете.<br/>
            Оплата заказа производится после его оформления.<br/>
            1.4. Получателем Услуг может быть только владелец карты.<br/>
            1.5. Подтверждением факта оформления Заказа является присвоение ему
            уникального идентификационного номера.<br/>
            1.6. Все транзакции являются окончательными. Компания не возвращает
            стоимость Покупок, за исключением случаев, указанных в разделе
            &quot;Условия возврата&quot; настоящего Соглашении.
            <h4>2. СРОК ПРЕДОСТАВЛЕНИЯ УСЛУГ</h4>
            2.1. Доступ к созданию, редактированию и публикации Момента Памяти
            предоставляется сразу же после успешной оплаты. Реквизиты для
            доступа к сервису высылаются на указанные при регистрации
            контактные данные - письмом на e-mail-ящик.<br/>
            2.2. Созданные Моменты Памяти хранятся на сервисе 10 (десять) лет,
            без необходимости доплаты или повторных платежей.<br/>
            2.3. Доступ в личный кабинет пользователя с созданными пользователем
            страницами памяти открыт без ограничений по времени.<br/>
            <h4>3. ГАРАНТИИ</h4>
            3.1. ООО &quot;СЕВЕРСНАБ ГРУПП&quot; гарантирует работоспособность QR-кода и
            доступность Момента Памяти для посещения в течение десяти лет с
            регистрации и оплаты Момента Памяти.<br/>
            3.2. В случае неработоспособности QR-кода или недоступности страницы в
            срок, указанный в п.4.1, ООО &quot;СЕВЕРСНАБ ГРУПП&quot; обязуется устранить
            проблему в течение 24 часов после получения уведомления на <a href={'mailto:north.memory.rf@gmail.com'}>north.memory.rf@gmail.com</a> о возникших проблемах.<br/>
            3.3. После устранения проблемы, пользователь получит уведомление на
            свой e-mail о доступности сервиса.<br/>
            3.4. В случае неработоспособности QR-кода в указанный в п.3.1 период,
            ООО &quot;СЕВЕРСНАБ ГРУПП&quot; обязуется бесплатно предоставить рабочий QR-код
            взамен нерабочему.<br/>
            <h4>4. ВОЗВРАТ ДЕНЕЖНЫХ СРЕДСТВ</h4>
            4.1. В случае отмены Покупки Момента Памяти, после ее создания,
            денежные средства подлежат возврату в течение 14 дней, при этом
            способ возврата определяется в зависимости от способа, который был
            использован Пользователем при оплате.<br/>
            4.1.1. В случае оплаты банковской картой - на банковскую карту, с
            которой производилась оплата.<br/>
          </Box>
        </Container>
        <ActionSection style={{marginTop:'40px'}}>
          <ActionTitle>ПОДЕЛИТЕСЬ МОМЕНТОМ ПАМЯТИ БЛИЗКОГО ЧЕЛОВЕКА</ActionTitle>
          <ActionText>С нашими табличками памяти</ActionText>
          <ActionBtn onClick={(e) => {
            if (!userSession.getSession()) {
              dispatch(setLoginOpen(true))
            } else {
              navigate('/order')
            }
          }} variant="contained">Заполнить анкету</ActionBtn>
        </ActionSection>
      </>
  );
}

export default PoliticPage;