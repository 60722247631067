import { isCancel, createCancelToken } from './ApiService/client'

/**
 * Сервис для работы с axios cancel token.
 */
class AxiosTokensService {
  constructor() {
    /** Токены текущего инстанса */
    this._tokens = {}
  }

  /**
   * Проверяет наличие токена, если токен существует - триггерит
   * отмену http зароса. Создает новый токен.
   */
  updateToken(tokenName) {
    if (this.checkToken(tokenName)) {
      this.cancelToken(tokenName)
    }

    return this.createToken(tokenName).token
  }

  /**
   * Проверяет ошибку на тип "отмена запроса"
   */
  checkError(error) {
    return isCancel(error)
  }

  /**
   * Триггерит отмену http запроса по имени токена,
   * после отмены токен удаляется из текущего инстанса
   */
  cancelToken(tokenName) {
    this._tokens[tokenName].cancel(this._tokens[tokenName])
    this._tokens[tokenName] = undefined
  }

  /**
   * Проверяет, создан ли уже токен с таким именем
   */
  checkToken(tokenName) {
    return typeof this._tokens[tokenName] !== 'undefined'
  }

  /**
   * Создает и сохраняет внутри себя новый токен
   */
  createToken(tokenName) {
    this._tokens[tokenName] = createCancelToken()

    return this._tokens[tokenName]
  }
}

export const axiosTokens = new AxiosTokensService()
