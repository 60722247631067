import {Box, Typography, Container, styled, Paper, Divider, Link, Button} from '@mui/material';

export const BackgroundContainer = styled(Container)`
  background-color: #fff;
  border: 1px solid rgba(192,201,218,.31);
  border-radius: 4px;
  box-shadow: 0 20px 20px hsla(0,0%,74.5%,.16);
  padding-top: 10px;
  border-radius: 8px;
`;
export const PhotoContainer = styled(Box)`
  display: block;
  border-radius: 50%;
  position: relative;
  width: 260px;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  border: 5px solid #fff;
  box-shadow: 0 0 24px 0 rgba(75,93,127,.14);
  margin-top: -130px;
      img {
        width: 100%;
        height: auto;
        display: block;
      }
`;
export const ModalContent = styled(Box)`
  height: 400px;
  overflow-y: auto;
  padding-right: 32px;
`;
export const ModalHeader = styled(Typography)`
  margin-bottom: 10px;
  margin-right: 32px;
`;
export const ModalDivider = styled(Divider)`
  margin-bottom: 10px;
  margin-right: 32px;
`;


export const Graveyard = styled(Box)`
  margin-bottom: 20px;
`;
export const GraveyardName = styled(Typography)`
  margin-bottom: 10px;
  font-size: 21px;
  letter-spacing: 0.02938em;
`;
export const GraveDivider = styled(Divider)`
  
`;
export const GraveAddress = styled(Typography)`
  margin-top: 10px;
  span {
    font-weight: 500;
    margin-right: 5px;
    margin-top: 15px;
  }
`;

export const MainTitle = styled(Typography)`
  color: #244D76;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 26px;
  letter-spacing: 0.06em;
  @media (max-width: 1024px) {
    padding-left: 24px;
    padding-right: 24px;
  }
  @media (max-width: 720px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const LoadedImage = styled('img')`
    width: 160px;
    height: 160px;
    object-fit: cover;
    object-position: left;
    display: inline-block;
    margin: 5px;
`;
export const LoadedImagesContainer = styled(Box)`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 0px;
    margin-bottom: 5px;
    margin-left: -5px;
    margin-right: -5px;
`;
export const LoadedImageBox = styled(Box)`
    position: relative;
`;
export const LoadedImageRemove = styled(Button)`
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 0;
    color: #fff;
    padding: 0px;
    min-width: 0;
    & svg {
        width: 20px;
        fill: #000;
    }
`;

export const TreeBlankImg = styled('img')`
    width: 650px;
    max-width: 100%;
    margin-top: 15px;
    display: block;
`;

export const QRCodeContainer = styled('div')`
    display: flex;
    justify-content: center;
    @media (max-width: 1024px) {
        flex-wrap: wrap;
    }
    & canvas {
        margin: 5px;
    }
`;

export const CustomQRCodeExampleContainer = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 350px;
    & canvas {
        display: block;
    }
`;