import { Navigation, Pagination, Scrollbar, A11y, FreeMode } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/free-mode';

import { styled } from '@mui/material';
import {useState} from "react";

export interface ImageSliderProps {
  images: string[];
}

const SwiperContainer = styled(Swiper)`
  padding-bottom: 40px;
`;

const ImageSlide = styled(SwiperSlide)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  cursor: pointer;
`;

const Img = styled('img')`
  height: 250px;
    width: auto;
  border-radius: 4px;
`;

const ImageSlider = ({images}: ImageSliderProps) => {
  const [index, setIndex] = useState(-1);
  return (
      <>
        <Lightbox
            open={index >= 0}
            index={index}
            close={() => setIndex(-1)}
            slides={images.map((img) => ({src: img}))}
        />
        <SwiperContainer
            slidesPerView={'auto'}
            modules={[Pagination, FreeMode]}
            pagination={{ clickable: true }}
            spaceBetween={10}
        >
          {images.map((slideContent, index) => <ImageSlide key={index+'imageSlider'}><Img onClick={(e) => setIndex(index)} src={slideContent} /></ImageSlide>)}
        </SwiperContainer>
      </>
  );
};

export default ImageSlider;