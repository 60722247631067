import React, { useState } from 'react';
import {
    Box,
    Container,
    TextField,
    Snackbar, Fade, SlideProps, Slide, SnackbarOrigin, Alert
} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {
    MainTitle,
} from './styles';

import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import { styled } from '@mui/material';

import {API_HOST} from "../../services/auth/ApiService/client";
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import {TransitionProps} from "@mui/material/transitions";
import axios from "axios";

const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    box-sizing: border-box;
    width: 100%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: rgba(0, 0, 0, 0.87);
    background: #fff;
    border: 1px solid #DAE2ED;
    box-shadow: 0px 2px 2px #F3F6F9;

    &:hover {
      border-color: #3399FF;
    }

    &:focus {
      border-color: #3399FF;
      box-shadow: 0 0 0 3px #3399FF;
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
);

const AnketaContainer = styled(Box)`
    display: flex;
    flex-wrap: wrap;
`;

export interface IReferalState {
    count: number;
    percent: number;
}
export interface IProfile {
    id?: string;
    email?: string;
    phone?: string;
    name?: string;
    persentIsFixed?: number;
    referalPercent?: boolean;
    referalId?: string;
    nextCount?: number;
    referalState?: IReferalState[];
    summ?: number;
}
export interface IFeedbackData {
    name?: string;
    phone?: string;
    email?: string;
    text?: string;
}
function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up" />;
}
interface State extends SnackbarOrigin {
    open: boolean;
    Transition: React.ComponentType<
        TransitionProps & {
        children: React.ReactElement<any, any>;
    }>;
}
const Feedback = () => {
  const [feedbackData, setFeedbackData] = useState<IFeedbackData>({name: '', email: '', text: '',phone:''});
  const [resultText, setResultText] = useState<string>('');
    const [state, setState] = React.useState<State>({
        open: false,
        vertical: 'bottom',
        horizontal: 'left',
        Transition: SlideTransition,
    });
  const navigate = useNavigate()
    const handleClose = () => {
        setState({
            ...state,
            open: false,
        });
    };

    const handleSubmit = (e: any) => {
      e.preventDefault();
      axios.post(`${API_HOST}/feedback`, feedbackData).then((res) => {
        if (res.data) {
            setResultText('Ваше сообщение отправлено')
            setFeedbackData({name: '', email: '', text: '',phone:''})
            setState({
                ...state,
                open: true,
            });
        }
      })
    }
    return (
    <>
        <Container style={{marginTop: '120px', paddingLeft: '0px', paddingRight: '0px',}}>
            <MainTitle variant="h4" sx={{marginBottom: '40px'}}>Обратная связь</MainTitle>
            <FormControl fullWidth sx={{m: 1}}>
                <TextField value={feedbackData.name} onChange={(e) => {
                    setFeedbackData({
                        ...feedbackData,
                        name: e.target.value,
                    })
                }} id="outlined-basic2" label="Ваше имя" variant="outlined"/>
            </FormControl>
            <FormControl fullWidth sx={{m: 1}}>
                <TextField value={feedbackData.email} onChange={(e) => {
                    setFeedbackData({
                        ...feedbackData,
                        email: e.target.value,
                    })
                }} id="outlined-basic2" label="Ваш email" variant="outlined"/>
            </FormControl>
            <FormControl fullWidth sx={{m: 1}}>
                <TextField value={feedbackData.phone} onChange={(e) => {
                    setFeedbackData({
                        ...feedbackData,
                        phone: e.target.value,
                    })
                }} id="outlined-basic2" label="Ваш номер телефона" variant="outlined"/>
            </FormControl>
            <FormControl fullWidth sx={{m: 1}}>
                <Textarea value={feedbackData.text} onChange={
                    (e) => setFeedbackData({
                        ...feedbackData,
                        text: e.target.value,
                    })
                } aria-label="minimum height" minRows={5} placeholder="Текст обращения"/>
            </FormControl>
            <Button onClick={(e) => handleSubmit(e)} sx={{m: 1, width: '100%'}} variant="contained">
                Отправить
            </Button>
        </Container>
        <Snackbar
            open={state.open}
            onClose={handleClose}
            TransitionComponent={SlideTransition}
            autoHideDuration={5000}
        >
            <Alert
                onClose={handleClose}
                severity="success"
                variant="filled"
                sx={{ width: '100%' }}
            >
                {resultText}
            </Alert>
        </Snackbar>
    </>
    );
}

export default Feedback;