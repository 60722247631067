import React, {useCallback, useEffect, useState} from 'react';
import {Box, Typography, Container, Divider, FormControlLabel, Checkbox, TextField, Grid} from '@mui/material';
import {Link, useNavigate} from 'react-router-dom';
import { FullscreenControl, GeolocationControl, Map, Placemark, RouteButton, YMaps } from '@pbe/react-yandex-maps';
import SpringModal from '../../components/Modal';
import ImageSlider from '../../components/ImageSlider';
import qrImage from '../../assets/img/qr.png';
import {
    BackgroundBlock,
    BackgroundContainer,
    BioBox,
    BioLink,
    BioPaper,
    BioTitle,
    CitateBox,
    ContentCopyIconBtn,
    DetailsBox,
    DetailsContainer,
    DetailsRow,
    GraveAddress,
    GraveDivider,
    Graveyard,
    GraveyardName,
    HumanDate,
    HumanName,
    HumanYears,
    MainTitle,
    ModalContent,
    ModalDivider,
    ModalHeader,
    NameDivider,
    PhotoContainer,
    QRCode,
    SubCitateBox
} from './styles';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import { styled } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AddIcon from '@mui/icons-material/Add';
import client, {API_HOST, UI_HOST, userSession} from "../../services/auth/ApiService/client";
import {IAnketa} from "../ChangePage/ChangePage";
import {paymentSend, verifyPayment} from "../../utils/billing";

const AnketaContainer = styled(Box)`
    display: flex;
    flex-wrap: wrap;
`;

export interface IReferalState {
    count: number;
    percent: number;
}
export interface IProfile {
    id?: string;
    email?: string;
    phone?: string;
    name?: string;
    persentIsFixed?: number;
    referalPercent?: boolean;
    referalId?: string;
    nextCount?: number;
    referalState?: IReferalState[];
    summ?: number;
}

const CheckoutPage = () => {
  const [ankets, setAnkets] = useState<IAnketa[]>([]);
  const [profile, setProfile] = useState<IProfile>({name: '', email: '', phone: ''});
  const [resultText, setResultText] = useState<string>('Идет проверка платежа');
  const navigate = useNavigate()
    useEffect(() => {
        const loadData = async () => {
            const billingResult = await verifyPayment();
            console.log(billingResult)
            if (billingResult) {
                setResultText('Платеж прошел успешно')
                const orderId = localStorage.getItem('activeOrder');
                console.log(orderId)
                await client.post(`${API_HOST}/access-payment/${orderId}`).catch(e => console.log(e));
                // localStorage.removeItem('activeOrder');
            } else {
                setResultText('Платеж не прошел')
            }
        }
        loadData();
    }, [])
    return (
    <>
        <Container style={{marginTop: '120px', paddingLeft: '0px', paddingRight: '0px',}}>
          <MainTitle variant="h4" sx={{marginBottom: '40px'}}>{resultText}</MainTitle>
            <Link to={`/profile`}> Вернуться в профиль </Link>
        </Container>

    </>
  );
}

export default CheckoutPage;