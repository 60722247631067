import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function AccordionBlock() {
  const [expanded, setExpanded] = React.useState<string | false>('panel1');

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>Что такое «#На севере память»?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>#На севере память - это сервис, позволяющий сохранять и передавать память и воспоминания о близких людях</p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>Почему важно создавать Моменты Памяти о своих предках?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>Все материальные носители имеют ограниченный срок существования. А информация, которая хранится в сети, практически вечна.</p>
            <p>Ваши дети, внуки и правнуки узнают о своих предках, смогут увидеть их, услышать голос.</p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>Как я могу создать Моменты Памяти о человеке?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>Вам нужно зарегистрироваться, зайти в Личный кабинет и в нем создать краткий Момент Памяти. Если хотите разместить больше информации, оплатите расширенный функционал – и заполните поля формы Момента Памяти.</p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>Как я могу заказать табличку для размещения QR кода?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>Свяжитесь с администратором через чат или через форму обратной связи</p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>Сколько будет храниться момент памяти?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>«#На севере память» предназначен для хранения вашей информации неограниченно долго. Это обеспечивается надежной инфраструктурой и контролем за целостностью данных.</p>
            <p>Мы используем распределенные сервера высшей категории надежности, имеющие сертификат соответствия требованиям PCI DSS. Для соответствия требованиям ФЗ-152 и индустриальных стандартов, данных хранятся на серверах, расположенных в России.</p>
            <p>База данных и сервисы хранят пользовательские данные в зашифрованном виде. Настроен автоматический бэкап базы данных, а резервные копии сохраняются на разных серверах. В случае любого сбоя мы можем восстановить информацию максимально быстро.</p>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
