import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/free-mode';

import './styles.css';

// import required modules
import { Scrollbar, FreeMode, Autoplay } from 'swiper/modules';
import {Link} from "react-router-dom";
import SpringModal from "../../../components/Modal";
import {Box, Typography} from "@mui/material";
import {ModalContent, ModalDivider, ModalHeader} from "../styles";
import ImageSlider from "../../../components/ImageSlider";
import {IModalContent} from "../SolderPage";

export interface IHonor {
    title: string,
    content: string,
    img: string,
    imageSet: Array<{ title: string, img: string }>,
}

type Props = {
    honors: IHonor[];
}

export default function HonorsSlider ({honors} : Props) {
    const [open, setOpen] = React.useState(false);
    const [modalContent, setModalContent] = React.useState({} as IModalContent);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const setUpModal = (content: IModalContent) => {
        const newModalContent = {
            ...modalContent,
            ...content,
        };
        setModalContent(newModalContent);
    }
    return (
        <>
            <SpringModal open={open} handleClose={handleClose}>
                <Box style={ { paddingRight: '2px' } }>
                    <ModalHeader variant="h6" id="spring-modal-title">
                        {modalContent.title}
                    </ModalHeader>
                    <ModalDivider />
                    <ModalContent>
                        <Typography component="div" id="spring-modal-description" sx={{ mt: 2 }} dangerouslySetInnerHTML={{ __html: modalContent.content }} />
                        {modalContent?.imageSet?.length && <ImageSlider images={modalContent.imageSet.map((image) => image.img)} />}
                    </ModalContent>
                </Box>
            </SpringModal>
            <Swiper
                breakpoints={{
                    0: {
                        slidesPerView: 1
                    },
                    768: {
                        slidesPerView: 2
                    },
                    1024: {
                        slidesPerView: 3
                    },
                    1280: {
                        slidesPerView: 4
                    },
                }}
                spaceBetween={10}
                freeMode={true}
                autoplay={{
                    delay: 4500,
                }}
                scrollbar={{ draggable: true }}
                modules={[Scrollbar, FreeMode, Autoplay]}
                className="honorsSwiper"
            >
                {
                    honors.map((honor, index) => {
                        return (
                            <SwiperSlide key={honor.title + index}>
                                <img src={honor.img} alt={honor.title} />
                                <div className="hero-description">
                                    <p className="hero-name">{honor.title}</p>
                                </div>
                                <div className="hero-full-container">
                                    <div className="hero-full-description">
                                        <button className="full-button" onClick={(e) => {
                                            e.preventDefault();
                                            setUpModal({
                                                title: honor.title,
                                                content: honor.content,
                                                imageSet: honor.imageSet,
                                            });
                                            handleOpen();
                                        }}>Посмотреть историю получения</button>
                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
        </>
    );
}