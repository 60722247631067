class ExpirationTokenTimeManager {
  constructor() {
    this.expiryWindow = 0 //900000 // 15 min
    this.expirationTime = null
  }

  getExpirationTime() {
    return this.expirationTime
  }

  setExpirationTime(value) {
    this.expirationTime = value * 1000
  }

  isTokenExpired() {
    console.log(this.expirationTime, new Date().getTime() + this.expiryWindow, this.expiryWindow)
    return this.expirationTime < new Date().getTime() + this.expiryWindow
  }
}

export const expirationTokenTimeManager = new ExpirationTokenTimeManager()
