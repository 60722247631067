import React from 'react';
import { Box, Typography, List, ListItem, ListItemText, Container } from '@mui/material';
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {ActionBtn, ActionSection, ActionText, ActionTitle} from "../HomePage/styles";
import {userSession} from "../../services/auth/ApiService/client";
import {setLoginOpen} from "../../store/appSlice";

const PoliticPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  return (
      <>
        <Container maxWidth="md" sx={{marginTop: '82px'}}>
          <Box sx={{my: 4, textAlign: 'justify'}}>
            <Typography variant="h3" component="h3" gutterBottom>
              Политика в отношении обработки персональных данных
            </Typography>
            <h4>1. Общие положения</h4>
            Настоящая политика обработки персональных данных составлена в соответствии с требованиями Федерального
            закона от 27.07.2006. №152-ФЗ &quot;О персональных данных&quot; (далее - Закон о персональных данных) и определяет
            порядок обработки персональных данных и меры по обеспечению безопасности персональных данных,
            предпринимаемые Обществом с ограниченной ответственностью &quot;СЕВЕРСНАБ ГРУПП&quot; (ИНН/КПП:
            5108005388/510801001, ОГРН: 1245100004214) (далее – Оператор).<br/>
            1.1. Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав
            и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на
            неприкосновенность частной жизни, личную и семейную тайну.<br/>
            1.2. Настоящая политика Оператора в отношении обработки персональных данных (далее – Политика)
            применяется ко всей информации, которую Оператор может получить о посетителях веб-Сайта <a href='https://на-севере-память.рф/'>на-севере-память.рф</a>
            <h4>2. Основные понятия, используемые в Политике</h4>
            2.1. Автоматизированная обработка персональных данных – обработка персональных данных с помощью
            средств вычислительной техники.<br/>
            2.2. Блокирование персональных данных – временное прекращение обработки персональных данных (за
            исключением случаев, если обработка необходима для уточнения персональных данных).<br/>
            2.3. Веб-Сайт/ Сайт – совокупность графических и информационных материалов, а также программ для ЭВМ и
            баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу <a
              href='https://на-севере-память.рф/'>на-севере-память.рф</a><br/>
            2.4. Информационная система персональных данных — совокупность содержащихся в базах данных
            персональных данных, и обеспечивающих их обработку информационных технологий и технических средств.<br/>
            2.5. Обезличивание персональных данных — действия, в результате которых невозможно определить без
            использования дополнительной информации принадлежность персональных данных конкретному Пользователю
            или иному субъекту персональных данных.<br/>
            2.6. Обработка персональных данных – любое действие (операция) или совокупность действий (операций),
            совершаемых с использованием средств автоматизации или без использования таких средств с персональными
            данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение),
            извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание,
            блокирование, удаление, уничтожение персональных данных.<br/>
            2.7. Оператор – государственный орган, муниципальный орган, юридическое или физическое лицо,
            самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку
            персональных данных, а также определяющие цели обработки персональных данных, состав персональных
            данных, подлежащих обработке, действия (операции), совершаемые с персональными данными. В отношнении
            настоящей Политики – Общество с ограниченной ответственностью &quot;СЕВЕРСНАБ ГРУПП&quot; (ИНН/КПП:
            5108005388/510801001, ОГРН: 1245100004214)<br/>
            2.8. Персональные данные – любая информация, относящаяся прямо или косвенно к определенному или
            определяемому Пользователю веб-Сайта <a href='https://на-севере-память.рф/'>на-севере-память.рф</a><br/>
            2.9. Персональные данные, разрешенные субъектом персональных данных для распространения, -
            персональные данные, доступ неограниченного круга лиц к которым предоставлен субъектом персональных
            данных путем дачи согласия на обработку персональных данных, разрешенных субъектом персональных
            данных для распространения в порядке, предусмотренном Законом о персональных данных (далее -
            Персональные данные, разрешенные для распространения).<br/>
            2.10. Пользователь – любой посетитель веб-Сайта <a
              href='https://на-севере-память.рф/'>на-севере-память.рф</a><br/>
            2.11. Предоставление персональных данных – действия, направленные на раскрытие персональных данных
            определенному лицу или определенному кругу лиц.<br/>
            2.12. Распространение персональных данных – любые действия, направленные на раскрытие персональных
            данных неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными
            данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой
            информации, размещение в информационно-телекоммуникационных сетях или предоставление доступа к
            персональным данным каким-либо иным способом.<br/>
            2.13. Трансграничная передача персональных данных – передача персональных данных на территорию
            иностранного государства органу власти иностранного государства, иностранному физическому или
            иностранному юридическому лицу.<br/>
            2.14. Уничтожение персональных данных – любые действия, в результате которых персональные данные
            уничтожаются безвозвратно с невозможностью дальнейшего восстановления содержания персональных данных
            в информационной системе персональных данных и (или) уничтожаются материальные носители персональных
            данных.<br/>
            2.15. Контент — опубликованные на Сайте аудиовизуальные произведения, текстовые и иные материалы
            <h4>3. Основные права и обязанности Оператора</h4>
            3.1. Оператор имеет право:<br/>
            – получать от субъекта персональных данных достоверные информацию и/или документы, содержащие
            персональные данные;<br/>
            – в случае отзыва субъектом персональных данных согласия на обработку персональных данных Оператор
            вправе продолжить обработку персональных данных без согласия субъекта персональных данных при наличии
            оснований, указанных в Законе о персональных данных;<br/>
            – самостоятельно определять состав и перечень мер, необходимых и достаточных для обеспечения выполнения
            обязанностей, предусмотренных Законом о персональных данных и принятыми в соответствии с ним
            нормативными правовыми актами, если иное не предусмотрено Законом о персональных данных или другими
            федеральными законами.<br/>
            3.2. Оператор обязан:<br/>
            – предоставлять субъекту персональных данных по его просьбе информацию, касающуюся обработки его
            персональных данных;<br/>
            – организовывать обработку персональных данных в порядке, установленном действующим законодательством
            РФ;<br/>
            – отвечать на обращения и запросы субъектов персональных данных и их законных представителей в
            соответствии с требованиями Закона о персональных данных;<br/>
            – сообщать в уполномоченный орган по защите прав субъектов персональных данных по запросу этого органа
            необходимую информацию в течение 30 дней с даты получения такого запроса;<br/>
            – публиковать или иным образом обеспечивать неограниченный доступ к настоящей Политике в отношении
            обработки персональных данных;<br/>
            – принимать правовые, организационные и технические меры для защиты персональных данных от
            неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования,
            предоставления, распространения персональных данных, а также от иных неправомерных действий в
            отношении персональных данных;<br/>
            – прекратить передачу (распространение, предоставление, доступ) персональных данных, прекратить обработку
            и уничтожить персональные данные в порядке и случаях, предусмотренных Законом о персональных данных;<br/>
            – исполнять иные обязанности, предусмотренные Законом о персональных данных
            <h4>4. Основные права и обязанности субъектов персональных данных</h4>
            4.1. Субъекты персональных данных имеют право:<br/>
            – получать информацию, касающуюся обработки его персональных данных, за исключением случаев,
            предусмотренных федеральными законами. Сведения предоставляются субъекту персональных данных
            Оператором в доступной форме, и в них не должны содержаться персональные данные, относящиеся к другим
            субъектам персональных данных, за исключением случаев, когда имеются законные основания для раскрытия
            таких персональных данных. Перечень информации и порядок ее получения установлен Законом о
            персональных данных;<br/>
            – требовать от Оператора уточнения его персональных данных, их блокирования или уничтожения в случае,
            если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не
            являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры
            по защите своих прав;<br/>
            – выдвигать условие предварительного согласия при обработке персональных данных в целях продвижения на
            рынке товаров, работ и услуг;<br/>
            – на отзыв согласия на обработку персональных данных;<br/>
            – обжаловать в уполномоченный орган по защите прав субъектов персональных данных или в судебном порядке
            неправомерные действия или бездействие Оператора при обработке его персональных данных;<br/>
            – на осуществление иных прав, предусмотренных законодательством РФ.<br/>
            4.2. Субъекты персональных данных обязаны:<br/>
            – предоставлять Оператору достоверные данные о себе;<br/>
            – сообщать Оператору об уточнении (обновлении, изменении) своих персональных данных.<br/>
            4.3. Лица, передавшие Оператору недостоверные сведения о себе, либо сведения о другом субъекте
            персональных данных без согласия последнего, несут ответственность в соответствии с законодательством РФ.
            <h4>5. Согласие на обработку персональных данных и применимые оговорки</h4>
            5.1. Пользователь Сайта свободно, своей волей и в своем интересе предоставляет Оператору свои
            персональные данные, и в интересах иных лиц – их персональные данные.<br/>
            5.2. Принимая условия настоящей Политики, Пользователь Сайта настоящим подтверждает, что
            предоставленные им персональные данные являются достоверными. Оператор исходит из того, что
            Пользователь Сайта предоставляет достоверные персональные данные и поддерживает их в актуальном
            состоянии.<br/>
            5.3. Заполняя поля данных, предусмотренные на Сайте, проставляя галочку в чек-боксе &quot;Согласен с условиями
            политики обработки персональных данных&quot;, Пользователь Сайта принимает настоящую Политику и дает свое
            согласие на обработку его персональных данных в порядке и на условиях, указанных в настоящей Политике.<br/>
            5.4. Принимая условия настоящей Политики, Пользователь Сайта подтверждает, что ему известны его права и
            обязанности, предусмотренные действующим законодательством Российской Федерации о персональных
            данных, в частности, право на доступ к своим персональным данным, на отзыв своего согласия.<br/>
            5.5. Если Пользователь Сайта не намерен предоставлять персональные данные или не принимает настоящую
            Политику, то он должен прекратить использование Сайта, в этом случае Оператор не сможет обеспечить
            Пользователю использование Сайта.<br/>
            5.6. Оператор обрабатывает персональные данные Пользователя только в случае их заполнения и/или отправки
            Пользователем самостоятельно через специальные формы, расположенные на Сайте <a
              href='https://на-севере-память.рф/'>на-севере-память.рф</a> или
            направленные Оператору посредством электронной почты. Заполняя соответствующие формы и/или отправляя
            свои персональные данные Оператору, проставляя галочку в чек-боксе о согласии с Политикой, Пользователь
            выражает свое явное согласие с данной Политикой.<br/>
            5.7. Субъект персональных данных самостоятельно принимает решение о предоставлении его персональных
            данных и дает согласие свободно, своей волей и в своем интересе.<br/>
            <h4>6. Оператор может обрабатывать следующие персональные данные Пользователя</h4>
            6.1. Фамилия, имя, отчество.<br/>
            6.2. Электронный адрес.<br/>
            6.3. Номера телефонов.<br/>
            6.4. Год, месяц, дата и место рождения.<br/>
            6.5. Фотографии.<br/>
            6.6. Также на Сайте происходит сбор и обработка обезличенных данных о посетителях (в т.ч. файлов &quot;cookie&quot;) с
            помощью сервисов интернет-статистики (Яндекс Метрика и Гугл Аналитика и других).<br/>
            6.7. Вышеперечисленные данные далее по тексту Политики объединены общим понятием Персональные
            данные.<br/>
            6.8. Обработка специальных категорий персональных данных, касающихся расовой, национальной
            принадлежности, политических взглядов, религиозных или философских убеждений, интимной жизни,
            Оператором не осуществляется.<br/>
            6.9. Обработка персональных данных, разрешенных для распространения, из числа специальных категорий
            персональных данных, указанных в ч. 1 ст. 10 Закона о персональных данных, допускается, если соблюдаются
            запреты и условия, предусмотренные ст. 10.1 Закона о персональных данных.<br/>
            6.10. Согласие Пользователя на обработку персональных данных, разрешенных для распространения,
            оформляется отдельно от других согласий на обработку его персональных данных. При этом соблюдаются
            условия, предусмотренные, в частности, ст. 10.1 Закона о персональных данных. Требования к содержанию
            такого согласия устанавливаются уполномоченным органом по защите прав субъектов персональных данных.<br/>
            6.10.1 Согласие на обработку персональных данных, разрешенных для распространения, Пользователь
            предоставляет Оператору непосредственно.<br/>
            6.10.2 Передача (распространение, предоставление, доступ) персональных данных, разрешенных субъектом
            персональных данных для распространения, должна быть прекращена в любое время по требованию субъекта
            персональных данных. Данное требование должно включать в себя фамилию, имя, отчество (при наличии),
            контактную информацию (номер телефона, адрес электронной почты или почтовый адрес) субъекта
            персональных данных, а также перечень персональных данных, обработка которых подлежит прекращению.
            Указанные в данном требовании персональные данные могут обрабатываться только Оператором, которому оно
            направлено.<br/>
            6.10.3 Согласие на обработку персональных данных, разрешенных для распространения, прекращает свое
            действие с момента поступления Оператору требования, указанного в п. 7.10.2 настоящей Политики в
            отношении обработки персональных данных.<br/>
            6.11. Для аналитики действий Пользоватля на Сайте и для функционирования Сайта Оператор обрабатывает
            Пользовательские данные, получаемые с помощью cookie-файлов в процессе пользования Сайтом, включая, но
            не ограничиваясь: сетевой адрес Пользовательского устройства, сведения о факте, дате, времени посещения
            сетевых адресов, сведения о факте, дате, времени посещения Сайтов и (или) страниц Сайтов, сведения о
            способе попадания Пользователя на страницу Сайта, сведения о переходе Пользователя по гиперссылке со
            страницы Сайта на страницу другого Сайта, сведения о факте и количестве загрузок Пользователем файлов с
            Сайта, а также о наименовании и типе загруженных файлов, продолжительность Пользовательской сессии,
            наименование интернет-провайдера Пользователя, сведения об объеме потребленного сетевого трафика,
            выданный Пользователю идентификатор файла cookie, сведения о веб-браузере Пользователя, сведения о
            Пользовательском устройстве (тип и модель устройства, наименование операционной системы и языки ее
            интерфейса, разрешение и глубину цвета экрана), поисковые запросы Пользователя, географический адрес
            точки подключения Пользователя.<br/>
            6.12. Проставляя галочку в чек-боксе на Сайте о принятии настоящей Политики конфиденциальности,
            Пользователь дает согласие на обработку данных, указанных в п. 5.11 настоящей Политики
            конфиденциальности. Пользователь Сайта вправе не давать свое согласие на обработку этих данных. В этом
            случае он должен покинуть Сайт либо изменить в настройках возможность собирать Сайтом сookie-файлы.<br/>
            6.13. Оператор может осуществлять сбор информации о посещении Сайта Пользователями без предоставления
            соответствующей информации самими Пользователями. Такая информация может быть получена с помощью
            различных методов, средств и инструментов интернет-статистики и настройки (в частности, файлов Cookie,
            сервисов Google Analytics, Яндекс.Метрика). Оператор может использовать такие методы, средства и
            инструменты для продвижения работ и услуг Оператора, предоставления информации о предстоящих
            мероприятиях путем предоставления целевой рекламы, а также повышения удобства использования Сайта
            путем создания профилей зарегистрированных Пользователей. При этом такие методы, средства и инструменты
            не используются для идентификации незарегистрированных Пользователей.<br/>
            6.14. Если Пользователь Сайта не согласен с тем, что файлы Cookie сохраняются на его устройстве, он может
            самостоятельно отключить эту опцию в настройках браузера. Сохраненные файлы Cookie также можно удалить
            в любое время в системных настройках браузера. Пользователь Сайта может изменить настройки браузера,
            чтобы принимать или отклонять по умолчанию все файлы Cookie либо файлы Cookie с определенных Сайтов,
            включая Сайт.<br/>
            6.15. Отключение некоторых файлов Cookie может привести к тому, что использование отдельных разделов или
            функций Сайта станет невозможным.<br/>
            6.16. Если Пользователь Сайта не согласен с использованием методов, средств и инструментов
            интернет-статистики и настройки, он должен прекратить использование Сайта, в этом случае ИП Кислов Никита
            Олеговия не сможет обеспечить Пользователю использование Сайта.<br/>
            6.17. Обезличенные данные Пользователей, собираемые с помощью сервисов интернет-статистики, служат для
            сбора информации о действиях Пользователей на Сайте, улучшения качества Сайта и его содержания.<br/>
            <h4>7. Принципы обработки персональных данных</h4>
            7.1. Обработка персональных данных осуществляется на законной и справедливой основе, в соответствии с
            требованиями, установленными Федеральным законом от 27.07.06 № 152-ФЗ &quot;О персональных данных&quot;, иными
            законами и подзаконными актами, устанавливающими требования к обработке Персональных данных.<br/>
            7.2. Обработка персональных данных ограничивается достижением конкретных, заранее определенных в
            настоящей Политике и законных целей. Не допускается обработка персональных данных, несовместимая с
            целями сбора персональных данных.<br/>
            7.3. Не допускается объединение баз данных, содержащих персональные данные, обработка которых
            осуществляется в целях, несовместимых между собой.<br/>
            7.4. Обработке подлежат только персональные данные, которые отвечают целям их обработки.<br/>
            7.5. Содержание и объем обрабатываемых персональных данных соответствуют заявленным целям обработки.<br/>
            Не допускается избыточность обрабатываемых персональных данных по отношению к заявленным целям их
            обработки.<br/>
            7.6. При обработке персональных данных обеспечивается точность персональных данных, их достаточность, а в
            необходимых случаях и актуальность по отношению к целям обработки персональных данных. Оператор
            принимает необходимые меры и/или обеспечивает их принятие по удалению или уточнению неполных или
            неточных данных.<br/>
            7.7. Хранение персональных данных осуществляется в форме, позволяющей определить субъекта
            персональных данных, не дольше, чем этого требуют цели обработки персональных данных, если срок хранения
            персональных данных не установлен федеральным законом, договором, стороной которого,
            выгодоприобретателем или поручителем по которому является субъект персональных данных. Обрабатываемые
            персональные данные уничтожаются либо обезличиваются по достижении целей обработки или в случае утраты
            необходимости в достижении этих целей, если иное не предусмотрено федеральным законом.<br/>
            7.8. Согласие с Политикой происходит по следующему принципу - проставление галочки на Сайте в боксе о
            принятии и ознакомлении с настоящей Политикой конфиденциальности означает, что Пользователь
            предоставил согласие на обработку его Персональных данных, в том числе на передачу Персональных данных
            платежной системе.
            <h4>8. Цели обработки персональных данных</h4>
            8.1. Цель обработки персональных данных Пользователя:<br/>
            – информирование Пользователя посредством отправки электронных писем;<br/>
            – заключение, исполнение и прекращение гражданско-правовых договоров;<br/>
            – предоставление доступа Пользователю к сервисам, информации и/или материалам, содержащимся на
            веб-Сайте <a href='https://на-севере-память.рф/'>на-севере-память.рф</a>;<br/>
            – для аналитики действий физического лица на Сайте и для функционирования Сайта;<br/>
            - для продвижения услуг, в том числе проведения рекламных и новостных рассылок путем отправления по
            электронной почте;<br/>
            - для исчисления и уплаты налоговых платежей, представления законодательно установленной отчетности в
            отношении физических лиц в налоговые органы и внебюджетные фонды;<br/>
            - для использования Сайта Пользователями, что включает в себя: коммуникацию с Оператором, предоставление
            Пользователям справочной информации, предоставление Пользователям консультаций по вопросам,
            касающимся оказываемых работ и услуг, поддержки Пользователей, повышения удобства использования Сайта,
            продвижение работ и услуг Оператором путем осуществления прямых контактов с Пользователями.<br/>
            8.2. Также Оператор имеет право направлять Пользователю уведомления о новых продуктах и услугах,
            специальных предложениях и различных событиях. Пользователь всегда может отказаться от получения
            информационных сообщений, направив Оператору письмо на адрес электронной почты <a
              href='mailto:north.memory.rf@gmail.com'>north.memory.rf@gmail.com</a> с
            пометкой &quot;Отказ от уведомлений о новых продуктах и услугах и специальных предложениях&quot;.
            <h4>9. Правовые основания обработки персональных данных</h4>
            9.1. Правовыми основаниями обработки персональных данных Оператором являются:<br/>
            – настоящая Политика;<br/>
            - Федеральный закона от 27.07.2006. №152-ФЗ &quot;О персональных данных&quot;;<br/>
            – договоры, заключаемые между Оператором и субъектом персональных данных;<br/>
            – иные федеральные законы, иные нормативно-правовые акты в сфере защиты персональных данных;<br/>
            – согласия Пользователей на обработку их персональных данных, на обработку персональных данных,
            разрешенных для распространения.<br/>
            9.2. Оператор обрабатывает обезличенные данные о Пользователе в случае, если это разрешено в настройках
            браузера Пользователя (включено сохранение файлов &quot;cookie&quot; и использование технологии JavaScript).
            <h4>10. Условия обработки персональных данных</h4>
            10.1. Обработка персональных данных осуществляется с согласия субъекта персональных данных на обработку
            его персональных данных.<br/>
            10.2. Обработка персональных данных необходима для достижения целей, предусмотренных международным
            договором Российской Федерации или законом, для осуществления возложенных законодательством
            Российской Федерации на оператора функций, полномочий и обязанностей.<br/>
            10.3. Обработка персональных данных необходима для осуществления правосудия, исполнения судебного акта,
            акта другого органа или должностного лица, подлежащих исполнению в соответствии с законодательством
            Российской Федерации об Операторном производстве.<br/>
            10.4. Обработка персональных данных необходима для исполнения договора, стороной которого либо
            выгодоприобретателем или поручителем по которому является субъект персональных данных, а также для заключения
            договора по инициативе субъекта персональных данных или договора, по которому субъект
            персональных данных будет являться выгодоприобретателем или поручителем.<br/>
            10.5. Обработка персональных данных необходима для осуществления прав и законных интересов Оператора
            или третьих лиц либо для достижения общественно значимых целей при условии, что при этом не нарушаются
            права и свободы субъекта персональных данных.<br/>
            10.6. Осуществляется обработка персональных данных, доступ неограниченного круга лиц к которым
            предоставлен субъектом персональных данных либо по его просьбе (далее – Общедоступные персональные
            данные).<br/>
            10.7. Осуществляется обработка персональных данных, подлежащих опубликованию или обязательному
            раскрытию в соответствии с федеральным законом.
            <h4>11. Порядок сбора, хранения, передачи и других видов обработки персональных данных. Безопасность</h4>
            11.1. Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путем
            реализации правовых, организационных и технических мер, необходимых для выполнения в полном объеме
            требований действующего законодательства в области защиты персональных данных.<br/>
            11.2. Оператор обеспечивает сохранность персональных данных и принимает все возможные меры,
            исключающие доступ к персональным данным неуполномоченных лиц.<br/>
            11.3. Персональные данные Пользователя никогда, ни при каких условиях не будут переданы третьим лицам, за
            исключением случаев, связанных с исполнением действующего законодательства либо в случае, если
            субъектом персональных данных дано согласие Оператору на передачу данных третьему лицу для исполнения
            обязательств по гражданско-правовому договору.<br/>
            11.3. В случае выявления неточностей в персональных данных, Пользователь может актуализировать их
            самостоятельно, путем направления Оператору уведомление на адрес электронной почты Оператора <a href='mailto:north.memory.rf@gmail.com'>north.memory.rf@gmail.com</a> пометкой &quot;Актуализация персональных
            данных&quot;.<br/>
            11.4. Срок обработки персональных данных<br/>
            11.4.1. Срок обработки персональных данных определяется достижением целей, для которых были собраны
            персональные данные, если иной срок не предусмотрен договором или действующим законодательством.<br/>
            11.4.2 Пользователь может в любой момент отозвать свое согласие на обработку персональных данных,
            направив Оператору уведомление посредством электронной почты на электронный адрес Оператора <a href='mailto:north.memory.rf@gmail.com'>north.memory.rf@gmail.com</a> с пометкой &quot;Отзыв согласия на обработку
            персональных данных&quot;.<br/>
            11.5. Вся информация, которая собирается сторонними сервисами, в том числе платежными системами,
            средствами связи и другими поставщиками услуг, хранится и обрабатывается указанными лицами
            (Операторами) в соответствии с их Пользовательским соглашением и Политикой конфиденциальности. Субъект
            персональных данных и/или Пользователь обязан самостоятельно своевременно ознакомиться с указанными
            документами. Оператор не несет ответственность за действия третьих лиц, в том числе указанных в настоящем
            пункте поставщиков услуг.<br/>
            11.6. Установленные субъектом персональных данных запреты на передачу (кроме предоставления доступа), а
            также на обработку или условия обработки (кроме получения доступа) персональных данных, разрешенных для
            распространения, не действуют в случаях обработки персональных данных в государственных, общественных и
            иных публичных интересах, определенных законодательством РФ.<br/>
            11.7. Оператор при обработке персональных данных обеспечивает конфиденциальность персональных данных.<br/>
            11.8. Оператор осуществляет хранение персональных данных в форме, позволяющей определить субъекта
            персональных данных, не дольше, чем этого требуют цели обработки персональных данных, если срок хранения
            персональных данных не установлен федеральным законом, договором, стороной которого,
            выгодоприобретателем или поручителем по которому является субъект персональных данных.<br/>
            11.9. Прекращение обработки персональных данных.<br/>
            11.9.1. Оператор прекращает обработку персональных данных в следующих случаях:<br/>
            - при наступлении условий прекращения обработки Персональных данных или по истечении установленных
            сроков;<br/>
            - по достижении целей их обработки либо в случае утраты необходимости в достижении этих целей;<br/>
            - по требованию субъекта персональных данных, если обрабатываемые Исполнителем персональные данные
            являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для
            заявленной цели обработки;<br/>
            - в случае отзыва Пользователем согласия на обработку его персональных данных или истечения срока
            действия такого согласия (если персональные данные обрабатываются Исполнителем исключительно на
            основании согласия субъекта персональных данных);<br/>
            11.9.2. Оператор прекращает обработку персональных данных в случае отзыва согласия Пользователем в
            течение 30 календарных дней. Оператор обязан уведомить об этом Пользователя не позднее 3 рабочих дней
            после прекращения обработки персональных данных по электронной почте.<br/>
            <h4>12. Меры защиты персональных данных</h4>
            12.1. Оператор в целях защиты персональных данных принимает меры, предусмотренные Федеральным
            законом от 27.07.06 № 152-ФЗ &quot;О персональных данных&quot;, иными нормативными актами в области защиты
            персональных данных и настоящей Политикой конфиденциальности.<br/>
            12.2. Оператор принимает необходимые и достаточные организационные и технические меры для защиты
            Персональных данных от неправомерного или случайного доступа, уничтожения, изменения, блокирования,
            копирования, распространения, а также от иных неправомерных действий с ней третьих лиц.<br/>
            <h4>13. Перечень действий, производимых Оператором с полученными персональными данными, способы обработки
              персональных данных</h4>
            13.1. Оператор осуществляет сбор, запись, систематизацию, накопление, хранение, уточнение (обновление,
            изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание,
            блокирование, удаление и уничтожение персональных данных.<br/>
            13.2. Оператор осуществляет автоматизированную обработку персональных данных с получением и/или
            передачей полученной информации по информационно-телекоммуникационным сетям или без таковой.<br/>
            13.3. Оператор может осуществлять обработку персональных данных с использованием средств автоматизации
            или без использования таких средств путем сбора, записи, систематизации, накопления, хранения, уточнения
            (обновления, изменения), извлечения, использования, передачи (предоставления, доступа), блокирования,
            удаления, уничтожения.<br/>
            13.4. Оператор будет осуществлять обработку персональных данных не дольше, чем этого требуют цели их
            обработки, если иные сроки не предусмотрены действующим законодательством Российской Федерации о
            персональных данных.<br/>
            <h4>14. Трансграничная передача персональных данных</h4>
            14.1. Оператор вправе осуществлять трансграничную передачу персональных данных в соответствии и по
            правилам, определенным в Законе о персональных данных и иных федеральных законах и
            нормативно-правовых актв в сфере персональных данных.<br/>
            14.2. Оператор до начала осуществления трансграничной передачи персональных данных обязан убедиться в
            том, что иностранным государством, на территорию которого предполагается осуществлять передачу
            персональных данных, обеспечивается надежная защита прав субъектов персональных данных.<br/>
            14.3. Трансграничная передача персональных данных на территории иностранных государств, не отвечающих
            вышеуказанным требованиям, может осуществляться только в случае наличия согласия в письменной форме
            субъекта персональных данных на трансграничную передачу его персональных данных и/или исполнения
            договора, стороной которого является субъект персональных данных.<br/>
            <h4>15. Конфиденциальность персональных данных</h4>
            15.1 Оператор и иные лица, получившие доступ к персональным данным, обязаны не раскрывать третьим лицам
            и не распространять персональные данные без согласия субъекта персональных данных, если иное не
            предусмотрено федеральным законом.
            <h4>16. Заключительные положения</h4>
            16.1. Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его
            персональных данных, обратившись к Оператору с помощью электронной почты <a
              href='mailto:north.memory.rf@gmail.com'>north.memory.rf@gmail.com</a><br/>
            16.2. В данном документе будут отражены любые изменения политики обработки персональных данных
            Оператором. Политика действует бессрочно до замены ее новой версией.<br/>
            16.3. Актуальная версия Политики в свободном доступе расположена в сети Интернет по адресу <a href='https://на-севере-память.рф/processing-of-personal-data'>https://на-севере-память.рф/processing-of-personal-data</a>
            <h4>17. Реквизиты компании</h4>
            ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ &quot;СЕВЕРСНАБ ГРУПП&quot;
            <ul>
              <li>Адрес: 184511, РОССИЯ, МУРМАНСКАЯ ОБЛАСТЬ, Г МОНЧЕГОРСК, УЛ КУМУЖИНСКАЯ, Д. 7, КВ. 28</li>
              <li>ОГРН: 1245100004214</li>
              <li>ИНН: 7710140679</li>
            </ul>
            Платежные реквизиты:
            <ul>
              <li>Р/с 40702810910001665831 в АО &quot;ТБанк&quot; г. Москва</li>
              <li>К/с 30101810145250000974</li>
              <li>БИК 044525974</li>
            </ul>
            Генеральный директор: Громов А.А.
          </Box>
        </Container>
        <ActionSection style={{marginTop:'40px'}}>
          <ActionTitle>ПОДЕЛИТЕСЬ МОМЕНТОМ ПАМЯТИ БЛИЗКОГО ЧЕЛОВЕКА</ActionTitle>
          <ActionText>С нашими табличками памяти</ActionText>
          <ActionBtn onClick={(e) => {
            if (!userSession.getSession()) {
              dispatch(setLoginOpen(true))
            } else {
              navigate('/order')
            }
          }} variant="contained">Заполнить анкету</ActionBtn>
        </ActionSection>
      </>
  );
}

export default PoliticPage;