import React, {useCallback, useEffect, useRef, useState} from 'react';
import { FullscreenControl, GeolocationControl, Map, Placemark, RouteButton, YMaps } from '@pbe/react-yandex-maps';
import {
    BackgroundContainer, CAddress, CContainer, CItem, CList, CMap, CName,
    GraveAddress,
    GraveDivider,
    Graveyard,
    GraveyardName,
} from './styles';

const tableExample = [
    {
        id: 1,
        name: 'Салон ритуальных услуг Похоронный Дом',
        email: 'test1@mail.ru',
        phone: '+7 999 999 99 99',
        coordinates: [68.9539819,33.1066582],
        selected: false,
    },
    {
        id: 2,
        name: 'Салон Ритуальных Услуг "Последний путь"',
        email: 'test2@mail.ru',
        phone: '+7 999 999 99 99',
        coordinates: [68.9528438,33.1058195],
        selected: false,
    },
    {
        id: 3,
        name: 'ООО "Городской Центр Ритуальных Услуг"',
        email: 'test3@mail.ru',
        phone: '+7 999 999 99 99',
        coordinates: [68.9552536,33.105201],
        selected: false,
    },
]

interface ICompany {
    id: number;
    name: string;
    email: string;
    phone: string;
    coordinates: number[];
    selected: boolean;
}

const CompaniesPage = () => {
    const ref: any = useRef();
    const ref2: any = useRef();
    const ymaps: any = React.useRef(null);
    const [coords, setNewCoords] = React.useState<number[]>([68.9539819,33.1066582])
    const [companies, setCompanies] = useState<ICompany[]>([])
    const setCenter = (id: number) => {
        const item = companies.find(item => item.id === id);
        if (item) {
            const obj = ref.current.getMap().geoObjects;
            obj.each((geo: any) => {
                if (geo.geometry._coordinates[0] === item.coordinates[0] && geo.geometry._coordinates[1] === item.coordinates[1]) {
                    geo.balloon.open()
                }
            })
            setNewCoords(item.coordinates)
            setCompanies(
                companies.map(item => ({
                    ...item,
                    selected: item.id === id
                }))
            )
        }
    };

  const mapState = {
    center: tableExample[0]?.coordinates || [55.751574, 37.573856],
    zoom: 15,
    controls: []
  };
    const onYmapsLoad = (e: any) => {
        ymaps.current = e;
    }
    useEffect(() => {
        const loadData = async () => {
            setCompanies(tableExample)
        }
        loadData()
    }, []);

  return (
    <>
        <BackgroundContainer style={{marginTop: '120px', paddingTop: '20px', paddingBottom: '20px'}}>
          <Graveyard>
            <GraveyardName>Компании ритуальных услуг</GraveyardName>
            <GraveDivider />
            <GraveAddress>Для ритуальных услуг вы можете воспользоваться следующими компаниями:</GraveAddress>
          </Graveyard>
            <CContainer>
                <CList>
                    {companies?.length > 0 && companies.map((item, index) => (
                        <CItem key={`company-${index}-${item.email}-${item.phone}`} onClick={(e) => setCenter(item.id)}>
                            <CName>{item.name}</CName>
                            <CAddress>Контактные данные:<br /> Номер телефона: <a href={`tel:${item.phone}`}>{item.phone}</a><br />  Email: <a href={`tel:${item.email}`}>{item.email}</a></CAddress>
                        </CItem>
                    ))}
                </CList>
                <CMap>
                    <Map state={{
                        ...mapState,
                        center: coords,
                    }} width="100%" height="440px" onLoad={onYmapsLoad} instanceRef={ref2}>
                        {companies?.length > 0 && companies.map((item, index) => (
                            <Placemark key={index} modules={["geoObject.addon.balloon"]} geometry={item.coordinates} properties={
                                {
                                    balloonContentHeader: item.name,
                                    balloonContentBody: `<p>Контактные данные:<br> Номер телефона: <a href="tel:${item.phone}">${item.phone}</a><br>  Email: <a href="tel:${item.email}">${item.email}</a><br></p>`,
                                }
                            } instanceRef={ref} onLoad={(e: any) => {
                                if (item.selected) {
                                    e.balloon.open()
                                }
                            }} />
                        ))}
                        {/*<Placemark modules={["geoObject.addon.balloon"]} geometry={[55.684758, 37.738521]} properties={{balloonContentBody: 'ул. Рябиновая, вл.24, Троекуровское кладбище'}} />*/}
                        <FullscreenControl />
                        <GeolocationControl />
                        <RouteButton />
                    </Map>
                </CMap>
            </CContainer>
        </BackgroundContainer>
    </>
  );
}

export default CompaniesPage;