import { Box, Typography, Container, styled, Paper, Divider, Link } from '@mui/material';

export const BackgroundBlock = styled(Box)`
  background-image: url(https://src.memorycode.ru/storage/app/public/5981/media-libraryffauXE);
  background-size: cover;
  background-position-y: -50px;
  margin-bottom: -150px;
  height: 350px;
  width: 100%;
`;
export const BackgroundContainer = styled(Container)`
  background-color: #fff;
  border: 1px solid rgba(192,201,218,.31);
  border-radius: 4px;
  box-shadow: 0 20px 20px hsla(0,0%,74.5%,.16);
  padding-top: 10px;
  border-radius: 8px;
`;

export const FTBox = styled(Box)`
    background: #fff;
    color: #244D76;
    text-align: center;
    width: 172px;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 0 20px 20px hsla(0,0%,74.5%,.16);
    position: absolute;
    border: 1px solid #e4e4e4;
       img {
           width: 100px;
           border-radius: 90px;
       }
        span {
              font-size: 10px;
         }
        h6:first-of-type {
            font-size: 14px;
        }
        h6:nth-of-type(2) {
            font-size: 12px;
        }
`;
export const PhotoContainer = styled(FTBox)`
  display: block;
  border-radius: 50%;
  position: relative;
  width: 260px;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  border: 5px solid #fff;
  box-shadow: 0 0 24px 0 rgba(75,93,127,.14);
  margin-top: -130px;
      img {
        width: 100%;
        height: auto;
        display: block;
      }
`;
export const HumanName = styled(Typography)`
  text-align: center;
  margin-top: 15px;
  color: #244D76;
  font-weight: 500;
  font-size: 28px;
`;
export const NameDivider = styled(Divider)`
  width: 350px;
  margin: 0 auto;
  margin-top: 7px;
  border-color: rgba(0, 0, 0, 0.17);
`;
export const HumanDate = styled(Typography)`
  text-align: center;
  margin-top: 15px;
  font-size: 18px;
  font-weight: 600;
  color: #18334e;
  letter-spacing: 0.015em;
`;
export const HumanYears = styled(Typography)`
  text-align: center;
`;
export const CitateBox = styled(Box)`
  background-color: #f8f9fc;
  border-radius: 8px;
  padding: 20px;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 600;
  color: #18334e;
  margin-left: -40px;
  margin-right: -40px;
  position: relative;
  z-index: 1;
  @media (max-width: 1024px) {
    margin-left: 0px;
    margin-right: 0px;
  }
  @media (max-width: 720px) {
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 30px;
    padding-bottom: 30px;
      p {
        font-size: 1rem;
      }
  }
  :after{
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-image: url(https://src.memorycode.ru/storage/app/public/5979/media-library5fSQqe);
    background-size: cover;
    background-position-y: -50px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.08;
    z-index: -1;  
  }
`;
export const SubCitateBox = styled(Box)`
  max-width: 650px;
  margin: 0 auto;
  text-align: justify;
  text-align-last: center;
      p {
        font-size: 18px;
      }
      h6 {
        margin-top: 20px;
      }
`;

export const DetailsContainer = styled(Box)`
  position: relative;
  margin-top: 60px;
  @media (max-width: 720px) {
    flex-direction: column;
  }
`;
export const DetailsBox = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media (max-width: 720px) {
    flex-direction: column;
  }
`;
export const DetailsRow = styled(Box)`
  border-bottom: 1px solid #d2d5d9;
  margin-bottom: 20px;
  padding-bottom: 20px;
  display: flex;
  width: 50%;
  @media (max-width: 720px) {
    width: 100%;
  }
    span:first-of-type{
      font-weight: 500;
      width: 185px;
      display: inline-block;
      font-size: 14px;
      @media (max-width: 720px) {
        width: 50%;
      }
    }
    span:last-of-type{
      font-weight: 400;
      font-size: 14px;
      @media (max-width: 720px) {
        text-align: right;
        width: 50%;
        display: inline-block;
      }
    }
`;
export const ModalContent = styled(Box)`
  height: 400px;
  overflow-y: auto;
  padding-right: 32px;
`;
export const ModalHeader = styled(Typography)`
  margin-bottom: 10px;
  margin-right: 32px;
`;
export const ModalDivider = styled(Divider)`
  margin-bottom: 10px;
  margin-right: 32px;
`;

export const BioPaper = styled(Paper)`
  padding: 0px;
  box-shadow: none;
  transition: none;
  padding-bottom: 10px;
`;
export const BioTitle = styled(Typography)`
  margin-bottom: 15px;
`;
export const BioBox = styled(Box)`

`;
export const BioLink = styled(Link)`
  margin-bottom: 10px;
  display: block;
  text-decoration: none;
`;

export const Graveyard = styled(Box)`
  margin-bottom: 20px;
`;
export const GraveyardName = styled(Typography)`
  margin-bottom: 10px;
  font-size: 21px;
  letter-spacing: 0.02938em;
`;
export const GraveDivider = styled(Divider)`
  
`;
export const GraveAddress = styled(Typography)`
  margin-top: 10px;
  span {
    font-weight: 500;
    margin-right: 5px;
    margin-top: 15px;
  }
`;

export const QRCode = styled('img')`
  max-width: 250px;
  margin: 0 auto;
  display: block;
`;

export const MainTitle = styled(Typography)`
  color: #244D76;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 26px;
  letter-spacing: 0.06em;
  @media (max-width: 1024px) {
    padding-left: 24px;
    padding-right: 24px;
  }
  @media (max-width: 720px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const CContainer = styled(Container)`
    position: relative;
    display: flex;
    padding-left: 0px;
    @media (max-width: 720px) {
        flex-direction: column;
        padding-left: 0px
    }
    @media (min-width: 600px) {
        padding-left: 0px
    }
` as typeof Container;
export const CList = styled(Box)`
    width: 290px;
` as typeof Box;
export const CItem= styled(Box)`
    padding-top: 15px;
    padding-bottom: 15px;
    cursor: pointer;
    transition: 0.2s all ease;
    padding-left: 24px;
    margin-left: -24px;
    &:hover{
        background: #e4e4e42b;
    }
` as typeof Box;
export const CName= styled(Box)`
    font-weight: 500;
    padding-bottom: 10px;
    color: #244D76;
    font-size: 16px;
    letter-spacing: 0.7px;
    line-height: 1.4;
` as typeof Box;
export const CAddress= styled(Box)`
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.4px;
    line-height: 1.5;
    & a {
        text-decoration: none;
        text-transform: none;
    }
` as typeof Box;
export const CMap= styled(Box)`
    flex-basis: max-content;
    flex-grow: 1;
` as typeof Box;
