import {Box, TextField} from "@mui/material";
import React, {useEffect} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Autocomplete from "@mui/material/Autocomplete";
import {IAnketa, initialState, IRelationsResult} from "../../pages/ChangePage/ChangePage";
import _ from "lodash";
import {API_HOST} from "../../services/auth/ApiService/client";

const TreeAutocomplete = ({relationsResult, setRelationsResult}: {relationsResult: IRelationsResult[], setRelationsResult: any}) => {
    const [open, setOpen] = React.useState(false);
    const [ankets, setAnkets] = React.useState<IAnketa[]>([])
    const loading = open && ankets?.length === 0;

    const onSearch = async (value: string) => {
        const search = async (text: string) => {
            try {
                let searchText = `${API_HOST}/anketas`
                if (text) {
                    searchText += `?search=${text}`
                } else {
                    searchText += `?page=1`
                }
                const res = await fetch(searchText)
                const data = await res.json() as IAnketa[]

                let searchText2 = `${API_HOST}/solders`
                if (text) {
                    searchText2 += `?search=${text}`
                } else {
                    searchText2 += `?page=1`
                }
                const res2 = await fetch(searchText2)
                const data2 = await res2.json() as IAnketa[]

                setAnkets([...data, ...data2])
            } catch (e) {
                console.log(e);
            }

        };
        search(value)
    }

    React.useEffect(() => {
        console.log('open',open)
        if (!open) {
            setAnkets([]);
        }
    }, [open]);

    return (
        <Autocomplete
            id="tree-autocomplete"
            // fullWidth
            // freeSolo
            open={open}
            onOpen={() => {
                setOpen(true);
                onSearch('')
            }}
            onClose={() => {
                setOpen(false);
            }}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            options={ankets?.map((item: IAnketa) => item)}
            loading={loading}
            loadingText="Идет поиск..."
            clearText="Очистить"
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Введите ФИО"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
            getOptionLabel={(option) => option.name}
            onInputChange={(event, newValue) => {
                if (typeof newValue === "string" || newValue === '') {
                    onSearch(newValue)
                }
            }}
            onChange={(event, newValue) => {
                if (!newValue) {
                    onSearch('')
                } else {
                    if (newValue) {
                        const ank = newValue as IAnketa
                        const newArr = [...relationsResult, {
                            anketa: ank,
                            relation: '',
                            position: 1,
                        }]
                        setRelationsResult(newArr)
                    }
                }
            }}
            renderOption={(props: any, option: IAnketa) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <img
                        loading="lazy"
                        width="20"
                        srcSet={`${option.photo.src} 2x`}
                        src={option.photo.src}
                        alt={option.name}
                    />
                    {option.name} ({option.dateBorn} - {option.dateEnd})
                </Box>
            )}
        />
    )
}

export default TreeAutocomplete;