import { Box, Typography, Container, styled, Paper, Divider, Link } from '@mui/material';

import secondImage from '../../assets/img/trees/t6.png';

import bgSnow from '../../assets/img/bg-snow.png';
import solder1 from '../../assets/img/solder1.png';
import solder3 from '../../assets/img/solder3.png';
import bgCity1 from '../../assets/img/north5/bg0.png';
import quotes1 from '../../assets/img/quotes1.png';

export const SnowBackground = styled(Box)`
    background: url(${bgSnow});
    padding-bottom: 40px;
`;

export const BackgroundBlock = styled(Box)`
    background-image: url(${bgCity1});
    background-size: cover;
    background-position-x: 0px;
    background-position-y: bottom;
    background-repeat: no-repeat;
    margin-bottom: -150px;
    height: 500px;
    width: 100%;
    position: relative;
    :after{
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background: rgba(12,12,12,0.75);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }
`;
export const BackgroundContainer = styled(Container)`
    background-color: #fff;
    border: 1px solid rgba(192,201,218,.31);
    box-shadow: 0 20px 20px hsla(0,0%,74.5%,.16);
    padding-top: 10px;
    border-radius: 8px;
    position: relative;
    z-index: 2;
    padding-bottom: 20px;
`;

export const SocialLinksContainer = styled(Box)`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
` as typeof Box;
export const SocialLink = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: baseline;
    width: fit-content;
    margin: 5px;
    & svg {
        margin-right: 5px;
    }
` as typeof Link;


export const FTBox = styled(Box)`
    background: #fff;
    color: #244D76;
    text-align: center;
    //width: 172px;
    //padding: 5px;
    //border-radius: 5px;
    border-radius: 0px;
    box-shadow: 0 20px 20px hsla(0,0%,74.5%,.16);
    position: absolute;
    border: 1px solid #e4e4e4;
    img {
        width: 70px;
        height: 70px;
        object-fit: cover;
        border-radius: inherit;
        transition: all 0.2s ease;
        @media (max-width: 720px) {
            width: 40px;
            height: 40px;
        }
    }
    span {
        font-size: 10px;
    }
    h6:first-of-type {
        font-size: 14px;
    }
    h6:nth-of-type(2) {
        font-size: 12px;
    }
`;
export const FThiddenBox = styled(Box)`
    position: absolute;
    display: block;
    background: #fff;
    border-left: 1px solid #e4e4e4;
    border-right: 1px solid #e4e4e4;
    border-bottom: 1px solid #e4e4e4;
    padding: 5px;
    opacity: 0;
    height: 0px;
    overflow: hidden;
    transition: all 0.2s ease-out;
    z-index: -1;
    width: 189px;
`;
export const FT = styled(FTBox)`
    transition: all 0.5s ease;
    &:hover ${FThiddenBox} {
        opacity: 1;
        height: 120px;
        z-index: 1;
        transition: all 0.5s ease-in;
    }
    &:hover img {
        height: 200px;
        width: 200px;
    }
`;
export const FamilyTreeContainer = styled(Container)`
  background-color: #fff;
  background-image: url(${secondImage});
    width: 100%;
    height: 900px;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: contain;
    box-shadow: none;
    position: relative;
    @media (max-width: 720px) {
        height: 400px;
        overflow: hidden;
    }
`;

export const HonorContainer = styled(Container)`
    margin-top: 40px;
    padding-left: 0;
    padding-right: 0;
    position: relative;
    @media (max-width: 720px) {
        overflow: hidden;
    }
    :after{
        content: '';
        display: block;
        width: 300px;
        height: 300px;
        background-image: url(${solder3});
        background-size: contain;
        background-position: 13%;
        background-repeat: no-repeat;
        position: absolute;
        top: -140px;
        left: -150px;
        opacity: 0.7;
        z-index: 0;
        @media (max-width: 720px) {
            display: none;            
        }
    }
    :before{
        content: '';
        display: block;
        width: 300px;
        height: 300px;
        background-image: url(${solder1});
        background-size: contain;
        background-position: 13%;
        background-repeat: no-repeat;
        position: absolute;
        top: -140px;
        right: -140px;
        opacity: 0.7;
        z-index: 0;
        @media (max-width: 720px) {
            display: none;
        }
    }
`;
export const PhotoContainer = styled(FTBox)`
    padding: 0;
  display: block;
  border-radius: 50%;
  position: relative;
  width: 260px;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  border: 5px solid #fff;
  box-shadow: 0 0 24px 0 rgba(75,93,127,.14);
  margin-top: -130px;
      img {
        width: 100%;
        height: auto;
        display: block;
      }
`;
export const HumanName = styled(Typography)`
  text-align: center;
  margin-top: 15px;
  color: #244D76;
  font-weight: 500;
  font-size: 28px;
`;
export const HumanRating = styled(Typography)`
    line-height: 1.5;
    letter-spacing: 0.00938em;
    text-align: center;
    color: #244d76;
    font-weight: 400;
    font-size: 24px;
    text-transform: uppercase;
    position: relative;
    display: block;
    width: fit-content;
    margin: 0 auto;
    margin-top: 10px;
    &:after {
        content: ' ';
        height: 2px;
        width: 30px;
        background: #e4e5e9;
        top: calc(50% - 1px);
        right: calc(100% + 10px);
        position: absolute;
        z-index: 3;
        display: block;
    }
    &:before {
        content: ' ';
        height: 2px;
        width: 30px;
        background: #e4e5e9;
        top: calc(50% - 1px);
        left: calc(100% + 10px);
        z-index: 3;
        display: block;
        position: absolute;
    }
`;
export const HumanDate = styled(Typography)`
  text-align: center;
  margin-top: 15px;
  font-size: 18px;
  font-weight: 600;
  color: #18334e;
  letter-spacing: 0.015em;
`;
export const HumanYears = styled(Typography)`
  text-align: center;
`;
export const CitateBox = styled(Box)`
    background-color: #f8f9fc;
    border-radius: 8px;
    padding: 20px;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 600;
    color: #18334e;
    margin-left: -40px;
    margin-right: -40px;
    position: relative;
    z-index: 1;
    @media (max-width: 1024px) {
        margin-left: 0px;
        margin-right: 0px;
    }
    @media (max-width: 720px) {
        margin-left: -15px;
        margin-right: -15px;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 30px;
        padding-bottom: 30px;
        p {
            font-size: 1rem;
        }
    }
    :after{
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-image: url(${quotes1});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.1;
        z-index: -1;
    }
`;
export const SubCitateBox = styled(Box)`
  max-width: 650px;
  margin: 0 auto;
  text-align: justify;
  text-align-last: center;
      p {
        font-size: 18px;
      }
      h6 {
        margin-top: 20px;
      }
`;

export const DetailsContainer = styled(Box)`
  position: relative;
  margin-top: 60px;
  @media (max-width: 720px) {
    flex-direction: column;
  }
`;
export const DetailsBox = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media (max-width: 720px) {
    flex-direction: column;
  }
`;
export const DetailsRow = styled(Box)`
  border-bottom: 1px solid #d2d5d9;
  margin-bottom: 20px;
  padding-bottom: 20px;
  display: flex;
  width: 50%;
  @media (max-width: 720px) {
    width: 100%;
  }
    span:first-of-type{
      font-weight: 500;
      width: 185px;
      display: inline-block;
      font-size: 14px;
      @media (max-width: 720px) {
        width: 50%;
      }
    }
    span:last-of-type{
      font-weight: 400;
      font-size: 14px;
      @media (max-width: 720px) {
        text-align: right;
        width: 50%;
        display: inline-block;
      }
    }
`;
export const ModalContent = styled(Box)`
  height: 400px;
  overflow-y: auto;
  padding-right: 32px;
`;
export const ModalHeader = styled(Typography)`
  margin-bottom: 10px;
  margin-right: 32px;
`;
export const ModalDivider = styled(Divider)`
  margin-bottom: 10px;
  margin-right: 32px;
`;

export const BioPaper = styled(Paper)`
  padding: 0px;
  box-shadow: none;
  transition: none;
  padding-bottom: 10px;
`;
export const BioTitle = styled(Typography)`
  margin-bottom: 15px;
`;
export const BioBox = styled(Box)`

`;
export const BioLink = styled(Link)`
  margin-bottom: 10px;
  display: block;
  text-decoration: none;
`;

export const Graveyard = styled(Box)`
  margin-bottom: 20px;
`;
export const GraveyardName = styled(Typography)`
  margin-bottom: 10px;
  font-size: 21px;
  letter-spacing: 0.02938em;
`;
export const GraveDivider = styled(Divider)`
  
`;
export const GraveAddress = styled(Typography)`
  margin-top: 10px;
  span {
    font-weight: 500;
    margin-right: 5px;
    margin-top: 15px;
  }
`;

export const QRCode = styled('img')`
  max-width: 250px;
  margin: 0 auto;
  display: block;
`;

export const MainTitle = styled(Typography)`
  color: #244D76;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 26px;
  letter-spacing: 0.06em;
  @media (max-width: 1024px) {
    padding-left: 24px;
    padding-right: 24px;
  }
  @media (max-width: 720px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;