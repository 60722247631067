import axios from 'axios'
import { axiosTokens } from '../AxiosTokensService'
import { expirationTokenTimeManager } from '../expirationTokenTimeManager'

const portUI = process.env.REACT_APP_UI_PORT ? `:${process.env.REACT_APP_UI_PORT}` : ''
const secUI = process.env.REACT_APP_UI_HOST === 'localhost' ? 'http' : 'https';
export const UI_HOST = `${secUI}://${process.env.REACT_APP_UI_HOST}${portUI}`;

const port = process.env.REACT_APP_API_PORT ? `:${process.env.REACT_APP_API_PORT}` : ''
const sec = process.env.REACT_APP_API_HOST === 'localhost' ? 'http' : 'https';
export const API_HOST = `${sec}://${process.env.REACT_APP_API_HOST}${port}`;
const API_PREFIX = ''

export const userSession = {
  initSession: (token, refreshToken, expTime, id) => {
    localStorage.clear()
    localStorage.setItem('token', token)
    localStorage.setItem('refreshToken', refreshToken)
    localStorage.setItem('expTime', expTime)
    localStorage.setItem('userId', id)
  },
  clearSession: () => {
    localStorage.clear()
  },
  getUserData: () => {
    return {
      token: localStorage.getItem('token'),
      refreshToken: localStorage.getItem('refreshToken'),
      expTime: localStorage.getItem('expTime'),
      userId: localStorage.getItem('userId'),
    }
  },
  getSession: () => {
    return localStorage.getItem('token')
  },
  getRefreshToken: () => {
    return localStorage.getItem('refreshToken')
  },
  refreshSession: (config) => {
    return new Promise((resolve, reject) => {
      const refresToken = localStorage.getItem('refreshToken')
      axios
        .post(`${API_HOST}/refresh`, {
          refreshToken: refresToken,
        })
        .then((response) => {
          const token = response.data.accessToken
          console.log(response)
          userSession.initSession(token, refresToken, response.data.expTime, response.data.id)
          // localStorage.setItem('token', token)
          // localStorage.setItem('refreshToken', refresToken)
          // localStorage.setItem('expTime', response.data.expTime)
          // localStorage.setItem('userId', response.data.id)
          expirationTokenTimeManager.setExpirationTime(response.data.expTime)
          console.log('token', token)
          addHeader(
            'Authorization',
            `Bearer ${token}`,
          )
          addHeader(
            'Content-Type',
            `application/json`,
          )
          resolve()
        })
        .catch((error) => {
          localStorage.clear()
          window.location.href = '/#login'
          reject(error)
        })
    })
  },
}

const client = axios.create({
  withCredentials: true,
  baseURL: API_HOST + API_PREFIX,
})

export const addHeader = (name, value) => {
  client.defaults.headers.common[name] = value
}

export const isCancel = (error) => axios.isCancel(error)

export const createCancelToken = () => axios.CancelToken.source()

client.interceptors.request.use(
  async (config) => {
    console.log(config.headers.Authorization, expirationTokenTimeManager)
    if (!config.headers.Authorization) {
      const token = userSession.getSession()
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
        expirationTokenTimeManager.setExpirationTime(localStorage.getItem('expTime'))
        console.log(expirationTokenTimeManager.isTokenExpired())
      } else {
        window.location.href = '/'
      }
    }
    if (expirationTokenTimeManager.isTokenExpired()) {
      await userSession.refreshSession()
      const token = userSession.getSession()
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  },
  (error) => {
    console.log(error)
    return Promise.reject(error)
  },
)

client.interceptors.response.use(
  (config) => {
    return config
  },
  (error) => {
    return axiosTokens.checkError(error) ? Promise.resolve() : Promise.reject(error)
  },
)

export default client
