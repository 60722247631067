import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useSpring, animated } from '@react-spring/web';

interface FadeProps {
  children: React.ReactElement;
  in?: boolean;
  onClick?: any;
  onEnter?: (node: HTMLElement, isAppearing: boolean) => void;
  onExited?: (node: HTMLElement, isAppearing: boolean) => void;
  ownerState?: any;
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null as any, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null as any, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 1200,
  width: '100%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  paddingRight: 0,
  '*::-webkit-scrollbar': {
    width: '0.4em'
  },
  '*::-webkit-scrollbar-track': {
    '-webkit-box-shadow': '0 0 6px rgba(0,0,0,0.0)'
  },
  '*::-webkit-scrollbar-thumb': {
    backgroundColor: 'hsl(216 43% 75% / 1)',
  }
};

type Props = {
    children: React.ReactNode,
    open: boolean,
    handleClose: any,
    width?: number,
}

export default function SpringModal( { children, open, handleClose, width = 1200 } : Props ) {

  return (
    <Modal
    aria-labelledby="spring-modal-title"
    aria-describedby="spring-modal-description"
    open={open}
    onClose={handleClose}
    closeAfterTransition
    slots={{ backdrop: Backdrop }}
    slotProps={{
        backdrop: {
        TransitionComponent: Fade,
        },
    }}
    >
    <Fade in={open}>
        <Box component='div' sx={{...style, maxWidth: width}}>
            { children }
        </Box>
    </Fade>
    </Modal>
  );
}