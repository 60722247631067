import React, {useState} from 'react';
import {AppBar, Toolbar, Typography, Button, Box} from '@mui/material';
import styled from "@emotion/styled/macro";
import {Link, useNavigate} from 'react-router-dom';
import imgLogo from '../assets/img/logo-original.png';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import SpringModal from "./Modal";
import {ModalContent, ModalDivider, ModalHeader} from "../pages/SolderPage/styles";
import ImageSlider from "./ImageSlider";
import {IModalContent} from "../pages/SolderPage/SolderPage";
import Login from "./Login";
import SignUp from "./SignUp";
import {userSession} from "../services/auth/ApiService/client";
import {useDispatch, useSelector} from 'react-redux';
import { setLoginOpen } from '../store/appSlice';

const Logo = styled('img')`
  height: 50px;
  margin-right: 10px;
  border-radius: 90px;
    cursor: pointer;
    @media (max-width: 720px) {
        height: 35px;
        margin-right: 5px;
    }
`;

const HeaderToolbar = styled(Toolbar)`
    @media (max-width: 720px) {
        padding: 5px;
    }
    svg {
        color: white;
        fill: #244D76;
        width: 30px;
        height: 30px;
        padding: 5px;
        border-radius: 90px;
        border: 1px solid #04091136;
        display: block;
    }
`;

const CloseBtnIcon = styled(CloseIcon)`
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    &.active {
        opacity: 1;
    }
`;
const MenuBtnIcon = styled(MenuIcon)`
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    &.active {
        opacity: 1;
    }
`;

const OpenMenu = styled(Button)`
    display: none;
    width: 35px;
    height: 35px;
    position: relative;
    @media (max-width: 720px) {
        display: block;
        min-width: 0;
        padding: 0px;
        margin: 0;
    }
`;
const HeaderButtonIcon = styled(Button)`
    margin-left: 10px;
    padding: 0px;
    width: auto;
    min-width: 0px;
    @media (max-width: 720px) {
        min-width: 0;
        margin-right: 5px;
        margin-left: 0;
    }
`;
const HeaderSubMenu = styled(Box)`
    transition: all ease-in-out 200ms;
    &.active {
        height: calc(100% - 56px);
        z-index: 5;
        top: 56px;
        opacity: 1;
    }
    @media (max-width: 720px) {
        position: fixed;
        left: 0;
        right: 0;
        top: 0px;
        height: 0px;
        background: #fff;
        padding: 20px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        opacity: 0;
        z-index: -1;
        overflow: hidden;
        transition: all linear 200ms;
    }
`;
const HeaderIcons = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

const HeaderContainer = styled(AppBar)`
    @media (max-width: 720px) {
        border-radius: 0px;
        top: 0px;
        padding-left: 0px;
    }
`;
const SignUpBtn = styled(Button)``;
const LogoText = styled(Typography)`
    @media (max-width: 720px) {
        font-size: 18px;
    }
` as typeof Typography;

const AuthModalContainer = styled(Box)`
    height: auto;
    overflow-y: auto;
    padding-right: 32px;
`;

const AuthModalHeader = styled(ModalHeader)`
    margin: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1.235;
    letter-spacing: 0.04835em;
    color: #244D76;
    font-weight: 400;
    font-size: 28px;
    text-align: left;
    text-transform: uppercase;
    padding: 0px;
    padding-bottom: 10px;
`;
const Header = () => {
    const navigate = useNavigate();
    const [menuOpened, setMenuOpened] = useState(false);
    const [openSignUp, setOpenSignUp] = React.useState(false);
    const loginOpen = useSelector((state: any) => state.app.loginOpen)
    const dispatch = useDispatch()
    const handleOpenLogin = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        const token = userSession.getSession();
        if (token) {
            navigate('/profile')
        } else {
            dispatch(setLoginOpen(true))
        }
        setMenuOpened(false)
    };
    const handleCloseLogin = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        dispatch(setLoginOpen(false))
    };
    const handleOpenSignUp = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        setOpenSignUp(true)
    };
    const handleCloseSignUp = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        setOpenSignUp(false)
    };
    const handleOpenMenu = () => {
        setMenuOpened(!menuOpened);
    }
    const moveTo = (link: string) => {
        navigate(link);
        setMenuOpened(false)
    }
    return (
        <>
            <HeaderContainer position="static" sx={{
                backgroundColor: '#f8f9fc',
                boxShadow: '0 3px 20px 0 hsla(0,0%,74.5%,.16)',
                color: '#244D76',
                maxWidth: '1234px',
                position: 'fixed',
                zIndex: '100',
                borderRadius: '90px',
                top: '10px',
                left: 0,
                right: 0,
                marginLeft: 'auto',
                marginRight: 'auto',
            }}>
                <HeaderToolbar>
                    <Logo src={imgLogo}  onClick={(e) => moveTo("/")}/>
                    <LogoText variant="h6" component="div" sx={{flexGrow: 1}}>
                        #НА СЕВЕРЕ - ПАМЯТЬ
                    </LogoText>
                    <HeaderSubMenu className={menuOpened ? 'active' : undefined}>
                        <Button color="inherit" onClick={(e) => moveTo("/")}>
                            Главная
                        </Button>
                        <Button color="inherit" onClick={(e) => moveTo("/aleya-heroev")}>
                            Аллея героев
                        </Button>
                        <Button color="inherit" onClick={(e) => moveTo("/companies")}>
                            Каталог компаний
                        </Button>
                        <Button color="inherit" onClick={(e) => moveTo("/feedback")}>
                            Обратная связь
                        </Button>
                    </HeaderSubMenu>
                    <HeaderIcons>
                        {/*<HeaderButtonIcon color="inherit">*/}
                        {/*    <svg xmlns="http://www.w3.org/2000/svg"*/}
                        {/*         viewBox="0 0 512 512">*/}
                        {/*        <path*/}
                        {/*            d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/>*/}
                        {/*    </svg>*/}
                        {/*</HeaderButtonIcon>*/}
                        <HeaderButtonIcon color="inherit" onClick={(e) => handleOpenLogin(e)}>
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 448 512">
                                <path
                                    d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"/>
                            </svg>
                        </HeaderButtonIcon>
                        <OpenMenu color="inherit" onClick={() => handleOpenMenu()}>
                            <MenuBtnIcon className={!menuOpened ? 'active' : undefined} />
                            <CloseBtnIcon className={menuOpened ? 'active' : undefined} />
                        </OpenMenu>
                    </HeaderIcons>
                </HeaderToolbar>
            </HeaderContainer>
            <SpringModal open={loginOpen} handleClose={handleCloseLogin} width={800}>
                <Box style={ { paddingRight: '2px' } }>
                    <AuthModalHeader>
                        Вход в личный кабинет
                    </AuthModalHeader>
                    <ModalDivider />
                    <AuthModalContainer>
                        <Login />
                        <p>У вас еще нет аккаунта? <SignUpBtn onClick={() => {
                            dispatch(setLoginOpen(false))
                            setOpenSignUp(true)
                        }}>Регистрация</SignUpBtn></p>
                    </AuthModalContainer>
                </Box>
            </SpringModal>
            <SpringModal open={openSignUp} handleClose={handleCloseSignUp} width={450}>
                <Box style={ { paddingRight: '2px' } }>
                    <AuthModalHeader>
                        Регистрация
                    </AuthModalHeader>
                    <ModalDivider />
                    <AuthModalContainer>
                        <SignUp modalOpen={() => setOpenSignUp(false)}/>
                        <p>У вас есть аккаунт? <SignUpBtn onClick={() => {
                            dispatch(setLoginOpen(true))
                            setOpenSignUp(false)
                        }}>Войти</SignUpBtn></p>
                    </AuthModalContainer>
                </Box>
            </SpringModal>
        </>
    );
}

export default Header;